import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getValueFromStorage } from "../helpers/helpers";

export const QrCodeApi = createApi({
  reducerPath: "qrCode",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getValueFromStorage("token")}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    addQrCode: builder.mutation({
      query: (body) => ({
        url: `/api/qrcode`,
        method: "POST",
        body: body,
      }),
    }),
    putQrCode: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/api/qrcode/${id}`,
          method: "PATCH",
          body: body,
        };
      },
    }),
    getQrCodes: builder.query({
      query: ({
        page = 1,
        perPage = 100000,
        folderId,
        type,
        sortType = "last_created",
        startDate,
        endDate,
      }) => ({
        url: `/api/qrcode`,
        method: "GET",
        params: {
          page: page,
          perPage: perPage,
          folderId: folderId,
          type: type,
          sort_type: sortType,
          start_date: startDate,
          end_date: endDate,
        },
      }),
    }),
    getQrCodeByUrlId: builder.query({
      query: (id) => ({
        url: `/api/qrcode/getQrCodeByUrlId/${id}`,
        method: "GET",
      }),
    }),
    getQrCodeById: builder.query({
      query: (id) => ({
        url: `/api/qrcode/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useAddQrCodeMutation,
  usePutQrCodeMutation,
  useGetQrCodesQuery,
  useGetQrCodeByUrlIdQuery,
  useLazyGetQrCodeByUrlIdQuery,
  useGetQrCodeByIdQuery,
  useLazyGetQrCodeByIdQuery,
} = QrCodeApi;
