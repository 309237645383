import React from "react";

const ImageShape = ({ index, selected, onChange }) => {
  return (
    <div key={index} className="mb-8">
      <p className="text-primary font-semibold text-sm mb-2">
        Choose Image Shape
      </p>
      <div className="flex items-center">
        <div
          className={`bg-white rounded-md inputBox-shadow w-28 h-28 p-4 cursor-pointer ${
            selected === "circle" && "border-2 border-primary"
          }`}
          onClick={() => onChange("circle")}
        >
          <div className="w-full h-full bg-themeGray-300 rounded-full"></div>
        </div>
        <div
          className={`bg-white rounded-md inputBox-shadow w-28 h-28 p-4 ml-5 cursor-pointer ${
            selected === "square" && "border-2 border-primary"
          }`}
          onClick={() => onChange("square")}
        >
          <div className="w-full h-full bg-themeGray-300"></div>
        </div>
        <div
          className={`bg-white rounded-md inputBox-shadow w-28 h-28 py-4 ml-5 cursor-pointer ${
            selected === "rectangle" && "border-2 border-primary"
          }`}
          onClick={() => onChange("rectangle")}
        >
          <div className="w-full h-full bg-themeGray-300"></div>
        </div>
      </div>
    </div>
  );
};

export default ImageShape;
