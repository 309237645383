import React from "react";
import { Divider } from "antd";
import { ThemeInput, ThemeColorPicker } from "../../ui/ui";
import { default_icon } from "../../assets/icons/qrcustomization/qrcustomization";

const FrameSection = ({ formData, setFormData }) => {
  return (
    <div>
      <div className="mb-8">
        <h1 className="text-secondary text-lg font-bold mb-1">
          SELECT A FRAME
        </h1>
        <p className="text-xs text-themeGray-200 mb-6">
          Make your QR code print-ready by adding a frame
        </p>

        <ThemeColorPicker
          value={formData?.frame?.color}
          onChange={(e) =>
            setFormData({
              ...formData,
              frame: { ...formData?.frame, color: e.toHexString() },
            })
          }
        />

        <div className="flex items-center -ml-2">
          <div className="p-2">
            <div
              className={`qrbox-shadow rounded p-3 cursor-pointer border hover:border-primary ${
                formData?.frame?.type === "frame1" && "border-primary"
              }`}
              onClick={() =>
                setFormData({
                  ...formData,
                  frame: { ...formData?.frame, type: "frame1" },
                })
              }
            >
              <div className="flex-col w-full justify-center items-center">
                <div className="p-2 border-[3px] border-secondary rounded mb-1">
                  <img src={default_icon} alt="qrcode" />
                </div>
                <div className="bg-secondary rounded p-1">
                  <p className="text-[10px] text-center text-white">Scan Me</p>
                </div>
              </div>
            </div>
          </div>
          <div className="p-2">
            <div
              className={`qrbox-shadow rounded p-3 cursor-pointer border hover:border-primary ${
                formData?.frame?.type === "frame2" && "border-primary"
              }`}
              onClick={() =>
                setFormData({
                  ...formData,
                  frame: { ...formData?.frame, type: "frame2" },
                })
              }
            >
              <div className="flex-col w-full justify-center items-center">
                <div className="border-[3px] border-secondary rounded">
                  <img src={default_icon} alt="qrcode" className="p-2" />
                  <div className="bg-secondary p-1 mt-1">
                    <p className="text-[10px] text-center text-white">
                      Scan Me
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-2">
            <div
              className={`qrbox-shadow rounded p-3 cursor-pointer border hover:border-primary ${
                formData?.frame?.type === "frame3" && "border-primary"
              }`}
              onClick={() =>
                setFormData({
                  ...formData,
                  frame: { ...formData?.frame, type: "frame3" },
                })
              }
            >
              <div className="flex-col w-full justify-center items-center">
                <div className="border-[3px] border-secondary rounded">
                  <img src={default_icon} alt="qrcode" className="p-2" />
                  <div className="p-1 mt-1">
                    <p className="text-[10px] text-center text-secondary">
                      Scan Me
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-2">
            <div
              className={`qrbox-shadow rounded p-3 cursor-pointer border hover:border-primary ${
                formData?.frame?.type === "frame4" && "border-primary"
              }`}
              onClick={() =>
                setFormData({
                  ...formData,
                  frame: { ...formData?.frame, type: "frame4" },
                })
              }
            >
              <div className="flex-col w-full justify-center items-center">
                <img src={default_icon} alt="qrcode" className="p-2" />
                <div className="p-1 mt-1">
                  <p className="text-[10px] text-center text-secondary">
                    Scan Me
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="p-2">
            <div
              className={`qrbox-shadow rounded p-3 cursor-pointer border hover:border-primary ${
                formData?.frame?.type === "frame5" && "border-primary"
              }`}
              onClick={() =>
                setFormData({
                  ...formData,
                  frame: { ...formData?.frame, type: "frame5" },
                })
              }
            >
              <div className="flex-col w-full justify-center items-center">
                <div className="border-[3px] border-secondary rounded">
                  <img src={default_icon} alt="qrcode" className="p-2" />
                  <div className="p-1 mt-1 border-t-[3px] border-secondary">
                    <p className="text-[10px] text-center text-secondary">
                      Scan Me
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <h1 className="text-base font-bold text-primary mb-2">FRAME TEXT</h1>
        <p className="text-xs text-themeGray-200 mb-4">
          Modify text in the frame
        </p>
        <ThemeInput
          placeholder={"Enter frame text"}
          inputHeight={"h-11"}
          className={"mb-3"}
          value={formData?.frame?.text}
          onChange={(e) =>
            setFormData({
              ...formData,
              frame: { ...formData?.frame, text: e.target.value },
            })
          }
        />
        <ThemeColorPicker
          value={formData?.frame?.textColor}
          onChange={(e) =>
            setFormData({
              ...formData,
              frame: { ...formData?.frame, textColor: e.toHexString() },
            })
          }
        />
      </div>
    </div>
  );
};

export default FrameSection;
