import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { ThemeButton, ThemeInput } from "../../ui/ui";

const MultiLinks = ({ value, onChange }) => {
  const onHandleChange = (e, index) => {
    const values = value?.length > 0 ? value : [];
    values[index] = { ...values[index], [e.target.name]: e.target.value };
    onChange(values);
  };

  return (
    <div>
      {value?.length > 0 &&
        value?.map((item, index) => {
          return (
            <div className="mb-6" key={index}>
              {value?.length > 1 && (
                <div className="flex justify-between items-center">
                  <p className="text-base text-secondary font-MontserratBold mb-1">
                    Link {index + 1}
                  </p>
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      const values = value;
                      values.splice(index, 1);
                      onChange(values);
                    }}
                  >
                    <DeleteOutlined className="text-red-600 text-lg hover:text-xl" />
                  </span>
                </div>
              )}

              <ThemeInput
                label={"URL"}
                labelPrimary
                placeholder={"https://"}
                className={"mb-5"}
                name={"url"}
                value={item?.url}
                onChange={(e) => onHandleChange(e, index)}
              />
              <ThemeInput
                label={"Link Title"}
                labelPrimary
                placeholder={"Enter the Link Title"}
                className={"mb-5"}
                name={"title"}
                value={item?.title}
                onChange={(e) => onHandleChange(e, index)}
              />
            </div>
          );
        })}

      <ThemeButton
        className={"w-32 text-primary border-primary"}
        bgColor={"bg-transparent"}
        content={<p>Add Link</p>}
        onClick={() => {
          const values = value?.length > 0 ? value : [];
          values.push({ url: "", title: "" });
          onChange(values);
        }}
      />
    </div>
  );
};

export default MultiLinks;
