import React, { useState, useRef, useEffect } from "react";
import { getSocialType } from "../../helpers/helpers";
import { ThemeButton, ThemeInput } from "../../ui/ui";
import { dropdownBlue_icon, website_icon } from "../../assets/icons/icons";
import * as social_icons from "../../assets/icons/socials/socials";
import { bio_banner } from "../../assets/images/images";
import { Divider, Radio, Spin } from "antd";
import {
  QrcodeOutlined,
  UploadOutlined,
  PlusOutlined,
  MobileOutlined,
  MailOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { PREVIEW_URL } from "../../constants/constants";
import QRCodeStyling from "qr-code-styling";

const VcardPreview = ({
  type,
  qrCodeData,
  formData,
  setFormData,
  onSubmit,
  isLoading,
  isConsumer,
  urlid,
}) => {
  const ref = useRef(null);
  const [currentTab, setCurrentTab] = useState(1);
  const [qrOptions, setQrOptions] = useState({});

  const primaryColor = type?.sections[3]?.inputs[0]?.value
    ? type?.sections[3]?.inputs[0]?.value[0]
    : "#2965FF";
  const buttonColor = type?.sections[3]?.inputs[0]?.value
    ? type?.sections[3]?.inputs[0]?.value[1]
    : "#2965FF";
  const headingColor = type?.sections[3]?.inputs[0]?.value
    ? type?.sections[3]?.inputs[0]?.value[2]
    : "#2965FF";

  const vcardData = {
    name: "Lorem Ipsum",
    jobTitle: "Creative Lead",
    company: "Techoid",
    phone: "+92-000-0000000",
    website: "www.loremipsum.com",
    location: "example place",
    email: "youremail@gmail.com",
    socialLinks: [
      { icon: "facebook_icon" },
      { icon: "instagram_icon" },
      { icon: "twitter_icon" },
      { icon: "youtube_icon" },
    ],
  };

  const qrCode = new QRCodeStyling(qrOptions);

  useEffect(() => {
    if (isConsumer) {
      let styles = {
        width: 150,
        height: 150,
        data: `${PREVIEW_URL}${urlid}`,
        backgroundOptions: {
          color: !qrCodeData?.background?.isTransparent
            ? qrCodeData?.background?.type === "solid" &&
              qrCodeData?.background?.color[0]
            : "transparent",
          gradient: {
            type: "linear",
            rotation: 0,
            colorStops: [
              {
                offset: 0,
                color: qrCodeData?.background?.color[0],
              },
              {
                offset: 1,
                color: qrCodeData?.backgroundColor?.color[1],
              },
            ],
          },
        },
        cornersDotOptions: {
          type: qrCodeData?.eyeBall?.type,
          color: qrCodeData?.eyeBall?.color,
        },
        cornersSquareOptions: {
          type: qrCodeData?.eyeBall?.eyeFrame,
          color: qrCodeData?.eyeBall?.eyeFrameColor,
        },
        dotsOptions: {
          type: qrCodeData?.pattern?.type,
          color: Array.isArray(qrCodeData?.pattern?.color?.color)
            ? qrCodeData?.pattern?.color?.color[0]
            : qrCodeData?.pattern?.color[0],
          gradient: {
            type: "linear",
            rotation: 0,
            colorStops: [
              {
                offset: 0,
                color: Array.isArray(qrCodeData?.pattern?.color?.color)
                  ? qrCodeData?.pattern?.color?.color[0]
                  : qrCodeData?.pattern?.color[0],
              },
              {
                offset: 1,
                color: Array.isArray(qrCodeData?.pattern?.color?.color)
                  ? qrCodeData?.pattern?.color?.color[1]
                  : qrCodeData?.pattern?.color[1],
              },
            ],
          },
        },
        image: qrCodeData?.logo?.image || social_icons[qrCodeData?.logo?.logo],
        imageOptions: {
          hideBackgroundDots: qrCodeData?.logo?.isWhite,
          imageSize: qrCodeData?.logo?.size / 10,
          margin: 2,
        },
      };
      (qrCodeData?.background?.type !== "gradient" ||
        qrCodeData?.frame?.type) &&
        delete styles?.backgroundOptions?.gradient;
      qrCodeData?.pattern?.colorType === "solid" &&
        delete styles?.dotsOptions?.gradient;

      setQrOptions(styles);
    }
  }, [qrCodeData, isLoading]);

  useEffect(() => {
    const qrContainer = document.getElementById("qr-container");
    if (qrContainer) {
      qrContainer.innerHTML = "";

      qrCode.append(ref.current);
    }
  }, [qrOptions, currentTab]);

  const createVCard = async (data) => {
    const response = await fetch(data.photoUrl);
    const blob = await response.blob();

    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onloadend = () => {
        const photoData = reader.result.split(",")[1];
        const vCardContent =
          `BEGIN:VCARD\r\n` +
          `VERSION:3.0\r\n` +
          `FN:${data.name}\r\n` +
          `ORG:${data.company}\r\n` +
          `TITLE:${data.jobTitle}\r\n` +
          `TEL:${data.phone}\r\n` +
          `URL:${data.website}\r\n` +
          `ADR:${data.location}\r\n` +
          `EMAIL:${data.email}\r\n` +
          `PHOTO;TYPE=JPEG;ENCODING=BASE64:${photoData}\r\n` +
          `END:VCARD\r\n`;

        resolve(vCardContent);
      };
      reader.readAsDataURL(blob);
    });
  };

  const addToContacts = async () => {
    // Use the data from vcardData or formData to create a vCard string
    const vcardData = {
      name: type?.sections[1]?.inputs[0]?.value,
      company: type?.sections[1]?.inputs[1]?.value,
      jobTitle: type?.sections[1]?.inputs[2]?.value,
      phone: type?.sections[1]?.inputs[3]?.value,
      website: type?.sections[1]?.inputs[4]?.value,
      location: type?.sections[1]?.inputs[5]?.value,
      email: type?.sections[1]?.inputs[6]?.value,
      // Placeholder for the URL of the profile picture (replace this with the actual URL)
      photoUrl: type?.sections[0]?.inputs[0]?.value,
    };

    const vCardContent = await createVCard(vcardData);

    const blob = new Blob([vCardContent], { type: "text/vcard" });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${vcardData.name}.vcf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // const handleShareClick = () => {
  //   try {
  //     const shareableLink = `${window.location.href}?share=true`;
  //     const message = "Check out this link:";
  //     const sharedText = `${message}\n${shareableLink}`;

  //     if (navigator.clipboard) {
  //       // Try using clipboard API to copy the shared text
  //       navigator.clipboard.writeText(sharedText).then(() => {
  //         alert("Link copied to clipboard. You can now paste it to share.");
  //       });
  //     } else {
  //       // Fallback: prompt the user to manually copy the link
  //       prompt("Copy the link below:", sharedText);
  //     }
  //   } catch (error) {
  //     console.error("Error sharing:", error.message);
  //     // Handle errors or show a message to the user
  //   }
  // };

  return (
    <>
      {currentTab === 1 ? (
        <div>
          <div className="w-full object-cover h-[110px] -z-10" />

          <div
            className="rounded-lg -mt-2 mx-1 lg:pt-12 md:pt-12 pt-[4.3rem] z-20 relative flex flex-col items-center h-full lg:px-2 md:px-2 px-3"
            style={{ backgroundColor: primaryColor }}
          >
            <div className="absolute md:-top-16 -top-24 w-full">
              <img
                src={
                  type?.sections[0]?.inputs[0]?.value ||
                  "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YXZhdGFyfGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60"
                }
                alt="profile"
                className="rounded-full object-cover lg:w-24 lg:h-24 md:w-24 md:w-24 w-36 h-36 mx-auto "
              />
            </div>
            <h1 className="text-white font-MontserratBold lg:text-lg md:text-lg text-2xl">
              {type?.sections[1]?.inputs[0]?.value || vcardData?.name}
            </h1>
            <p className="text-white font-MontserratRegular font-semibold lg:text-xs md:text-xs text-sm lg:mt-0 md:mt-0 mt-1">
              {type?.sections[1]?.inputs[1]?.value || vcardData?.company}
            </p>
            <p className="text-white font-MontserratRegular font-semibold lg:text-xs md:text-xs text-sm lg:mt-0 md:mt-0 mt-1">
              {type?.sections[1]?.inputs[2]?.value || "---"}
            </p>

            <div className="flex items-center justify-center mt-3">
              <a href={`tel:${type?.sections[1]?.inputs[3]?.value}`}>
                <div
                  className="lg:w-8 lg:h-8 md:w-8 md:h-8 w-12 h-12 rounded-full bg-white lg:text-lg md:text-lg text-xl flex items-center justify-center m-2 cursor-pointer"
                  style={{ color: primaryColor }}
                >
                  <MobileOutlined />
                </div>
              </a>
              <a href={`mailto:${type?.sections[1]?.inputs[6]?.value}`}>
                <div
                  className="lg:w-8 lg:h-8 md:w-8 md:h-8 w-12 h-12 rounded-full bg-white lg:text-lg md:text-lg text-xl flex items-center justify-center m-2 cursor-pointer"
                  style={{ color: primaryColor }}
                >
                  <MailOutlined />
                </div>
              </a>
            </div>

            <div className="bg-white rounded-md p-3 flex flex-col w-full lg:mt-4 md:mt-4 mt-6 mb-3">
              <div className="flex items-center">
                <div className="lg:w-8 lg:h-8 md:w-8 md:h-8 w-10 h-10 rounded-full bg-secondary text-white text-lg flex items-center justify-center">
                  <MobileOutlined />
                </div>
                <p className="!mb-0 text-secondary font-semibold lg:text-sm md:text-sm text-base ml-2">
                  Contact Us
                </p>
              </div>
              <div className="lg:mt-6 md:mt-6 mt-8 flex flex-col">
                <div className="flex flex-col mb-2">
                  <p className="text-secondary font-MontserratRegular lg:text-sm md:text-sm text-base">
                    Call Us
                  </p>
                  <p className="text-themeGray-400 lg:text-xs md:text-xs text-sm">
                    {type?.sections[1]?.inputs[3]?.value || vcardData?.phone}
                  </p>
                </div>
                <div className="flex flex-col mb-2">
                  <p className="text-secondary font-MontserratRegular lg:text-sm md:text-sm text-base">
                    Email
                  </p>
                  <p className="text-themeGray-400 lg:text-xs md:text-xs text-sm break-words">
                    {type?.sections[1]?.inputs[6]?.value || vcardData?.email}
                  </p>
                </div>
                <div className="flex flex-col mb-2">
                  <p className="text-secondary font-MontserratRegular lg:text-sm md:text-sm text-base">
                    Address
                  </p>
                  <p className="text-themeGray-400 lg:text-xs md:text-xs text-sm">
                    {type?.sections[1]?.inputs[5]?.value || vcardData?.location}
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-md p-3 flex flex-col w-full mb-3">
              <p className="!mb-0 text-secondary text-xl font-MontserratBold font-bold text-center">
                Website
              </p>
              <Divider className="lg:mt-1 md:mt-1 mt-3" dashed={true} />
              <div className="flex items-center justify-between cursor-pointer">
                <a
                  href={type?.sections[1]?.inputs[4]?.value}
                  className="cursor-pointer"
                  target="_blank"
                >
                  <div className="flex items-center">
                    <img
                      src={website_icon}
                      alt="icon"
                      className="lg:w-5 lg:h-5 md:w-4 md:h-4 w-7 h-7"
                    />
                    <div className="lg:text-sm md:text-sm text-base text-secondary mx-2 break-all">
                      {type?.sections[1]?.inputs[4]?.value ||
                        vcardData?.website}
                    </div>
                  </div>
                </a>
                <img
                  src={dropdownBlue_icon}
                  alt="icon"
                  className=" transform -rotate-90 lg:w-3 lg:h-3 md:w-3 md:h-3 w-4 h-4"
                />
              </div>
              <p className="!mb-0 text-secondary text-xl font-MontserratBold font-bold text-center lg:mt-8 md:mt-8 mt-10">
                Social Links
              </p>
              <Divider className="lg:mt-1 md:mt-1 mt-3" dashed={true} />

              <div className="flex items-center justify-center">
                {type?.sections[2]?.inputs[0]?.value?.length > 0
                  ? type?.sections[2]?.inputs[0]?.value?.map((link, index) => {
                      return (
                        <div className="flex items-center p-2" key={index}>
                          <a
                            href={link?.value}
                            target="_blank"
                            className="cursor-pointer"
                          >
                            <img
                              src={
                                social_icons[getSocialType(link?.type)?.icon]
                              }
                              alt="icon"
                              className="lg:w-7 lg:h-7 md:w-7 md:h-7 w-10 h-10"
                            />
                          </a>
                        </div>
                      );
                    })
                  : vcardData.socialLinks?.map((link, index) => {
                      return (
                        <div className="flex items-center p-2" key={index}>
                          <a
                            href={link?.value}
                            target="_blank"
                            className="cursor-pointer"
                          >
                            <img
                              src={social_icons[link?.icon]}
                              alt="icon"
                              className="lg:w-7 lg:h-7 md:w-7 md:h-7 w-10 h-10"
                            />
                          </a>
                        </div>
                      );
                    })}
              </div>
            </div>
            <div
              className={`bg-white rounded-md p-3 flex flex-col w-full ${
                isConsumer ? "mb-20" : "mb-3"
              } `}
            >
              <div className="flex items-center">
                <p className="!mb-0 text-secondary font-semibold lg:text-sm md:text-sm text-base">
                  Connect With Me
                </p>
              </div>
              <div className="lg:mt-6 md:mt-6 mt-8 flex flex-col">
                <div className="flex flex-col mb-3">
                  <p className="text-secondary font-MontserratBold lg:text-sm md:text-sm text-base">
                    Full Name
                  </p>
                  <ThemeInput
                    type={"text"}
                    placeholder={"enter your full name"}
                    className={"!text-sm"}
                    inputHeight={"lg:!h-9 md:!h-9 !h-10"}
                    value={formData?.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                </div>
                <div className="flex flex-col mb-3">
                  <p className="text-secondary font-MontserratBold lg:text-sm md:text-sm text-base">
                    Email
                  </p>
                  <ThemeInput
                    type={"email"}
                    placeholder={"enter your email"}
                    className={"!text-sm"}
                    inputHeight={"lg:!h-9 md:!h-9 !h-10"}
                    value={formData?.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>
                {urlid && urlid === "ns7qgjpjga" && (
                  <div className="flex flex-col mb-3">
                    <p className="text-secondary font-MontserratBold lg:text-sm md:text-sm text-base">
                      Organization
                    </p>
                    <ThemeInput
                      type={"text"}
                      placeholder={"enter your email"}
                      className={"!text-sm"}
                      inputHeight={"lg:!h-9 md:!h-9 !h-10"}
                      value={formData?.organization}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          organization: e.target.value,
                        })
                      }
                    />
                  </div>
                )}
                <div className="flex flex-col mb-3">
                  <p className="text-secondary font-MontserratBold lg:text-sm md:text-sm text-base">
                    Number
                  </p>
                  <ThemeInput
                    type={"NUMBER"}
                    placeholder={"enter your number"}
                    className={"!text-sm"}
                    inputHeight={"lg:!h-9 md:!h-9 !h-10"}
                    value={formData?.number}
                    onChange={(e) =>
                      setFormData({ ...formData, number: e.target.value })
                    }
                  />
                </div>
                {urlid && urlid === "ns7qgjpjga" && (
                  <div className="flex flex-col mb-3">
                    <p className="text-secondary font-MontserratBold lg:text-sm md:text-sm text-base">
                      Interested in
                    </p>
                    <Radio.Group
                      value={formData?.interestedIn}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          interestedIn: e.target.value,
                        })
                      }
                    >
                      <Radio value={"WMS"}>WMS</Radio>
                      <Radio value={"Other"}>Other Solution</Radio>
                    </Radio.Group>
                  </div>
                )}
              </div>
              <ThemeButton
                onClick={() => onSubmit()}
                className={"w-20 flex ml-auto mt-4"}
                content={
                  <>
                    {isLoading ? <Spin /> : <p className="text-sm">Submit</p>}
                  </>
                }
                disabled={!isConsumer}
              />
            </div>
            {isConsumer && (
              <div className="flex items-center justify-between w-full lg:px-3 md:px-3 px-4 absolute bottom-[15px] z-50">
                <div className="flex items-center">
                  <span
                    className="border border-themeGray-dark lg:w-8 lg:h-8 md:w-8 md:h-8 w-12 h-12 rounded-full text-white lg:text-sm md:text-sm text-2xl flex items-center justify-center cursor-pointer"
                    style={{ backgroundColor: primaryColor }}
                    onClick={() => setCurrentTab(2)}
                  >
                    <QrcodeOutlined />
                  </span>
                  {/* <span
              className="border border-themeGray-dark lg:w-8 lg:h-8 md:w-8 md:h-8 w-12 h-12 rounded-full text-white lg:text-sm md:text-sm text-2xl flex items-center justify-center ml-2 cursor-pointer"
              style={{ backgroundColor: primaryColor }}
              onClick={handleShareClick}
            >
              <UploadOutlined />
            </span> */}
                </div>

                <span
                  className="border border-themeGray-dark lg:w-28 lg:h-9 md:w-28 md:h-9 w-36 h-12 lg:px-2 md::px-2 px-3 py-1 rounded-full text-white text-base ml-2 flex items-center justify-between cursor-pointer"
                  style={{ backgroundColor: primaryColor }}
                  onClick={addToContacts}
                >
                  <p className="md:text-[10px] leading-none text-sm text-center pl-2">
                    Add to <br /> Contact
                  </p>
                  <span
                    className="bg-white rounded-full lg:text-sm md:text-sm text-base lg:w-6 lg:h-6 md:w-6 md:h-6 w-8 h-8 flex items justify-center"
                    style={{ color: primaryColor }}
                  >
                    <PlusOutlined />
                  </span>
                </span>
              </div>
            )}
          </div>
          <p className=" font-MontserratBold bg-themeGray-medium p-3 text-center lg:text-sm md:text-sm text-base">
            Powered By
            <a
              href="https://qrinsight.co/"
              target="_blank"
              className="text-primary font-quicksandBold ml-1"
            >
              QRInsight
            </a>
          </p>
        </div>
      ) : (
        <div className="p-3">
          <div
            className="w-8 h-8 text-white text-base flex items-center justify-center rounded-full cursor-pointer"
            style={{ backgroundColor: primaryColor }}
            onClick={() => setCurrentTab(1)}
          >
            <CloseOutlined />
          </div>
          <div className="flex flex-col items-center h-full mt-4">
            <img
              src={
                type?.sections[0]?.inputs[0]?.value ||
                "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YXZhdGFyfGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60"
              }
              alt="profile"
              className="rounded-full object-cover lg:w-24 lg:h-24 md:w-24 md:w-24 w-32 h-32 mx-auto mb-2 mt-10"
            />
            <h1 className="text-secondary font-MontserratBold lg:text-lg md:text-lg text-2xl">
              {type?.sections[1]?.inputs[0]?.value || vcardData?.name}
            </h1>
            <p className="text-secondary font-MontserratRegular font-semibold lg:text-xs md:text-xs text-sm lg:mt-0 md:mt-0 mt-1 lg:mb-10 md:mb-10 mb-20">
              {type?.sections[1]?.inputs[1]?.value || vcardData?.company}
            </p>

            <div>
              {qrCodeData?.frame?.type ? (
                <div id="qr-main">
                  {qrCodeData?.frame?.type === "frame1" && (
                    <div className="flex-col w-full justify-center items-center">
                      <div
                        className="p-4 border-8 rounded-lg mb-1"
                        style={{
                          borderColor: qrCodeData?.frame?.color,
                          background: qrCodeData?.background?.isTransparent
                            ? "transparent"
                            : qrCodeData?.background?.type === "solid"
                            ? qrCodeData?.background?.color[0]
                            : qrCodeData?.background?.type === "gradient" &&
                              `linear-gradient(to right, ${qrCodeData?.background?.color[0]}, ${qrCodeData?.background?.color[1]})`,
                        }}
                      >
                        <div
                          ref={ref}
                          id="qr-container"
                          className={`${
                            qrCodeData?.template === "Circular" &&
                            "rounded-full border-4 border-secondary p-8"
                          }`}
                        />
                      </div>
                      <div
                        className="rounded-lg p-1 py-3"
                        style={{
                          backgroundColor: qrCodeData?.frame?.color,
                        }}
                      >
                        <p
                          className="text-sm text-center"
                          style={{
                            color: qrCodeData?.frame?.textColor,
                          }}
                        >
                          {qrCodeData?.frame?.text}
                        </p>
                      </div>
                    </div>
                  )}
                  {qrCodeData?.frame?.type === "frame2" && (
                    <div className="flex-col w-full justify-center items-center">
                      <div
                        className="border-8 rounded-lg mb-1"
                        style={{
                          borderColor: qrCodeData?.frame?.color,
                          background: qrCodeData?.background?.isTransparent
                            ? "transparent"
                            : qrCodeData?.background?.type === "solid"
                            ? qrCodeData?.background?.color[0]
                            : qrCodeData?.background?.type === "gradient" &&
                              `linear-gradient(to right, ${qrCodeData?.background?.color[0]}, ${qrCodeData?.background?.color[1]})`,
                        }}
                      >
                        <div
                          ref={ref}
                          id="qr-container"
                          className={`p-4 ${
                            qrCodeData?.template === "Circular" &&
                            "rounded-full border-4 border-secondary p-8"
                          }`}
                        />
                        <div
                          className="p-1 py-3 mt-2"
                          style={{
                            backgroundColor: qrCodeData?.frame?.color,
                          }}
                        >
                          <p
                            className="text-sm text-center"
                            style={{
                              color: qrCodeData?.frame?.textColor,
                            }}
                          >
                            {qrCodeData?.frame?.text}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {qrCodeData?.frame?.type === "frame3" && (
                    <div className="flex-col w-full justify-center items-center">
                      <div
                        className="border-8 rounded-lg mb-1"
                        style={{
                          borderColor: qrCodeData?.frame?.color,
                          background: qrCodeData?.background?.isTransparent
                            ? "transparent"
                            : qrCodeData?.background?.type === "solid"
                            ? qrCodeData?.background?.color[0]
                            : qrCodeData?.background?.type === "gradient" &&
                              `linear-gradient(to right, ${qrCodeData?.background?.color[0]}, ${qrCodeData?.background?.color[1]})`,
                        }}
                      >
                        <div
                          ref={ref}
                          id="qr-container"
                          className={`p-4 ${
                            qrCodeData?.template === "Circular" &&
                            "rounded-full border-4 border-secondary p-8"
                          }`}
                        />
                        <div className="bg-transparent p-1 py-3 mt-2">
                          <p
                            className="text-sm text-center"
                            style={{
                              color: qrCodeData?.frame?.textColor,
                            }}
                          >
                            {qrCodeData?.frame?.text}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {qrCodeData?.frame?.type === "frame4" && (
                    <div className="flex-col w-full justify-center items-center">
                      <div
                        className="mb-1"
                        style={{
                          background: qrCodeData?.background?.isTransparent
                            ? "transparent"
                            : qrCodeData?.background?.type === "solid"
                            ? qrCodeData?.background?.color[0]
                            : qrCodeData?.background?.type === "gradient" &&
                              `linear-gradient(to right, ${qrCodeData?.background?.color[0]}, ${qrCodeData?.background?.color[1]})`,
                        }}
                      >
                        <div
                          ref={ref}
                          id="qr-container"
                          className={`${
                            qrCodeData?.template === "Circular" &&
                            "rounded-full border-4 border-secondary p-8"
                          }`}
                        />
                        <div className="bg-transparent p-1 py-3 mt-2">
                          <p
                            className="text-sm text-center"
                            style={{
                              color: qrCodeData?.frame?.textColor,
                            }}
                          >
                            {qrCodeData?.frame?.text}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {qrCodeData?.frame?.type === "frame5" && (
                    <div className="flex-col w-full justify-center items-center">
                      <div
                        className="border-8 rounded-lg mb-1"
                        style={{
                          borderColor: qrCodeData?.frame?.color,
                          background: qrCodeData?.background?.isTransparent
                            ? "transparent"
                            : qrCodeData?.background?.type === "solid"
                            ? qrCodeData?.background?.color[0]
                            : qrCodeData?.background?.type === "gradient" &&
                              `linear-gradient(to right, ${qrCodeData?.background?.color[0]}, ${qrCodeData?.background?.color[1]})`,
                        }}
                      >
                        <div
                          ref={ref}
                          id="qr-container"
                          className={`p-4 ${
                            qrCodeData?.template === "Circular" &&
                            "rounded-full border-4 border-secondary p-8"
                          }`}
                        />
                        <div
                          className="border-t-8 bg-transparent p-1 py-3 mt-2"
                          style={{
                            borderColor: qrCodeData?.frame?.color,
                          }}
                        >
                          <p
                            className="text-sm text-center"
                            style={{
                              color: qrCodeData?.frame?.textColor,
                            }}
                          >
                            {qrCodeData?.frame?.text}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div id="qr-main">
                  <div
                    ref={ref}
                    id="qr-container"
                    className={`${
                      qrCodeData?.template === "Circular" &&
                      "rounded-full border-4 border-secondary p-8"
                    }`}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VcardPreview;
