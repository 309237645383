import React from "react";
import { Divider } from "antd";
import { ThemeColorPicker } from "../../ui/ui";

const BackgroundSection = ({ formData, setFormData }) => {
  const onHandleColorChange = (e, index) => {
    let values = [...formData?.background?.color];
    values[index] = e.toHexString();
    setFormData({
      ...formData,
      background: { ...formData?.background, color: values },
    });
  };

  return (
    <div>
      <div className="mb-10">
        <h1 className="text-secondary text-lg font-bold mb-1">
          Select Background:
        </h1>
        <p className="text-xs text-themeGray-200 mb-6">
          Select a background color or image for your QR code.
        </p>
        <div className="flex items-center mb-2">
          <div
            className={`flex items-center cursor-pointer  ${
              formData?.background?.type === "solid" && "text-primary"
            }`}
            onClick={() =>
              setFormData({
                ...formData,
                background: {
                  ...formData?.background,
                  type: "solid",
                  isTransparent: false,
                },
              })
            }
          >
            <div className="h-2 w-2 rounded-full bg-primary"></div>
            <p className="text-sm ml-2">Solid color</p>
          </div>
          <div
            className={`flex items-center cursor-pointer ml-10 ${
              formData?.background?.type === "gradient" && "text-primary"
            }`}
            onClick={() =>
              setFormData({
                ...formData,
                background: {
                  ...formData?.background,
                  type: "gradient",
                  isTransparent: false,
                },
              })
            }
          >
            <div className="h-2 w-2 rounded-full bg-primary"></div>
            <p className="text-sm ml-2">Gradient</p>
          </div>
          <div
            className={`flex items-center cursor-pointer ml-10 ${
              formData?.background?.type === "transparent" && "text-primary"
            }`}
            onClick={() =>
              setFormData({
                ...formData,
                background: {
                  ...formData?.background,
                  type: "transparent",
                  isTransparent: true,
                },
              })
            }
          >
            <div className="h-2 w-2 rounded-full bg-primary"></div>
            <p className="text-sm ml-2">Transparent</p>
          </div>
        </div>
        {formData?.background?.type !== "transparent" && (
          <div className="flex items-center">
            <ThemeColorPicker
              value={formData?.background?.color[0]}
              onChange={(e) => onHandleColorChange(e, 0)}
            />
            {formData?.background?.type === "gradient" && (
              <div className="ml-10">
                <ThemeColorPicker
                  value={formData?.background?.color[1]}
                  onChange={(e) => onHandleColorChange(e, 1)}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BackgroundSection;
