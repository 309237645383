import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getValueFromStorage } from "../helpers/helpers";

export const QrStatsApi = createApi({
  reducerPath: "qrStats",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getValueFromStorage("token")}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    addStat: builder.mutation({
      query: (body) => ({
        url: `/api/qr-scan`,
        method: "POST",
        body: body,
      }),
    }),
    getStatsById: builder.query({
      query: (id) => ({
        url: `/api/qr-scan/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useAddStatMutation,
  useGetStatsByIdQuery,
  useLazyGetStatsByIdQuery,
} = QrStatsApi;
