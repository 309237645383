import React from "react";
import linkTypes from "../../constants/data/social.json";
import { ThemeInput } from "../../ui/ui";
import * as social_icons from "../../assets/icons/socials/socials";
import { delete_icon } from "../../assets/icons/icons";

const SocialLink = ({ value, onChange, isOne, isText }) => {
  const getSocialType = (e) => {
    const type = linkTypes?.find((linkType) => linkType?.name === e);
    return type;
  };

  const addLink = (type) => {
    let values = value ? [...value] : [];
    values.push({
      type: type,
    });
    onChange(values);
  };

  const onHandleLink = (e, i) => {
    let values = [...value];
    values[i] = { ...values[i], value: e.target.value };
    onChange(values);
  };

  const onInputChange = (e, input, i) => {
    let values = [...value];
    values[i] = {
      ...values[i],
      value: { ...values[i]?.value, [input]: e.target.value },
    };
    onChange(values);
  };

  const onDelete = (i) => {
    let values = [...value];
    values.splice(i, 1);
    onChange(values);
  };

  return (
    <div>
      {!isOne && (
        <div>
          {value?.length > 0 &&
            value?.map((link, index) => {
              return (
                <div key={index} className="mb-6">
                  <div className="flex items-center mb-2 justify-between">
                    <div className="flex items-center">
                      <img
                        src={social_icons[getSocialType(link?.type)?.icon]}
                        alt="icon"
                        width={24}
                        height={24}
                      />
                      <span className="ml-4 text-primary font-semibold text-sm">
                        {getSocialType(link?.type)?.name}
                      </span>
                    </div>

                    <img
                      src={delete_icon}
                      alt="delete"
                      width={20}
                      height={20}
                      className="ml-4 cursor-pointer"
                      onClick={() => onDelete(index)}
                    />
                  </div>
                  <div className="flex items-center">
                    {isText ? (
                      <div className="mt-2 w-full">
                        <div className="mb-4 w-full">
                          <ThemeInput
                            label={"URL"}
                            value={link?.value?.url}
                            onChange={(e) => onInputChange(e, "url", index)}
                            placeholder={"https://"}
                            className={"w-full"}
                          />
                          <p className="text-sm text-red-600 mt-1">
                            {link?.errorMessage}
                          </p>
                        </div>
                        <ThemeInput
                          label={"Text"}
                          value={link?.value?.text}
                          onChange={(e) => onInputChange(e, "text", index)}
                          placeholder={"Visit us online"}
                          className={"w-full mb-4"}
                        />
                      </div>
                    ) : (
                      <div className="w-full">
                        <ThemeInput
                          value={link?.value}
                          onChange={(e) => onHandleLink(e, index)}
                          placeholder={"www.example/page.com"}
                          className={"w-full"}
                        />
                        <p className="text-sm text-red-600 mt-1">
                          {link?.errorMessage}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      )}
      <div className="flex items-center flex-wrap -ml-4">
        {linkTypes?.map((linkType, index) => {
          return (
            <span
              className={`p-4 ${
                isOne &&
                value === linkType?.icon &&
                "bg-[#eff4ff] border rounded border-primary"
              } `}
              key={index}
            >
              <img
                key={index}
                src={social_icons[linkType?.icon]}
                alt="icon"
                className="w-9 h-9 cursor-pointer"
                onClick={() =>
                  isOne ? onChange(linkType?.icon) : addLink(linkType?.name)
                }
              />
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default SocialLink;
