import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import {
  QrTypesApi,
  QrCodeApi,
  AuthApi,
  QrStatsApi,
  QrInteractionApi,
  FoldersApi,
  PurchaseApi,
} from "../services/services";

export const store = configureStore({
  reducer: {
    [AuthApi.reducerPath]: AuthApi.reducer,
    [QrTypesApi.reducerPath]: QrTypesApi.reducer,
    [QrCodeApi.reducerPath]: QrCodeApi.reducer,
    [QrStatsApi.reducerPath]: QrStatsApi.reducer,
    [QrInteractionApi.reducerPath]: QrInteractionApi.reducer,
    [FoldersApi.reducerPath]: FoldersApi.reducer,
    [PurchaseApi.reducerPath]: PurchaseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      AuthApi.middleware,
      QrTypesApi.middleware,
      QrCodeApi.middleware,
      QrStatsApi.middleware,
      QrInteractionApi.middleware,
      FoldersApi.middleware,
      PurchaseApi.middleware
    ),
});

setupListeners(store.dispatch);
