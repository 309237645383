import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Container, QrTypes } from "../../components/components";
import { Spin } from "antd";
import { useGetQrTypesQuery } from "../../services/qrTypes";
import { hero } from "../../assets/images/images";
import { qrCodeBlack, qrCodeBlue } from "../../assets/icons/icons";
import { useLazyGetUserQuery } from "../../services/auth";

const HomeMob = ({ selectedKey, onChange, isLoading, data, user }) => {
  return (
    <div className="md:hidden block container">
      <h1 className="text-3xl font-bold font-MontserratBold mb-4 leading-10 my-8">
        <span className="text-primary">Create</span> &
        <span className="text-primary"> Customize</span> Your
        <span className="text-primary"> Dynamic</span> QR Code
      </h1>

      <div className="bg-themeGray-light flex rounded-lg mt-4">
        <div
          className={`${
            selectedKey == "dynamic"
              ? "bg-secondary text-white"
              : "bg-inherit text-black"
          } w-6/12 p-6 flex items-center justify-center flex-col rounded-s-lg cursor-pointer box-inset-shadow`}
          onClick={() => onChange("dynamic")}
        >
          <img src={selectedKey == "dynamic" ? qrCodeBlue : qrCodeBlack} />
          <span className="font-semibold text-base mt-2">Dynamic</span>
        </div>
        <div
          className={`${
            selectedKey == "static"
              ? "bg-secondary text-white"
              : "bg-inherit text-black"
          } w-6/12 p-6 flex items-center justify-center flex-col rounded-e-l cursor-pointer rounded-e-lg box-inset-shadow`}
          onClick={() => onChange("static")}
        >
          <img src={selectedKey == "static" ? qrCodeBlue : qrCodeBlack} />
          <span className="font-semibold text-base mt-2">Static</span>
        </div>
      </div>

      <div className="mt-10">
        {isLoading ? (
          <div className="flex items-center justify-center h-72">
            <Spin size="large" />
          </div>
        ) : (
          <QrTypes
            user={user}
            isStatic={selectedKey === "dynamic" ? false : true}
            qrTypes={data?.data || []}
          />
        )}
      </div>
    </div>
  );
};

const Home = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("tab");
  const [selectedKey, setSelectedKey] = useState(type || "dynamic");
  const [user, setUser] = useState(false);
  const [getUserInfo, { isLoading: fetchingUserInfo }] = useLazyGetUserQuery();
  const { data, isLoading, refetch } = useGetQrTypesQuery();

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    let res = await getUserInfo();
    console.log("user data", res?.data?.data);
    setUser(res?.data?.data);
  };

  const onChange = (key) => {
    setSearchParams({ tab: key });
    setSelectedKey(key);
  };

  if (fetchingUserInfo) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Container>
      <div className="h-[calc(100vh-60px)]  md:px-0 px-5 md:block hidden">
        <div className="container mx-auto flex justify-between w-full items-center ">
          <div className="md:p-12 p-4 md:px-12 rounded-md box-shadow overflow-y-auto md:w-6/12 max-h-[calc(100vh-80px)] thin-scroll">
            <h1 className="md:text-4xl text-2xl font-bold font-MontserratBold mb-4 leading-10">
              <span className="text-primary">Create</span> &
              <span className="text-primary"> Customize</span> Your
              <span className="text-primary"> Dynamic</span> QR Code
            </h1>

            <div className="bg-themeGray-light flex rounded-lg mt-12 max-h-[calc(100vh-60px)]">
              <div
                className={`${
                  selectedKey == "dynamic"
                    ? "bg-secondary text-white"
                    : "bg-inherit text-black"
                } w-6/12 p-6 flex items-center justify-center flex-col rounded-s-lg cursor-pointer box-inset-shadow`}
                onClick={() => onChange("dynamic")}
              >
                <img
                  src={selectedKey == "dynamic" ? qrCodeBlue : qrCodeBlack}
                />
                <span className="font-semibold text-sm mt-2">Dynamic</span>
              </div>
              <div
                className={`${
                  selectedKey == "static"
                    ? "bg-secondary text-white"
                    : "bg-inherit text-black"
                } w-6/12 p-6 flex items-center justify-center flex-col rounded-e-l cursor-pointer rounded-e-lg box-inset-shadow`}
                onClick={() => onChange("static")}
              >
                <img src={selectedKey == "static" ? qrCodeBlue : qrCodeBlack} />
                <span className="font-semibold text-sm mt-2">Static</span>
              </div>
            </div>

            <div className="mt-12">
              {isLoading ? (
                <div className="flex items-center justify-center h-72">
                  <Spin size="large" />
                </div>
              ) : (
                <QrTypes
                  isStatic={selectedKey === "dynamic" ? false : true}
                  user={user}
                  qrTypes={data?.data || []}
                />
              )}
            </div>
          </div>
          <div className="h-[calc(100vh-60px)] py-12 px-4 bg-themeGray-dark md:flex flex-col  hidden">
            <h1 className="text-lg text-secondary text-center mb-2 font-Montserrat font-semibold">
              Preview
            </h1>
            <span className="h-[2px] bg-secondary mb-12"></span>
            <img src={hero} className="lg:h-[500px] h-[400px]" />
          </div>
        </div>
      </div>
      <HomeMob
        selectedKey={selectedKey}
        onChange={onChange}
        isLoading={isLoading}
        data={data}
        user={user}
      />
    </Container>
  );
};

export default Home;
