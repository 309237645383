import React from "react";
import { Select } from "antd";
import { dropdown_icon } from "../assets/icons/icons";

const ThemeSelect = ({
  value,
  onChange,
  placeholder,
  options,
  className,
  defaultValue,
  ...props
}) => {
  return (
    <Select
      {...props}
      style={{ height: 32 }}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={`${className} selectBox inputBox-shadow`}
      options={options}
      suffixIcon={<img src={dropdown_icon} width={12} height={12} />}
    />
  );
};

export default ThemeSelect;
