const BASE_URL_DEV = "http://192.168.100.57:3000/";
const BASE_URL_PROD = "https://qrinsight-6bb58.df.r.appspot.com/";
const PREVIEW_URL_DEV = "http://192.168.100.57:3001/";
const PREVIEW_URL_PROD = "https://www.myqr.live/";
const BASE_URL = BASE_URL_PROD;
const PREVIEW_URL = PREVIEW_URL_PROD;
const CLODINARY_CLOUD_NAME = "date1bmhd";
const CLOUDINARY_URL = `https://api.cloudinary.com/v1_1/${CLODINARY_CLOUD_NAME}/image/upload`;
const CLOUDINARY_PRESET = "tvgxcevu";

export {
  BASE_URL,
  PREVIEW_URL,
  CLOUDINARY_URL,
  CLODINARY_CLOUD_NAME,
  CLOUDINARY_PRESET,
};
