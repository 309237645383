import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getValueFromStorage } from "../helpers/helpers";

export const QrTypesApi = createApi({
  reducerPath: "qrTypes",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getValueFromStorage("token")}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getQrTypes: builder.query({
      query: () => ({
        url: `/api/qr-types`,
        method: "GET",
      }),
    }),
    getQrTypeByID: builder.query({
      query: (id) => ({
        url: `/api/qr-types/${id}`,
        method: "GET",
      }),
    }),
    putQrType: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/api/qr-types/${id}`,
          method: "PUT",
          body: body,
        };
      },
    }),
    addQrType: builder.mutation({
      query: (body) => ({
        url: `/api/qr-types`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useAddQrTypeMutation,
  usePutQrTypeMutation,
  useGetQrTypesQuery,
  useLazyGetQrTypesQuery,
  useGetQrTypeByIDQuery,
  useLazyGetQrTypeByIDQuery,
} = QrTypesApi;
