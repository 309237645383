import React from "react";
import { Modal, Button, Spin } from "antd";
import { ThemeInput, ThemeButton } from "../../ui/ui";
const FoldarModal = ({
  open,
  onClose,
  onSubmit,
  formData,
  setFormData,
  isLoading,
  mode,
}) => {
  return (
    <Modal
      open={open}
      centered={true}
      title={
        <p className="text-xl font-bold text-center">
          {mode === "create" ? "Add" : "Update"} Folder
        </p>
      }
      onCancel={onClose}
      footer={
        <div className="flex items-center justify-between">
          <Button key="cancel" onClick={onClose} className="h-9">
            Cancel
          </Button>
          <ThemeButton
            content={
              <>
                {isLoading ? (
                  <Spin />
                ) : (
                  <p>{mode === "create" ? "Add" : "Update"}</p>
                )}
              </>
            }
            onClick={onSubmit}
          />
        </div>
      }
    >
      <div className="my-6">
        <ThemeInput
          label={"Folder name"}
          labelPrimary
          inputHeight={"h-11"}
          value={formData?.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />
      </div>
    </Modal>
  );
};

export default FoldarModal;
