import React, { useState } from "react";
import { Rate } from "antd";
import { ThemeTextArea, ThemeButton } from "../../ui/ui";
import * as icons from "../../assets/icons/icons";
import * as images from "../../assets/images/images";

const RatingPreview = ({ type }) => {
  const faces = [
    "excellent_face_icon",
    "good_face_icon",
    "normal_face_icon",
    "sad_face_icon",
    "very_sad_face_icon",
  ];
  const thumbs = ["thumbs_up_active_icon", "thumbs_down_icon"];
  const primaryColor = type?.sections[4]?.inputs[0]?.value[0];
  const buttonColor = type?.sections[4]?.inputs[0]?.value[1];
  const headingColor = type?.sections[4]?.inputs[0]?.value[2];

  const ratingData = {
    title: "Lake Lorem",
    image: "rating_banner",
    question: "How was your rental experience",
    website: "www.loremlake.com",
  };

  const [rating, setRating] = useState();
  const [comments, setComments] = useState();

  return (
    <div className="rounded-t-3xl">
      <div className="">
        <div
          className="px-4 py-6 h-24 rounded-t-3xl"
          style={{ backgroundColor: primaryColor }}
        >
          <p
            className="text-xs font-MontserratBold"
            style={{ color: headingColor }}
          >
            {type?.sections[0]?.inputs[2]?.value || ratingData?.title}
          </p>
        </div>
        <div className="-mt-11">
          <div className="px-3 flex justify-center">
            <img
              src={
                type?.sections[0]?.inputs[0]?.value || images[ratingData?.image]
              }
              // className="h-40"
              className={`${
                type?.sections[0]?.inputs[1]?.value === "square"
                  ? "h-32 w-32 rounded object-cover"
                  : type?.sections[0]?.inputs[1]?.value === "circle"
                  ? "h-32 w-32 rounded-full object-cover"
                  : "w-full h-40"
              }`}
              alt="image"
            />
          </div>
          <div className="px-3 bg-[#F5F5F5]">
            <div className="h-full bg-white px-2 py-6 flex flex-col items-center">
              <p className="text-[11px] text-center w-full font-MontserratBold mb-4">
                {type?.sections[2]?.inputs[0]?.value || ratingData?.question}?
              </p>
              <div>
                {/* <Rating
                emptySymbol={
                  <img src="/icons/thumbs-up-active.svg" className="icon" />
                }
                fullSymbol={
                  <img src="/icons/thumbs-up-active.svg" className="icon" />
                }
                step={1}
                start={0}
                stop={1}
                className="mx-2"
              />
              <Rating
                emptySymbol={
                  <img src="/icons/thumbs-down.svg" className="icon" />
                }
                fullSymbol={
                  <img src="/icons/thumbs-down.svg" className="icon" />
                }
                step={1}
                start={0}
                stop={1}
                className="mx-2"
              /> */}
                {type?.sections[2]?.inputs[1]?.value === "thumb" ? (
                  <Rate
                    count={2}
                    character={({ index }) => {
                      return <img src={icons[thumbs[index]]} />;
                    }}
                    style={{ color: "#2965FF" }}
                  />
                ) : type?.sections[2]?.inputs[1]?.value === "face" ? (
                  <Rate
                    count={5}
                    character={({ index }) => {
                      return <img src={icons[faces[index]]} />;
                    }}
                    style={{ color: "#2965FF" }}
                  />
                ) : (
                  type?.sections[2]?.inputs[1]?.value === "star" && (
                    <Rate
                      count={5}
                      style={{ color: "#2965FF" }}
                      value={rating}
                      onChange={(e) => setRating(e)}
                    />
                  )
                )}
              </div>
              {rating ? (
                <div className="w-full px-2">
                  <hr className="my-2 w-full" />
                  <ThemeTextArea
                    placeholder={"Add a comment (optional)"}
                    inputClassName={"text-xs w-full"}
                    minRows={4}
                    maxRows={4}
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  />
                </div>
              ) : (
                <p className="text-[10px] py-16">
                  {type?.sections[3]?.inputs[2]?.value || ratingData?.website}?
                </p>
              )}
            </div>
            <ThemeButton
              className="w-full !h-10 !text-xs mb-10 !rounded-none"
              style={{ backgroundColor: buttonColor, color: primaryColor }}
              content={"Send Rating"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingPreview;
