import React from "react";
import { Switch } from "antd";

const ThemeSwitch = ({ defaultChecked, label, onChange, checked }) => {
  return (
    <div className="flex items-center">
      <Switch
        defaultChecked={defaultChecked}
        onChange={onChange}
        checked={checked}
        className={`${checked ? "bg-primary" : "bg-themeGray-200"}`}
      />
      <p
        className={`text-sm ${
          checked ? "text-primary" : "text-themeGray-200"
        } ml-2`}
      >
        {label}
      </p>
    </div>
  );
};

export default ThemeSwitch;
