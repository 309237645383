import React from "react";
import * as images from "../../assets/images/images";

const RatingType = ({ value, onChange }) => {
  const ratingTypes = [
    {
      type: "thumb",
      image: "thumbs",
    },
    {
      type: "face",
      image: "faces",
    },
    {
      type: "star",
      image: "stars",
    },
  ];
  return (
    <div className="flex items-center">
      {ratingTypes.map((type, index) => {
        return (
          <div
            key={index}
            className={`bg-[#E7E7E7] rounded w-40 h-20 mx-2 flex items-center justify-center hover:border-primary hover:border-2 cursor-pointer ${
              value === type?.type && "border-2 border-primary"
            }`}
            onClick={() => onChange(type?.type)}
          >
            <img src={images[type?.image]} alt="rating" />
          </div>
        );
      })}
    </div>
  );
};

export default RatingType;
