import React, { useState } from "react";
import { ThemeInput, ThemeButton } from "../../ui/ui";
import { Form, Divider, Spin, message } from "antd";
import { loginHero } from "../../assets/images/images";
import {
  google_icon,
  facebook_circle_blue_icon,
} from "../../assets/icons/icons";
import { useNavigate } from "react-router-dom";
import { useSetLoginMutation } from "../../services/auth";
import { addKeyToStorage } from "../../helpers/helpers";

const Login = ({ setAuthUser }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [setLogin, { isLoading }] = useSetLoginMutation();

  const handleLogin = async () => {
    if (formData?.email && formData?.password) {
      const response = await setLogin({
        email: formData?.email,
        password: formData?.password,
      });
      if (response?.data?.status?.code === 200) {
        console.log("response", response);
        addKeyToStorage("token", response.data.data.token);
        setAuthUser(response.data.data.user._id);
        navigate("/");
      } else {
        message.error(response?.error?.data?.message || "");
      }
    }
  };
  return (
    <div className="w-full h-screen flex">
      <div className="login-gradiant h-full w-6/12 md:flex hidden flex-col justify-center items-center">
        <div className="container flex flex-col items-center">
          <img src={loginHero} alt="image" className="mb-5" />
          <p className="text-white font-MontserratBold text-center">
            You are just a few steps away <br /> from your QR Code
          </p>
        </div>
      </div>
      <div className="md:w-6/12 w-full h-full flex flex-col items-center justify-center bg-themeGray-medium p-2">
        <div className="container">
          <div className="mb-12">
            <h1 className="text-3xl text-[#002B38] font-MontserratBold text-center">
              Welcome Back!
            </h1>
            <p className="text-center text-[#817E7E] text-sm font-semibold">
              Log in to start your session
            </p>
          </div>
          <div className="w-full">
            <Form name="normal_login" className="login-form w-full mt-5">
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: `Please input your UserName!`,
                  },
                ]}
              >
                <ThemeInput
                  placeholder={"Your email"}
                  type={"email"}
                  value={formData?.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  inputHeight={"h-12"}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
                className="mb-0"
              >
                <ThemeInput
                  placeholder={"Your Password"}
                  password
                  value={formData?.password}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                  inputHeight={"h-12"}
                />
              </Form.Item>

              <Form.Item className="w-full mb-2">
                <ThemeButton
                  type="primary"
                  htmlType="submit"
                  onClick={handleLogin}
                  className={"!h-12 w-full flex mt-12"}
                  content={
                    <>
                      {isLoading ? (
                        <Spin className="mt-1 loader-white" />
                      ) : (
                        <p className="text-sm uppercase">Log in</p>
                      )}
                    </>
                  }
                />
              </Form.Item>

              <Form.Item className="flex justify-end">
                <a className="text-[#7E7B7B] text-base hover:!text-primary hover:underline cursor-pointer">
                  Forgot your Password?
                </a>
              </Form.Item>

              <Form.Item className="my-0">
                <Divider orientation="center" className="font-MontserratBold">
                  OR
                </Divider>
              </Form.Item>

              <Form.Item className="mb-0">
                <div className="flex flex-col items-center justify-center">
                  <ThemeButton
                    type="primary"
                    className={
                      "!h-10 w-56 flex border-primary text-[#151515] mx-4 mb-4"
                    }
                    bgColor={"bg-transparent"}
                    content={
                      <>
                        <div className="flex items-center">
                          <img
                            src={google_icon}
                            alt=""
                            width={22}
                            height={22}
                          />
                          <p className="text-sm ml-2">Sign up With Google</p>
                        </div>
                      </>
                    }
                  />
                  <ThemeButton
                    type="primary"
                    className={
                      "!h-10 w-56 flex border-primary text-[#151515] mx-4 mb-4"
                    }
                    bgColor={"bg-transparent"}
                    content={
                      <>
                        <div className="flex items-center">
                          <img
                            src={facebook_circle_blue_icon}
                            alt=""
                            width={22}
                            height={22}
                          />
                          <p className="text-sm ml-2">Sign up With Facebook</p>
                        </div>
                      </>
                    }
                  />
                </div>
              </Form.Item>

              <Form.Item>
                <p className="text-[#797777] text-sm text-center">
                  New User?
                  <a
                    className="text-primary cursor-pointer underline"
                    onClick={() => navigate("/signup")}
                  >
                    Sign In here
                  </a>
                </p>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
