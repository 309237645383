import React from "react";
import { getSocialType } from "../../helpers/helpers";
import * as social_icons from "../../assets/icons/socials/socials";
import * as images from "../../assets/images/images";

const BioPreview = ({ type }) => {
  const bioData = {
    image: "bio_banner",
    title: "HARRY TAYLOR",
    about: "Designer & Photographer",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tempus turpis lorem, in dictum velit fermentum in. Etiam metus nulla, aliquam a sapien in, porta bibendum diam. Aliquam erat volutpat. Sed magna enim, sodales sed laoreet in, aliquam et arcu. Phasellus",
  };
  return (
    <div className="md:py-2 h-full">
      <div className="md:rounded-3xl h-full">
        <div className="relative">
          <img
            src={type?.sections[0]?.inputs[0]?.value || images[bioData?.image]}
            alt="banner"
            className="md:h-48 h-52 w-full md:rounded-t-3xl"
          />
          <img
            src={images.bio_banner_shade}
            alt="banner"
            className="absolute top-0 left-0 md:h-48 h-52 object-cover min-w-full max-w-full w-full md:rounded-t-3xl"
          />
          <div className="flex justify-center w-full items-center flex-col text-white absolute bottom-4">
            <h1 className="md:text-lg text-2xl font-MontserratBold">
              {type?.sections[0]?.inputs[1]?.value || bioData?.title}
            </h1>
            <p className="md:text-sm text-lg">
              {type?.sections[0]?.inputs[2]?.value || bioData?.about}
            </p>
          </div>
        </div>
        <div className="bg-[#F5F5F5] w-full py-4 flex flex-col items-center md:px-2 px-3 md:rounded-b-3xl h-full">
          {type?.sections[1]?.inputs[0]?.value?.length > 0 &&
            type?.sections[1]?.inputs[0]?.value?.map((link, index) => {
              return (
                <a
                  key={index}
                  href={link?.url}
                  target="_blank"
                  className="w-10/12 bg-[#0B2D86] rounded  text-white text-center md:py-2 py-3 mb-6 md:text-sm text-base"
                >
                  {link?.title || "Click here"}
                </a>
              );
            })}

          <p className="text-justify xl:text-xs lg:text-xs md:text-xs text-base font-semibold xl:leading-6 lg:leading-6 md:leading-6 leading-8 mb-10">
            {type?.sections[0]?.inputs[3]?.value || bioData?.description}
          </p>

          <div className="flex items-center justify-center flex-wrap">
            {type?.sections[2]?.inputs[0]?.value?.length > 0 &&
              type?.sections[2]?.inputs[0]?.value?.map((link, index) => {
                return (
                  <div className="flex items-center p-2" key={index}>
                    <a
                      href={link?.value}
                      target="_blank"
                      className="cursor-pointer"
                    >
                      <img
                        src={social_icons[getSocialType(link?.type)?.icon]}
                        alt="icon"
                        className="lg:w-7 lg:h-7 md:w-7 md:h-7 w-10 h-10"
                      />
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BioPreview;
