import { Input } from "antd";
const { TextArea } = Input;

const ThemeTextArea = ({
  className,
  label,
  labelPrimary,
  textMd,
  required,
  placeholder,
  minRows,
  maxRows,
  inputClassName,
  value,
  onChange,
  disabled,
  onKeyDown,
  inputShadow,
}) => {
  return (
    <div className={className}>
      <p
        className={`${labelPrimary && "text-primary"} ${
          textMd ? "font-medium" : "font-semibold"
        } mb-1 flex items-center text-sm`}
      >
        {required && <p className="mr-1 text-themeRed">*</p>} {label}
      </p>
      <TextArea
        placeholder={placeholder}
        autoSize={{ minRows: minRows, maxRows: maxRows }}
        className={`font-medium text-secondary ${
          inputShadow ? inputShadow : "inputBox-shadow"
        } py-3  ${inputClassName}`}
        value={value}
        onChange={onChange}
        disabled={disabled}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default ThemeTextArea;
