import React from "react";
import { Drawer, Menu } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const MenuDrawer = ({ selected, open, onClose, items, onHandleRoute }) => {
  return (
    <Drawer
      placement="left"
      open={open}
      className={"bg-white rounded-tr-md rounded-br-md p-0 sidemenu-drawer"}
      width={200}
      closable={false}
    >
      <div className="flex justify-end  p-4 pb-0">
        <CloseOutlined className="text-lg cursor-pointer" onClick={onClose} />
      </div>
      <div>
        <Menu
          onClick={onHandleRoute}
          style={{
            minHeight: "68vh",
          }}
          defaultSelectedKeys={[selected]}
          mode="inline"
          items={items}
          className="mt-4"
        />
        <div className="flex justify-center">
          <button className="border border-primary w-40 h-10 text-sm font-semibold text-primary hover:bg-primary hover:text-white cursor-pointer">
            Upgrade Now
          </button>
        </div>
      </div>
    </Drawer>
  );
};

export default MenuDrawer;
