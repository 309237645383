import React, { useEffect } from "react";
import dayjs from "dayjs";
import { DashboardContainer } from "../../components/components";
import ThemeTable from "../../ui/ThemeTable";
import { useGetPurchasesQuery } from "../../services/purchases";

const Purchases = () => {
  const { data, isLoading } = useGetPurchasesQuery();

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (data, _, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "Types",
      dataIndex: "types",
      key: "types",
      render: (types) => (
        <p className="text-center font-medium">
          {types?.map((type, index) => {
            return <span key={index}>{type?.typeId?.label}</span>;
          })}
        </p>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => <p className="text-center font-medium">{text || 0}</p>,
    },
    // {
    //   title: "Discount",
    //   dataIndex: "discount",
    //   key: "discount",
    //   render: (text) => <p className="text-center font-medium">{text || 0}</p>,
    // },
    {
      title: "Purchase For",
      dataIndex: "purchaseFor",
      key: "purchaseFor",
      render: (text) => <p className="text-center font-medium">{text}</p>,
    },
    {
      title: "Expiry Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => (
        <p className="text-center font-medium">
          {text ? dayjs(text).format("MMM DD, YYYY") : "---"}
        </p>
      ),
    },
  ];

  return (
    <DashboardContainer selected={"7"}>
      <div className="min-h-[calc(100vh-60px)] w-full bg-themeGray-light">
        <div className="w-full container py-6 ">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-MontserratBold font-bold text-secondary">
              Purchases
            </h1>
          </div>

          <div className="mt-6">
            <ThemeTable
              columns={columns}
              data={data?.purchases || []}
              loader={isLoading}
            />
          </div>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default Purchases;
