import React from "react";
import ThemeInput from "../../ui/ThemeInput";
import ThemeSelect from "../../ui/ThemeSelect";
import ThemeButton from "../../ui/ThemeButton";
import { DeleteOutlined } from "@ant-design/icons";

const CreateQuestion = ({
  value,
  onAddQuestion,
  onRemoveQuestion,
  onChange,
}) => {
  const onChangeInput = (e, questionIndex, inputIndex) => {
    let values = [...value];
    let inputs = [...values[questionIndex]?.inputs];
    if (e == "radio" || e == "checkbox") {
      inputs[inputIndex] = {
        ...inputs[inputIndex],
        value: e,
        answerOptions: [""],
      };
    } else {
      inputs[inputIndex] = { ...inputs[inputIndex], value: e };
      delete inputs[inputIndex]?.answerOptions;
    }
    values[questionIndex] = { ...values[questionIndex], inputs: inputs };
    onChange(values);
  };

  const onAddOption = (questionIndex, inputIndex) => {
    let values = [...value];
    let inputs = [...values[questionIndex]?.inputs];
    let answerOptions = [...inputs[inputIndex]?.answerOptions];
    answerOptions.push("");
    inputs[inputIndex] = {
      ...inputs[inputIndex],
      answerOptions: answerOptions,
    };
    values[questionIndex] = { ...values[questionIndex], inputs: inputs };
    onChange(values);
  };

  const onRemoveOption = (questionIndex, inputIndex, deleteIndex) => {
    let values = [...value];
    let inputs = [...values[questionIndex]?.inputs];
    let answerOptions = [...inputs[inputIndex]?.answerOptions];
    answerOptions.splice(deleteIndex, 1);
    inputs[inputIndex] = {
      ...inputs[inputIndex],
      answerOptions: answerOptions,
    };
    values[questionIndex] = { ...values[questionIndex], inputs: inputs };
    onChange(values);
  };

  const onChangeOption = (e, questionIndex, inputIndex, optionIndex) => {
    let values = [...value];
    let inputs = [...values[questionIndex]?.inputs];
    let answerOptions = [...inputs[inputIndex]?.answerOptions];
    answerOptions[optionIndex] = e;
    inputs[inputIndex] = {
      ...inputs[inputIndex],
      answerOptions: answerOptions,
    };
    values[questionIndex] = { ...values[questionIndex], inputs: inputs };
    onChange(values);
  };

  return (
    <div>
      {value?.map((question, index) => {
        return (
          <div key={index} className="mb-12">
            <div className="flex items-center justify-between">
              <p className="text-xl text-secondary font-semibold font-MontserratBold mb-4">
                Question {index + 1}
              </p>
              {index > 0 && (
                <span
                  className="text-xl text-red-600 cursor-pointer"
                  onClick={() => onRemoveQuestion(index)}
                >
                  <DeleteOutlined />
                </span>
              )}
            </div>
            <div>
              {question?.inputs?.map((input, key) => {
                if (input?.type === "text") {
                  return (
                    <div key={key} className="mb-4">
                      <ThemeInput
                        labelPrimary
                        value={input?.value}
                        label={input?.label}
                        placeholder={input?.placeholder}
                        onChange={(e) =>
                          onChangeInput(e.target.value, index, key)
                        }
                      />
                    </div>
                  );
                } else if (input?.type === "selectList") {
                  return (
                    <div key={key} className="mb-4">
                      <p
                        className={
                          "text-primary font-semibold mb-1 flex items-center text-sm"
                        }
                      >
                        {input?.label}
                      </p>
                      <ThemeSelect
                        key={key}
                        value={input?.value}
                        options={input?.options}
                        className={"w-full"}
                        onChange={(e) => onChangeInput(e, index, key)}
                      />

                      {(input?.value == "radio" ||
                        input?.value === "checkbox") && (
                        <div className="mt-8">
                          <p className="text-base text-secondary font-semibold">
                            Options
                          </p>

                          {input?.answerOptions?.map((option, optionIndex) => {
                            return (
                              <div
                                className="mb-2 flex items-center w-full"
                                key={optionIndex}
                              >
                                <ThemeInput
                                  placeholder={`option ${optionIndex + 1}`}
                                  value={option}
                                  inputHeight={"!h-10"}
                                  className={"w-full"}
                                  onChange={(e) =>
                                    onChangeOption(
                                      e.target.value,
                                      index,
                                      key,
                                      optionIndex
                                    )
                                  }
                                />
                                {input?.answerOptions.length > 1 && (
                                  <span
                                    className="text-lg text-red-600 cursor-pointer ml-2"
                                    onClick={() =>
                                      onRemoveOption(index, key, optionIndex)
                                    }
                                  >
                                    <DeleteOutlined />
                                  </span>
                                )}
                              </div>
                            );
                          })}

                          <p
                            className="underline text-secondary  hover:text-primary text-sm cursor-pointer font-semibold mt-2"
                            onClick={() => onAddOption(index, key)}
                          >
                            Add Option
                          </p>
                        </div>
                      )}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        );
      })}
      {value?.length < 10 && (
        <div className="flex items-center justify-end mt-10">
          <ThemeButton
            content={
              <div className="flex items-center">
                <svg
                  width="7"
                  height="7"
                  viewBox="0 0 7 7"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3"
                >
                  <path d="M3.237 6.297V0.603H4.329V6.297H3.237ZM0.871 3.97V2.943H6.695V3.97H0.871Z" />
                </svg>

                <p className="ml-2 text-sm">Add Question</p>
              </div>
            }
            bgColor={"!bg-transparent"}
            className={
              "!text-primary border border-primary hover:!bg-primary hover:!text-white !fill-primary hover:!fill-white"
            }
            onClick={onAddQuestion}
          />
        </div>
      )}
    </div>
  );
};

export default CreateQuestion;
