import { TimePicker } from "antd";
import React from "react";
import { dropdownBlue_icon } from "../assets/icons/icons";

const ThemeTimePicker = ({ value, onChange, className, placeholder }) => {
  return (
    <TimePicker
      value={value}
      onChange={(e) => onChange(e)}
      className={`inputBox-shadow ${className} placeholder:text-[#7D7D7D]`}
      suffixIcon={<img src={dropdownBlue_icon} />}
      placeholder={placeholder}
    />
  );
};

export default ThemeTimePicker;
