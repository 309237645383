import React, { useEffect, useState } from "react";
import { FileImageOutlined } from "@ant-design/icons";
import { ThemeRating } from "../../ui/ui";
import * as images from "../../assets/images/images";
import { star_fill2_icon, star_gray_icon } from "../../assets/icons/icons";

const MenuPreview = ({ type }) => {
  const [categories, setCategories] = useState();
  const [selectedCategory, setSelectedCategory] = useState(0);
  const primaryColor = type?.sections[3]?.inputs[0]?.value[0];
  const buttonColor = type?.sections[3]?.inputs[0]?.value[1];
  const headingColor = type?.sections[3]?.inputs[0]?.value[2];
  const menuData = {
    title: "Restaurant Name",
    banner: "menu_banner",
    description: "We aim to provide fresh and healthy snacks people on the go.",
    rating: 3,
    categories: [
      {
        name: "Burger",
        products: [
          {
            title: "Lorem Ipsum",
            description: "Loremimpsum",
            price: 10.0,
            image: "product",
          },
          {
            title: "Lorem Ipsum",
            description: "Loremimpsum",
            price: 10.0,
            image: "product",
          },
          {
            title: "Lorem Ipsum",
            description: "Loremimpsum",
            price: 10.0,
            image: "product",
          },
        ],
      },
      {
        name: "Burger",
        products: [
          {
            title: "Lorem Ipsum",
            description: "Loremimpsum",
            price: 10.0,
            image: "product",
          },
          {
            title: "Lorem Ipsum",
            description: "Loremimpsum",
            price: 10.0,
            image: "product",
          },
          {
            title: "Lorem Ipsum",
            description: "Loremimpsum",
            price: 10.0,
            image: "product",
          },
        ],
      },
      {
        name: "Burger",
        products: [
          {
            title: "Lorem Ipsum",
            description: "Loremimpsum",
            price: 10.0,
            image: "product",
          },
          {
            title: "Lorem Ipsum",
            description: "Loremimpsum",
            price: 10.0,
            image: "product",
          },
          {
            title: "Lorem Ipsum",
            description: "Loremimpsum",
            price: 10.0,
            image: "product",
          },
        ],
      },
    ],
  };

  useEffect(() => {
    getCategories();
  }, [type]);

  const getCategories = () => {
    let list;
    if (type?.sections[2]?.inputs[0]?.value?.length > 0) {
      list = type?.sections[2]?.inputs[0]?.value;
    } else {
      list = menuData?.categories;
    }
    setCategories(list);
  };

  return (
    <div className="h-full">
      <div
        className="md:rounded-t-3xl"
        style={{ backgroundColor: primaryColor }}
      >
        <h1 className="text-white p-4 rounded-t-3xl  md:text-sm text-xl font-semibold px-5 w-full">
          {type?.sections[1]?.inputs[0]?.value || menuData?.title}
        </h1>
        <div
          className={`md:h-36 h-48 ${
            type?.sections[0]?.inputs[1]?.value === "square"
              ? "flex items-center justify-center"
              : type?.sections[0]?.inputs[1]?.value === "circle" &&
                "flex items-center justify-center w-full"
          }`}
        >
          <img
            src={
              type?.sections[0]?.inputs[0]?.value || images[menuData?.banner]
            }
            alt="banner"
            className={`${
              type?.sections[0]?.inputs[1]?.value === "square"
                ? "md:h-32 md:w-32 w-40 h-40 rounded object-cover"
                : type?.sections[0]?.inputs[1]?.value === "circle"
                ? "md:h-32 md:w-32 h-40 w-40 rounded-full object-cover"
                : "w-full md:h-40 h-48 object-fill"
            }`}
          />
        </div>

        <div className="py-8 px-5 flex flex-col text-white">
          {/* <h1 className="text-sm font-medium mb-2">Eat. Refresh. Go.</h1> */}
          <p className="md:text-xs text-base  mb-2">
            {type?.sections[1]?.inputs[1]?.value || menuData?.description}
          </p>
          <ThemeRating
            empty={star_gray_icon}
            full={star_fill2_icon}
            value={type?.sections[1]?.inputs[4]?.value || menuData?.rating}
            readonly
          />
        </div>
      </div>
      <div className="bg-[#F5F5F5] h-full flex flex-col justify-between">
        <div>
          <div className="py-3 overflow-x-auto thin-scroll border-b border-b-[#BEBEBE] flex">
            {categories?.map((category, index) => {
              return (
                <div className="flex flex-col px-2" key={index}>
                  <p
                    className="md:text-xs text-base font-semibold mx-3 cursor-pointer whitespace-nowrap"
                    style={{ color: headingColor }}
                    key={index}
                    onClick={() => setSelectedCategory(index)}
                  >
                    {category?.name}
                  </p>
                  <span
                    className={`h-[2px] w-full transition-all duration-300 ${
                      selectedCategory == index ? "flex" : "hidden"
                    }`}
                    style={{ backgroundColor: primaryColor }}
                  ></span>
                </div>
              );
            })}
          </div>
          {categories && categories[selectedCategory]?.products?.length ? (
            categories[selectedCategory]?.products?.map((product, index) => {
              return (
                <div
                  className="py-3 md:px-3 px-5 flex items-center justify-between border-b border-b-[#BEBEBE]"
                  key={index}
                >
                  <div className="flex flex-col justify-between h-full">
                    <span>
                      <h2 className="text-base font-bold mb-0 leading-0">
                        {product?.title}
                      </h2>
                      <span className="text-[#7D7D7D] text-xs -mt-4">
                        {product?.description}
                      </span>
                    </span>
                    <span className="text-sm" style={{ color: headingColor }}>
                      {product?.price} $
                    </span>
                  </div>
                  <div>
                    {product?.image ? (
                      <img
                        src={
                          type?.sections[2]?.inputs[0]?.value?.length > 0
                            ? product?.image
                            : images[product?.image]
                        }
                        alt="product"
                        className="md:w-20 md:h-14 w-24 h-20"
                      />
                    ) : (
                      <div className="bg-white rounded w-[70px] h-[70px] flex items-center justify-center">
                        <FileImageOutlined className="text-3xl text-[#7d7d7d]" />
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="flex items-center justify-center h-full">
              <h1 className="text-center md:text-xs text-base text-secondary font-semibold my-4">
                No product available
              </h1>
            </div>
          )}
        </div>
        {type?.sections[1]?.inputs[3]?.value && (
          <div className="flex items-center justify-center w-full">
            <a
              href={type?.sections[1]?.inputs[3]?.value[1] || ""}
              target="_blank"
              className="w-11/12"
            >
              <button
                className="w-full rounded-md md:h-10 h-14 mb-4 text-white md:text-sm text-base font-semibold"
                style={{ backgroundColor: buttonColor }}
              >
                {type?.sections[1]?.inputs[3]?.value[0]}
              </button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuPreview;
