import linkTypes from "../constants/data/social.json";
import jwt_decode from "jwt-decode";
import {
  CLOUDINARY_URL,
  CLODINARY_CLOUD_NAME,
  CLOUDINARY_PRESET,
} from "../constants/constants";

const uploadImage = async (image) => {
  let url;
  const data = new FormData();
  data.append("file", image);
  data.append("filename", image.name);
  data.append("upload_preset", CLOUDINARY_PRESET);
  data.append("cloud_name", CLODINARY_CLOUD_NAME);
  await fetch(CLOUDINARY_URL, {
    method: "POST",
    body: data,
  })
    .then((res) => res.json())
    .then((data) => {
      url = data.secure_url;
    })
    .catch((err) => {
      err = err;
    });
  return url;
};

const addKeyToStorage = (key, value) => {
  localStorage.setItem(key, value);
};

const deleteKeyFromStorage = (key) => {
  localStorage.removeItem(key);
};

const updateKey = (key, value) => {
  localStorage.setItem(key, value);
};

const getValueFromStorage = (key) => {
  const value = localStorage.getItem(key);
  return value;
};

const getUserInfo = () => {
  try {
    let token = getValueFromStorage("token");
    if (token) {
      let user = jwt_decode(token);
      return user;
    } else {
      return null;
    }
  } catch (err) {
    return err;
  }
};

const getSocialType = (e) => {
  const type = linkTypes?.find((linkType) => linkType?.name === e);
  return type;
};

const getBrowserName = (userAgent) => {
  const lowerUserAgent = userAgent.toLowerCase();

  if (lowerUserAgent.includes("chrome")) {
    return "Chrome";
  } else if (lowerUserAgent.includes("firefox")) {
    return "Firefox";
  } else if (lowerUserAgent.includes("safari")) {
    return "Safari";
  } else if (lowerUserAgent.includes("edge")) {
    return "Microsoft Edge";
  } else if (
    lowerUserAgent.includes("msie") ||
    lowerUserAgent.includes("trident/")
  ) {
    return "Internet Explorer";
  } else {
    // Default to the full user agent if the browser is not recognized
    return userAgent;
  }
};

const getUserMetaData = async () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const device = isMobile ? "Mobile" : "Desktop";

  // Get browser information
  const browser = getBrowserName(navigator.userAgent);

  // Get user's IP address
  const getUserIP = async () => {
    try {
      const ipResponse = await fetch("https://api64.ipify.org?format=json");
      const ipData = await ipResponse.json();

      // Use the obtained IP address to get location information
      const locationResponse = await fetch(
        `https://ipapi.co/${ipData.ip}/json`
      );
      const locationData = await locationResponse.json();

      let data = {
        device,
        browser,
        location: {
          country: locationData.country_name,
          city: locationData.city,
        },
      };
      return data;
    } catch (error) {
      console.error("Error getting IP address:", error);
    }
  };

  const resp = await getUserIP();
  return resp;
};

export {
  addKeyToStorage,
  deleteKeyFromStorage,
  updateKey,
  getValueFromStorage,
  getUserInfo,
  uploadImage,
  getSocialType,
  getUserMetaData,
};
