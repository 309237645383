import React from "react";
import * as customization_icons from "../../assets/icons/qrcustomization/qrcustomization";

const TemplateSection = ({ formData, setFormData }) => {
  const templates = [
    { icon: "default_icon", title: "Default" },
    { icon: "circular_icon", title: "Circular" },
    { icon: "gradient_icon", title: "Gradient" },
  ];
  return (
    <div>
      <div className="mb-10">
        <h1 className="text-secondary text-lg font-bold mb-1">
          SELECT A TEMPLATE
        </h1>
        <div className="flex items-center -ml-3">
          {templates.map((template, index) => {
            return (
              <div className="p-3" key={index}>
                <div
                  key={index}
                  className={`flex flex-col items-center justify-between rounded qrbox-shadow p-3 px-5 cursor-pointer  border-2 hover:border-2 hover:border-primary hover:text-primary ${
                    formData?.template === template?.title
                      ? "border-primary text-primary"
                      : "text-themeGray-200"
                  }`}
                  onClick={() =>
                    setFormData({ ...formData, template: template?.title })
                  }
                >
                  <img
                    src={customization_icons[template.icon]}
                    alt={template.title}
                    className="w-20 h-20"
                  />
                  <p className="text-sm font-MontserratBold mt-5">
                    {template.title}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TemplateSection;
