import React, { useState } from "react";
import { ThemeSwitch } from "../../ui/ui";
import ImageBox from "../ImageBox/ImageBox";
import SocialLink from "../SocialLink/SocialLink";
import { Slider } from "antd";

const LogoSection = ({ formData, setFormData }) => {
  const [errorMessage, setErrorMessage] = useState("");

  console.log("formData", formData);

  return (
    <div>
      <div className="mb-12">
        <h1 className="text-secondary text-lg font-bold mb-1">SELECT A LOGO</h1>
        <p className="text-xs text-themeGray-200 mb-6">
          This will appear at the center of your QR code
        </p>
        <ImageBox
          label={"Upload a logo"}
          className={"my-6"}
          id={"logo-upload"}
          image={formData?.logo?.image}
          setImage={(url, image) => {
            setFormData({
              ...formData,
              logo: {
                ...formData?.logo,
                image: url,
                imageFile: image,
                logo: "",
              },
            });
          }}
          errorMessage={errorMessage}
          setErrorMessage={(e) => setErrorMessage(e)}
        />
        <div className="mb-4">
          <ThemeSwitch
            label={"Set Logo Background To White"}
            checked={formData?.logo?.isWhite}
            onChange={(e) =>
              setFormData({
                ...formData,
                logo: { ...formData?.logo, isWhite: e },
              })
            }
          />
        </div>

        <h1 className="text-secondary text-lg font-bold">
          OR CHOOSE FROM GALLERY
        </h1>
        <div className="mb-4">
          <SocialLink
            isOne
            value={formData?.logo?.logo}
            onChange={(e) => {
              setFormData({
                ...formData,
                logo: { ...formData?.logo, logo: e, image: "", imageFile: "" },
              });
            }}
          />
        </div>
        <h1 className="text-secondary text-lg font-bold mb-6">Logo size</h1>
        <Slider
          defaultValue={30}
          value={formData?.logo?.size}
          onChange={(e) =>
            setFormData({ ...formData, logo: { ...formData?.logo, size: e } })
          }
          min={1}
          max={10}
        />
      </div>
    </div>
  );
};

export default LogoSection;
