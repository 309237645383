import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { DashboardContainer } from "../../components/components";
import { Col, Form, Row, Skeleton, Spin } from "antd";
import ThemeInput from "../../ui/ThemeInput";
import ThemeDatePicker from "../../ui/ThemeDatePicker";
import ThemeTextArea from "../../ui/ThemeTextArea";
import ThemeButton from "../../ui/ThemeButton";
import { useGetUserQuery, useUpdateUserMutation } from "../../services/auth";

const Profile = () => {
  const { data, isLoading, refetch } = useGetUserQuery();
  const [formData, setFormData] = useState({});
  const [updateUser, { isLoading: updating }] = useUpdateUserMutation();

  useEffect(() => {
    if (data?.status?.code === 200) {
      setFormData({ ...data?.data });
    }
  }, [data]);

  const onSubmit = async () => {
    const response = await updateUser({
      info: formData,
      userId: data?.data?._id,
    });
    console.log("response", response);
  };

  return (
    <DashboardContainer selected={"1"}>
      <div className="min-h-[calc(100vh-60px)] w-full bg-themeGray-light">
        <div className="w-full container py-6">
          <div>
            {isLoading ? (
              <Skeleton />
            ) : (
              <div>
                <div>
                  <h1 className="text-2xl font-MontserratBold font-bold text-secondary">
                    My Account
                  </h1>
                  <p className="text-primary font-semibold text-sm mt-1">
                    Wlecome back, {data?.data?.fullname} !
                  </p>
                </div>
                <div className="mt-6 type-box-shadow bg-white container py-12">
                  <h1 className="text-xl text-primary font-MontserratBold text-center">
                    Account Info
                  </h1>

                  <Form className="mt-6">
                    <Row
                      className="mb-6"
                      gutter={{ xs: 8, sm: 16, md: 20, lg: 40 }}
                    >
                      <Col span={24}>
                        <p className="text-xs text-themeGray-400">Full Name</p>
                        <ThemeInput
                          inputShadow={"shadow-none"}
                          inputClassName={"!border !border-primary"}
                          value={formData?.fullname}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              fullname: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row
                      className="mb-6"
                      gutter={{ xs: 8, sm: 16, md: 20, lg: 40 }}
                    >
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <p className="text-xs text-themeGray-400">
                          Contact Number
                        </p>
                        <ThemeInput
                          type={"number"}
                          inputShadow={"shadow-none"}
                          inputClassName={"!border !border-primary"}
                          value={formData?.contactNumber}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              contactNumber: e.target.value,
                            })
                          }
                        />
                      </Col>
                      <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={24}
                        xs={24}
                        className="md:mt-0 mt-6"
                      >
                        <p className="text-xs text-themeGray-400">Email</p>
                        <ThemeInput
                          type={"email"}
                          inputShadow={"shadow-none"}
                          inputClassName={"!border !border-primary"}
                          value={formData?.email}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    <Row
                      className="mb-6"
                      gutter={{ xs: 8, sm: 16, md: 20, lg: 40 }}
                    >
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <p className="text-xs text-themeGray-400">
                          Date of Birth
                        </p>
                        <ThemeDatePicker
                          inputShadow={"shadow-none"}
                          inputClassName={"!border !border-primary"}
                          value={
                            formData?.dateofBith && dayjs(formData?.dateofBith)
                          }
                          onChange={(e, date) =>
                            setFormData({ ...formData, dateofBith: date })
                          }
                        />
                      </Col>
                      <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={24}
                        xs={24}
                        className="md:mt-0 mt-6"
                      >
                        <p className="text-xs text-themeGray-400">Country</p>
                        <ThemeInput
                          inputShadow={"shadow-none"}
                          inputClassName={"!border !border-primary"}
                          value={formData?.country}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              country: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row
                      className="mb-6"
                      gutter={{ xs: 8, sm: 16, md: 20, lg: 40 }}
                    >
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <p className="text-xs text-themeGray-400">
                          Compony Name
                        </p>
                        <ThemeInput
                          inputShadow={"shadow-none"}
                          inputClassName={"!border !border-primary"}
                          value={formData?.companyName}
                        />
                      </Col>
                      <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={24}
                        xs={24}
                        className="md:mt-0 mt-6"
                      >
                        <p className="text-xs text-themeGray-400">
                          Compony Size
                        </p>
                        <ThemeInput
                          inputShadow={"shadow-none"}
                          inputClassName={"!border !border-primary"}
                          value={formData?.companySize}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              companySize: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row
                      className="mb-6"
                      gutter={{ xs: 8, sm: 16, md: 20, lg: 40 }}
                    >
                      <Col span={24}>
                        <p className="text-xs text-themeGray-400">Address</p>
                        <ThemeTextArea
                          inputShadow={"shadow-none"}
                          inputClassName={"!border !border-primary"}
                          minRows={2}
                          value={formData?.address}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              address: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </Form>

                  <ThemeButton
                    onClick={onSubmit}
                    content={
                      <div>
                        {updating ? (
                          <Spin className="loader-white" />
                        ) : (
                          <p className="text-sm !font-MontserratRegular !font-medium !rounded-sm text-white text-center">
                            Save Changes
                          </p>
                        )}
                      </div>
                    }
                    className={"w-40 !h-12 mx-auto mt-12 mb-6"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default Profile;
