import React from "react";
import { appstore, playstore } from "../../assets/images/images";

const AppStorePreview = ({ type }) => {
  const appStoreData = {
    title: "Loremipsum",
    description:
      "Turn your photos into professional artwork with detailed editing and retouching of your images",
    appStore:
      "https://apps.apple.com/us/app/nahw-ki-dunya-learn-arabic/id1575739040",
    playStore: "https://play.google.com/store/apps/details?id=com.sarfkidunya",
    otherLink: "www.loremipsum.com",
  };
  return (
    <div
      className={`${
        type?.sections[0]?.inputs[2]?.value
          ? type?.sections[0]?.inputs[2]?.value.length > 103
            ? "md:h-auto"
            : "md:h-full"
          : "md:h-full"
      } h-full bg-[#002B38] md:rounded-3xl rounded-none md:p-3 p-6 w-full`}
    >
      <div className="md:py-6 md:px-3 py-10 px-5 flex flex-col items-center md:justify-center bg-white rounded-3xl h-full">
        <div className="md:w-24 md:h-24 w-32 h-32 rounded-full bg-[#002B38] mb-4">
          {type?.sections[0]?.inputs[0]?.value && (
            <img
              src={type?.sections[0]?.inputs[0]?.value}
              alt="logo"
              className="md:w-24 md:h-24 w-32 h-32 rounded-full"
            />
          )}
        </div>
        <p className="md:text-sm text-2xl text-[#515151] font-semibold mb-3 font-MontserratBold">
          {type?.sections[0]?.inputs[1]?.value || appStoreData?.title}
        </p>
        <a
          className="md:text-[8px] text-sm text-primary md:mb-4 mb-6 font-medium font-MontserratRegular text-center"
          href={type?.sections[0]?.inputs[5]?.value || appStoreData?.otherLink}
          target="_blank"
        >
          {type?.sections[0]?.inputs[5]?.value || appStoreData?.otherLink}
        </a>
        <p className="text-center md:text-[10px] text-base text-[#868686] md:mb-8 mb-12 leading-normal">
          {type?.sections[0]?.inputs[2]?.value || appStoreData?.description}
        </p>
        <button className="md:w-32 md:h-10 w-8/12 h-16 rounded-xl md:mb-6 mb-8">
          <a
            href={type?.sections[0]?.inputs[3]?.value || appStoreData?.appStore}
            target="_blank"
          >
            <img src={appstore} alt="icon" className="w-full md:h-10 h-16" />
          </a>
        </button>
        <button className="md:w-32 md:h-10 w-8/12 h-16 rounded-xl md:mb-6 mb-8">
          <a
            href={
              type?.sections[0]?.inputs[4]?.value || appStoreData?.playStore
            }
            target="_blank"
          >
            <img src={playstore} alt="icon" className="w-full md:h-10 h-16" />
          </a>
        </button>
      </div>
    </div>
  );
};

export default AppStorePreview;
