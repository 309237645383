"use client";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Spin, message } from "antd";
import { ThemeInput, ThemeButton } from "../../ui/ui";
import { loginHero } from "../../assets/images/images";
import {
  facebook_circle_blue_icon,
  google_icon,
  right_icon,
} from "../../assets/icons/icons";
import { useRegisterUserMutation } from "../../services/auth";

const Signup = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [registerUser, { isLoading }] = useRegisterUserMutation();

  const checkPasswordStrength = () => {
    const password = formData?.password || "";

    // Regular expressions to check for lowercase, uppercase, and numbers
    setHasLowercase(/[a-z]/.test(password));
    setHasUppercase(/[A-Z]/.test(password));
    setHasNumber(/\d/.test(password));
  };

  useEffect(() => {
    checkPasswordStrength();
  }, [formData]);

  const handleSubmit = async () => {
    try {
      await form.validateFields(); // Trigger form validation

      const response = await registerUser({ ...formData });

      console.log("response", response);

      // message.success("user registered successfully");
    } catch (error) {
      // If validation fails, the Ant Design form will automatically display errors
      console.log("Form validation failed:", error);
    }
  };

  return (
    <div className="w-full h-screen flex">
      <div className="login-gradiant h-full w-6/12 md:flex hidden flex-col justify-center items-center">
        <div className="container flex flex-col items-center">
          <img src={loginHero} alt="image" className="mb-5" />
          <p className="text-white font-MontserratBold text-center">
            You are just a few steps away <br /> from your QR Code
          </p>
        </div>
      </div>
      <div className="md:w-6/12 w-full h-full flex flex-col items-center justify-center bg-themeGray-medium p-2">
        <div className="container">
          <div className="mb-12">
            <h1 className="text-3xl text-[#002B38] font-MontserratBold text-center">
              Create an account
            </h1>
            <p className="text-center text-[#817E7E] text-sm font-semibold">
              Already have an account
              <a
                className="text-primary underline ml-1 cursor-pointer"
                onClick={() => navigate("/login")}
              >
                Log in
              </a>
            </p>
          </div>
          <div className="w-full">
            <Form
              form={form}
              name="normal_login"
              className="login-form w-full mt-5"
            >
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: `Please input your Name!`,
                  },
                ]}
              >
                <ThemeInput
                  label={"Full Name"}
                  placeholder={"Enter your name"}
                  labelPrimary
                  value={formData?.fullname}
                  onChange={(e) =>
                    setFormData({ ...formData, fullname: e.target.value })
                  }
                  inputHeight={"h-12"}
                />
              </Form.Item>

              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: `Please input your Email!`,
                  },
                ]}
              >
                <ThemeInput
                  label={"Email"}
                  placeholder={"Enter the Email"}
                  labelPrimary
                  value={formData?.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  inputHeight={"h-12"}
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                  {
                    min: 8,
                    message: "Password must be at least 8 characters long",
                  },
                  {
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                    message:
                      "Password must contain at least one lowercase letter, one uppercase letter, and one number",
                  },
                ]}
                className="mb-0"
              >
                <ThemeInput
                  label={"Password"}
                  placeholder={"Enter the Password"}
                  labelPrimary
                  password
                  value={formData?.password}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                  inputHeight={"h-12"}
                />
              </Form.Item>
              <div className="mt-2">
                <div
                  className={`flex items-center mb-2 ${
                    hasLowercase
                      ? "text-green-600 fill-green-600"
                      : "text-[#6C6C6C] fill-[#D0D0D0]"
                  }`}
                >
                  <span className="mt-[3px]">
                    <svg
                      width="12"
                      height="14"
                      viewBox="0 0 12 14"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.7568 6.83752C11.7568 8.07767 11.412 9.28997 10.7661 10.3211C10.1202 11.3523 9.20215 12.156 8.12802 12.6306C7.05389 13.1052 5.87194 13.2294 4.73164 12.9875C3.59134 12.7455 2.5439 12.1484 1.72178 11.2714C0.899665 10.3945 0.339792 9.27729 0.112963 8.06097C-0.113865 6.84465 0.00253885 5.5839 0.447456 4.43815C0.892373 3.2924 1.64582 2.31311 2.61252 1.62412C3.57921 0.935131 4.71574 0.567383 5.87838 0.567383C7.4374 0.567383 8.93257 1.22798 10.035 2.40386C11.1374 3.57973 11.7567 5.17456 11.7568 6.83752ZM5.19842 10.1576L9.55936 5.50593C9.63046 5.43006 9.67041 5.32718 9.67041 5.21991C9.67041 5.11264 9.63046 5.00976 9.55936 4.93389L9.02308 4.36133C8.98786 4.32376 8.94604 4.29395 8.90003 4.27362C8.85401 4.25328 8.80468 4.24281 8.75487 4.24281C8.70506 4.24281 8.65574 4.25328 8.60972 4.27362C8.5637 4.29395 8.52189 4.32376 8.48667 4.36133L4.93034 8.15527L3.26972 6.38355C3.2345 6.34598 3.19269 6.31617 3.14667 6.29584C3.10065 6.27551 3.05133 6.26504 3.00152 6.26504C2.95171 6.26504 2.90238 6.27551 2.85636 6.29584C2.81034 6.31617 2.76853 6.34598 2.73331 6.38355L2.19703 6.95559C2.12593 7.03146 2.08598 7.13434 2.08598 7.24161C2.08598 7.34888 2.12593 7.45176 2.19703 7.52763L4.66213 10.1576C4.69735 10.1952 4.73917 10.225 4.78519 10.2453C4.8312 10.2656 4.88053 10.2761 4.93034 10.2761C4.98015 10.2761 5.02947 10.2656 5.07549 10.2453C5.12151 10.225 5.1632 10.1952 5.19842 10.1576Z" />
                    </svg>
                  </span>
                  <p className="ml-2 text-sm">One lowercase character</p>
                </div>
                <div
                  className={`flex items-center mb-2 ${
                    hasUppercase
                      ? "text-green-600 fill-green-600"
                      : "text-[#6C6C6C] fill-[#D0D0D0]"
                  }`}
                >
                  <span className="mt-[3px]">
                    <svg
                      width="12"
                      height="14"
                      viewBox="0 0 12 14"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.7568 6.83752C11.7568 8.07767 11.412 9.28997 10.7661 10.3211C10.1202 11.3523 9.20215 12.156 8.12802 12.6306C7.05389 13.1052 5.87194 13.2294 4.73164 12.9875C3.59134 12.7455 2.5439 12.1484 1.72178 11.2714C0.899665 10.3945 0.339792 9.27729 0.112963 8.06097C-0.113865 6.84465 0.00253885 5.5839 0.447456 4.43815C0.892373 3.2924 1.64582 2.31311 2.61252 1.62412C3.57921 0.935131 4.71574 0.567383 5.87838 0.567383C7.4374 0.567383 8.93257 1.22798 10.035 2.40386C11.1374 3.57973 11.7567 5.17456 11.7568 6.83752ZM5.19842 10.1576L9.55936 5.50593C9.63046 5.43006 9.67041 5.32718 9.67041 5.21991C9.67041 5.11264 9.63046 5.00976 9.55936 4.93389L9.02308 4.36133C8.98786 4.32376 8.94604 4.29395 8.90003 4.27362C8.85401 4.25328 8.80468 4.24281 8.75487 4.24281C8.70506 4.24281 8.65574 4.25328 8.60972 4.27362C8.5637 4.29395 8.52189 4.32376 8.48667 4.36133L4.93034 8.15527L3.26972 6.38355C3.2345 6.34598 3.19269 6.31617 3.14667 6.29584C3.10065 6.27551 3.05133 6.26504 3.00152 6.26504C2.95171 6.26504 2.90238 6.27551 2.85636 6.29584C2.81034 6.31617 2.76853 6.34598 2.73331 6.38355L2.19703 6.95559C2.12593 7.03146 2.08598 7.13434 2.08598 7.24161C2.08598 7.34888 2.12593 7.45176 2.19703 7.52763L4.66213 10.1576C4.69735 10.1952 4.73917 10.225 4.78519 10.2453C4.8312 10.2656 4.88053 10.2761 4.93034 10.2761C4.98015 10.2761 5.02947 10.2656 5.07549 10.2453C5.12151 10.225 5.1632 10.1952 5.19842 10.1576Z" />
                    </svg>
                  </span>
                  <p className="ml-2 text-sm">One uppercase character</p>
                </div>
                <div
                  className={`flex items-center mb-2 ${
                    hasNumber
                      ? "text-green-600 fill-green-600"
                      : "text-[#6C6C6C] fill-[#D0D0D0]"
                  }`}
                >
                  <span className="mt-[3px]">
                    <svg
                      width="12"
                      height="14"
                      viewBox="0 0 12 14"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.7568 6.83752C11.7568 8.07767 11.412 9.28997 10.7661 10.3211C10.1202 11.3523 9.20215 12.156 8.12802 12.6306C7.05389 13.1052 5.87194 13.2294 4.73164 12.9875C3.59134 12.7455 2.5439 12.1484 1.72178 11.2714C0.899665 10.3945 0.339792 9.27729 0.112963 8.06097C-0.113865 6.84465 0.00253885 5.5839 0.447456 4.43815C0.892373 3.2924 1.64582 2.31311 2.61252 1.62412C3.57921 0.935131 4.71574 0.567383 5.87838 0.567383C7.4374 0.567383 8.93257 1.22798 10.035 2.40386C11.1374 3.57973 11.7567 5.17456 11.7568 6.83752ZM5.19842 10.1576L9.55936 5.50593C9.63046 5.43006 9.67041 5.32718 9.67041 5.21991C9.67041 5.11264 9.63046 5.00976 9.55936 4.93389L9.02308 4.36133C8.98786 4.32376 8.94604 4.29395 8.90003 4.27362C8.85401 4.25328 8.80468 4.24281 8.75487 4.24281C8.70506 4.24281 8.65574 4.25328 8.60972 4.27362C8.5637 4.29395 8.52189 4.32376 8.48667 4.36133L4.93034 8.15527L3.26972 6.38355C3.2345 6.34598 3.19269 6.31617 3.14667 6.29584C3.10065 6.27551 3.05133 6.26504 3.00152 6.26504C2.95171 6.26504 2.90238 6.27551 2.85636 6.29584C2.81034 6.31617 2.76853 6.34598 2.73331 6.38355L2.19703 6.95559C2.12593 7.03146 2.08598 7.13434 2.08598 7.24161C2.08598 7.34888 2.12593 7.45176 2.19703 7.52763L4.66213 10.1576C4.69735 10.1952 4.73917 10.225 4.78519 10.2453C4.8312 10.2656 4.88053 10.2761 4.93034 10.2761C4.98015 10.2761 5.02947 10.2656 5.07549 10.2453C5.12151 10.225 5.1632 10.1952 5.19842 10.1576Z" />
                    </svg>
                  </span>
                  <p className="ml-2 text-sm">One number</p>
                </div>
              </div>

              <Form.Item className="my-0">
                <Divider orientation="center" className="font-MontserratBold">
                  OR
                </Divider>
              </Form.Item>

              <Form.Item className="mb-0">
                <div className="flex md:flex-row flex-col items-center justify-center">
                  <ThemeButton
                    type="primary"
                    className={
                      "!h-10 w-56 flex border-primary text-[#151515] mx-4 mb-4"
                    }
                    bgColor={"bg-transparent"}
                    content={
                      <>
                        <div className="flex items-center">
                          <img
                            src={google_icon}
                            alt=""
                            width={22}
                            height={22}
                          />
                          <p className="text-sm ml-2">Sign up With Google</p>
                        </div>
                      </>
                    }
                  />
                  <ThemeButton
                    type="primary"
                    className={
                      "!h-10 w-56 flex border-primary text-[#151515] mx-4 mb-4"
                    }
                    bgColor={"bg-transparent"}
                    content={
                      <>
                        <div className="flex items-center">
                          <img
                            src={facebook_circle_blue_icon}
                            alt=""
                            width={22}
                            height={22}
                          />
                          <p className="text-sm ml-2">Sign up With Facebook</p>
                        </div>
                      </>
                    }
                  />
                </div>
              </Form.Item>

              <Form.Item className="flex justify-center">
                <ThemeButton
                  type="primary"
                  htmlType="submit"
                  onClick={handleSubmit}
                  className={"!h-10 w-56 flex mt-6 mb-2"}
                  content={
                    <>
                      {isLoading ? (
                        <Spin className="mt-1 loader-white" />
                      ) : (
                        <div className="flex items-center">
                          <p className="text-sm uppercase">
                            create free account
                          </p>
                          <img
                            src={right_icon}
                            alt=""
                            width={6}
                            height={6}
                            className="ml-3"
                          />
                        </div>
                      )}
                    </>
                  }
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
