import React from "react";
import { preview_hero } from "../../assets/images/images";

const StaticPreview = () => {
  return (
    <div className="h-full rounded-3xl bg-[#ECECEC] flex flex-col justify-center p-4">
      <img src={preview_hero} alt="logo" className="h-40 mb-6" />
      <p className="text-xs font-MontserratBold text-center">
        Hover over a dynamic type to see preview
      </p>
    </div>
  );
};

export default StaticPreview;
