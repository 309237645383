import React from "react";
import dayjs from "dayjs";
import features from "../../constants/data/features.json";
import { ThemeButton } from "../../ui/ui";
import * as images from "../../assets/images/images";
import * as featureIcons from "../../assets/icons/features/features";
const EventPreview = ({ type }) => {
  const eventData = {
    image: "event_banner",
    organizer: "Sterling & Co.",
    title: "4th Annual Company Meetup",
    summary: "Meet fellow Sterlings and the 'people behind the jobs'",
    eventDate: dayjs(),
    location: "Avenue 3rd New York, NY 10017 USA",
    about:
      "Come join Sterling & Co. at our annual company meetup! Get to know fellow Sterlings and enjoy team building activities.",
    contactName: "John Doe",
    contactNumber: "000 000 000",
    contactEmail: "your@email.com",
    website: "www.your-website.com",
  };
  const primaryColor = type?.sections[2]?.inputs[0]?.value[0];
  const buttonColor = type?.sections[2]?.inputs[0]?.value[1];
  const headingColor = type?.sections[2]?.inputs[0]?.value[2];

  const getFeatureIcon = (value) => {
    const feature = features?.find((feature) => feature?.name === value);
    return feature?.icon;
  };

  return (
    <div className="md:py-1 py-0 h-full">
      <div className="md:rounded-t-3xl rounded-t-none relative">
        <img
          src={type?.sections[0]?.inputs[0]?.value || images[eventData?.image]}
          alt="banner"
          className="md:rounded-t-3xl rounded-t-none md:h-44 h-60 w-full object-cover"
        />
        <p
          className="absolute top-4 left-6 md:text-sm text-2xl font-semibold"
          style={{ color: headingColor }}
        >
          {type?.sections[1]?.inputs[0]?.value || eventData?.organizer}
        </p>
      </div>
      <div
        className="md:px-4 px-6 md:py-6 py-10 text-white flex flex-col"
        style={{ backgroundColor: primaryColor }}
      >
        <p className="md:text-sm text-2xl font-MontserratBold mb-3">
          {type?.sections[1]?.inputs[1]?.value || eventData?.title}
        </p>
        <p className="md:text-xs text-base md:mb-6 mb-8">
          {type?.sections[1]?.inputs[2]?.value || eventData?.summary}
        </p>
        {type?.sections[1]?.inputs[3]?.value?.length > 0 && (
          <ThemeButton
            className=" mb-6 md:!text-xs text-base md:!h-8 !h-11 font-MontserratBold !border-none !outline-none max-w-min md:px-5 px-8"
            style={{ backgroundColor: buttonColor, color: primaryColor }}
            content={<p>{type?.sections[1]?.inputs[3]?.value[0]}</p>}
          />
        )}
      </div>
      <div className="bg-white md:px-4 px-6 md:py-6 py-8">
        <div className="mb-6">
          <p className="md:text-xs text-lg font-MontserratBold mb-1">When</p>
          <p className="md:text-[11px] text-base text-[#7D7D7D]">
            {dayjs(
              type?.sections[1]?.inputs[4]?.value || eventData?.eventDate
            ).format("MMM DD, YYYY")}
          </p>
          {/* <p className="md:text-[10px] text-base text-primary">All day</p> */}
        </div>
        <div className="mb-6">
          <p className="md:text-xs text-lg font-MontserratBold mb-1">Where</p>
          <p className="md:text-[11px] text-base text-[#7D7D7D] w-6/12">
            {type?.sections[1]?.inputs[6]?.value || eventData?.location}
          </p>
          {/* <p className="md:text-[11px] text-base text-primary">Show on map</p> */}
        </div>
        <div className="mb-6">
          <p className="md:text-xs text-lg font-MontserratBold mb-3">
            Features
          </p>
          <div>
            {type?.sections[1]?.inputs[7]?.value?.length > 0 ? (
              <div className="flex items-center flex-wrap -ml-3">
                {type?.sections[1]?.inputs[7]?.value?.map((value, index) => {
                  return (
                    <span className="md:p-3 p-4" key={index}>
                      <img
                        key={index}
                        src={featureIcons[getFeatureIcon(value)]}
                        alt="icon"
                        className="md:w-4 md:h-4 w-6 h-6"
                      />
                    </span>
                  );
                })}
              </div>
            ) : (
              <p className="text-xs md:text-[11px] text-base text-[#7D7D7D]">
                No Feature added
              </p>
            )}
          </div>
        </div>
        <div className="mb-12">
          <p className="md:text-xs text-lg font-MontserratBold mb-2">About</p>
          <p className="md:text-[11px] text-base text-[#7D7D7D]">
            {type?.sections[1]?.inputs[8]?.value || eventData?.about}
          </p>
        </div>
        <div>
          <p className="md:text-xs text-lg font-MontserratBold mb-4">Contact</p>
          <div className="mb-4">
            <p className="md:text-[10px] text-base font-semibold text-primary">
              Name
            </p>
            <p className="md:text-[11px] text-base text-[#7D7D7D]">
              {type?.sections[1]?.inputs[9]?.value || eventData?.contactName}
            </p>
          </div>
          <div className="mb-4">
            <p className="md:text-[10px] text-base font-semibold text-primary">
              Phone
            </p>
            <p className="md:text-[11px] text-base text-[#7D7D7D]">
              {type?.sections[1]?.inputs[10]?.value || eventData?.contactNumber}
            </p>
          </div>
          <div className="mb-4">
            <p className="md:text-[10px] text-base font-semibold text-primary">
              Email
            </p>
            <p className="md:text-[11px] text-base text-[#7D7D7D]">
              {type?.sections[1]?.inputs[11]?.value || eventData?.contactEmail}
            </p>
          </div>
          <div className="mb-4">
            <p className="md:text-[10px] text-base font-semibold text-primary">
              Website
            </p>
            <p className="md:text-[11px] text-base text-[#7D7D7D]">
              {type?.sections[1]?.inputs[12]?.value || eventData?.website}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventPreview;
