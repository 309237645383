import React from "react";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { calendar_icon } from "../assets/icons/icons";

const ThemeDatePicker = ({
  label,
  labelPrimary,
  textMd,
  required,
  value,
  onChange,
  className,
  inputShadow,
  inputClassName,
}) => {
  return (
    <div className={className}>
      <p
        className={`${labelPrimary && "text-primary"} ${
          textMd ? "font-medium" : "font-semibold"
        } mb-1 flex items-center text-sm`}
      >
        {required && <p className="mr-1 text-themeRed">*</p>} {label}
      </p>
      <DatePicker
        value={value ? dayjs(value) : ""}
        onChange={onChange}
        className={`w-full h-14 font-medium text-secondary ${
          inputShadow ? inputShadow : "inputBox-shadow"
        } ${inputClassName}`}
        suffixIcon={<img src={calendar_icon} alt="icon" />}
      />
    </div>
  );
};

export default ThemeDatePicker;
