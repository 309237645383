import React from "react";
import features from "../../constants/data/features.json";
import * as icons from "../../assets/icons/features/features";
const FeaturesBox = ({ value, onChange }) => {
  const onHandleFeature = (index, feature) => {
    console.log("value", value);
    console.log("feature", feature);
    console.log("index", index);
    let values = [...value];
    console.log("values", values);
    if (index !== -1) {
      values.splice(index, 1);
    } else {
      values.push(feature);
    }
    console.log("after update values", values);
    onChange(values);
  };
  return (
    <div className="flex items-center flex-wrap">
      {features?.map((feature, index) => {
        return (
          <span className="p-6" key={index}>
            <img
              key={index}
              src={
                value.indexOf(feature?.name) !== -1
                  ? icons[feature?.selected]
                  : icons[feature?.icon]
              }
              alt="icon"
              className="w-6 h-6 cursor-pointer"
              onClick={() =>
                onHandleFeature(value.indexOf(feature?.name), feature?.name)
              }
            />
          </span>
        );
      })}
    </div>
  );
};

export default FeaturesBox;
