import { Input } from "antd";

const ThemeInput = ({
  label,
  placeholder,
  value,
  labelPrimary,
  textMd,
  className,
  password,
  onChange,
  name,
  disabled,
  onKeyDown,
  type,
  required,
  inputHeight,
  inputShadow,
  suffix,
  inputClassName,
}) => {
  return (
    <div className={className}>
      <p
        className={`${labelPrimary && "text-primary"} ${
          textMd ? "font-medium" : "font-semibold"
        } mb-1 flex items-center text-sm`}
      >
        {required && <p className="mr-1 text-themeRed">*</p>} {label}
      </p>
      {password ? (
        <Input.Password
          onKeyDown={onKeyDown}
          name={name}
          placeholder={placeholder}
          value={value}
          className={`${inputHeight ? inputHeight : "h-14"} ${
            inputShadow ? inputShadow : "inputBox-shadow"
          } font-medium text-secondary inputBox-shadow ${inputClassName}`}
          onChange={onChange}
          disabled={disabled}
        />
      ) : (
        <Input
          type={type || "text"}
          onKeyDown={onKeyDown}
          name={name}
          placeholder={placeholder}
          value={value}
          className={`${inputHeight ? inputHeight : "h-14"} ${
            inputShadow ? inputShadow : "inputBox-shadow"
          } font-medium text-secondary ${inputClassName}`}
          onChange={onChange}
          disabled={disabled}
          suffix={suffix}
        />
      )}
    </div>
  );
};

export default ThemeInput;
