import React from "react";
import { ColorPicker } from "antd";

const ThemeColorPicker = ({
  label,
  labelPrimary,
  textMd,
  required,
  value,
  onChange,
}) => {
  return (
    <div>
      <p
        className={`${labelPrimary && "text-primary"} ${
          textMd ? "font-medium" : "font-semibold"
        } mb-1 flex items-center text-sm`}
      >
        {required && <p className="mr-1 text-themeRed">*</p>} {label}
      </p>
      <ColorPicker
        showText
        value={value}
        onChange={onChange}
        className="theme-color-picker !w-min"
        size="large"
        format={"hex"}
      />
    </div>
  );
};

export default ThemeColorPicker;
