"use client";
import React, { useState } from "react";
import dayjs from "dayjs";
import { receipt_footer_hero } from "../../assets/images/images";
import { close_icon, time_icon } from "../../assets/icons/icons";
import * as images from "../../assets/images/images";

const CouponPreview = ({ type }) => {
  const [isGetCoupon, setIsGetCoupon] = useState(false);
  const primaryColor = type?.sections[3]?.inputs[0]?.value[0];
  const buttonColor = type?.sections[3]?.inputs[0]?.value[1];
  const headingColor = type?.sections[3]?.inputs[0]?.value[2];
  const couponData = {
    company: "Lorem Botique",
    image: "coupon_banner",
    couponTitle: "Lorem Store",
    description: "50% OFF on your next purchase",
    badge: "50% Off",
    terms: "This coupon is only valid for online purchases",
    website: "www.aim.com",
    effective: dayjs(),
  };
  return (
    <div
      className="md:rounded-3xl py-6 px-3 md:h-full h-screen"
      style={{ backgroundColor: primaryColor }}
    >
      <div
        className={`relative flipCard h-full ${
          isGetCoupon && "flipCard-active"
        }`}
      >
        <div className="front h-full">
          <p className="w-full text-center text-white md:text-xs text-xl font-semibold font-MontserratBold mb-4">
            {type?.sections[1]?.inputs[0]?.value || couponData?.company}
          </p>
          <img
            src={
              type?.sections[0]?.inputs[0]?.value || images[couponData?.image]
            }
            alt="banner"
            className="w-full md:h-[140px] h-56 object-fill"
          />
          <div className="bg-white p-2 flex flex-col items-center relative">
            <button
              className="md:w-24 md:h-8 w-28 h-10 rounded font-semibold md:text-[11px] text-base text-white overflow-hidden absolute -top-1 -mt-4 right-3"
              style={{ backgroundColor: buttonColor }}
            >
              {type?.sections[1]?.inputs[5]?.value || couponData?.badge}
            </button>
            <div className="flex flex-col md:w-6/12 w-8/12 my-8 md:mt-8 mt-12">
              <span className="md:text-sm text-lg font-semibold mb-2 text-center font-MontserratBold">
                {type?.sections[1]?.inputs[3]?.value || couponData?.couponTitle}
              </span>
              <p className="md:text-xs text-base text-center">
                {type?.sections[1]?.inputs[4]?.value || couponData?.description}
              </p>
            </div>
            <button
              className="md:w-32 md:h-10 w-40 h-11 rounded font-semibold md:text-[11px] text-base text-white md:mb-0 mb-2"
              style={{ backgroundColor: buttonColor }}
              onClick={() => setIsGetCoupon(true)}
            >
              Get Coupon
            </button>
          </div>
          <img src={receipt_footer_hero} alt="footer" className="w-full" />
        </div>
        <div className="back h-full absolute top-0 bg-white w-full md:p-4 p-6">
          <div className="flex justify-end md:mb-8 mb-12">
            <img
              src={close_icon}
              alt="close"
              className="cursor-pointer md:w-4 md:h-4 w-6 h-6"
              onClick={() => setIsGetCoupon(false)}
            />
          </div>
          <div className="flex flex-col justify-center ">
            <p className="md:text-xs text-base text-[#031D36] font-semibold mb-3 text-center">
              Coupon Code
            </p>
            <h1
              className="md:text-3xl text-6xl font-MontserratBold mb-5 text-center"
              style={{ color: headingColor }}
            >
              SALE50
            </h1>
            <div className="md:p-2 px-4 flex items-center bg-[#EEECEC] rounded-md md:py-1 py-2 exp-btnShadow mb-12 mx-auto">
              <img
                src={time_icon}
                alt="icon"
                className="md:w-3 md:h-3 w-5 h-5"
              />
              <p className="md:text-[11px] text-base ml-2">
                {`Expires 
                ${dayjs(
                  type?.sections[2]?.inputs[2]?.value || couponData?.effective
                ).format("MMM DD, YYYY")}`}
              </p>
            </div>

            <div className="mb-12">
              <p
                className="md:text-xs text-lg font-MontserratBold font-semibold mb-1"
                style={{ color: headingColor }}
              >
                Terms of Use
              </p>
              <p className="md:text-[10px] text-base">
                {type?.sections[2]?.inputs[5]?.value || couponData?.terms}
              </p>
            </div>

            <div className="flex flex-col justify-center items-center">
              <p className="md:text-xs text-sm font-semibold md:mb-2 mb-4 cursor-pointer">
                <a
                  href={
                    type?.sections[1]?.inputs[2]?.value || couponData?.website
                  }
                  target="_blank"
                >
                  {type?.sections[1]?.inputs[2]?.value || couponData?.website}
                </a>
              </p>
              <button
                className="md:w-28 w-36 md:py-2 py-3 font-semibold md:text-[11px] text-base font-MontserratBold text-white rounded-md"
                style={{ backgroundColor: buttonColor }}
                onClick={() => setIsGetCoupon(false)}
              >
                I Got it
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponPreview;
