import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { DashboardContainer, FoldarModal } from "../../components/components";
import { add_icon } from "../../assets/icons/icons";
import ThemeTable from "../../ui/ThemeTable";
import ThemeButton from "../../ui/ThemeButton";
import { DeleteOutlined, EyeOutlined, EditOutlined } from "@ant-design/icons";
import {
  useAddFolderMutation,
  useGetFoldersQuery,
  useGetUserFoldersQuery,
  usePutFolderMutation,
} from "../../services/folders";
import { getUserInfo } from "../../helpers/helpers";
import { message } from "antd";

const Folder = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState("create");
  const [editId, setEditId] = useState("");
  const [formData, setFormData] = useState({});
  const { data, isLoading, refetch } = useGetFoldersQuery();
  const [addFolder, { isLoading: submitting }] = useAddFolderMutation();
  const [updateFolder, { isLoading: updating }] = usePutFolderMutation();

  const onClose = () => {
    setIsOpen(false);
    setFormData({});
    setMode("create");
    setEditId("");
  };

  const onEditFolder = (data) => {
    setMode("edit");
    setFormData({ name: data?.name });
    setEditId(data?._id);
    setIsOpen(true);
  };

  const onSubmit = async () => {
    let response;
    if (mode === "create") {
      response = await addFolder({
        name: formData?.name,
        userId: getUserInfo()?.userId,
      });
    } else {
      response = await updateFolder({ id: editId, body: formData });
    }
    console.log("response: ", response);

    if (
      response?.data?.status?.code === 201 ||
      response?.data?.status?.code === 200
    ) {
      message.success(
        `Folder ${mode === "create" ? "Created" : "Updated"} Successfully`
      );
      refetch();
      onClose();
    } else {
      message.error(response?.data?.error || "Something went wrong!");
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (data, _, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p className="text-center font-medium">{text}</p>,
    },
    {
      title: "Mo of QR Codes",
      dataIndex: "numberOfQRCodes",
      key: "numberOfQRCodes",
      render: (text) => <p className="text-center font-medium">{text || 0}</p>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => (
        <p className="text-center font-medium">
          {createdAt ? dayjs(createdAt).format("MMM DD, YYYY") : "---"}
        </p>
      ),
    },
    {
      title: "In Action",
      dataIndex: "_id",
      key: "_id",
      width: "10%",
      render: (text, data) => (
        <span className="flex justify-center items-center">
          <span
            className="mx-2 cursor-pointer text-base text-primary"
            onClick={() => onEditFolder(data)}
          >
            <EditOutlined />
          </span>
          {/* <span
            className="mx-2 cursor-pointer text-base text-red-600"
            onClick={() => message.info("This functionality add soon!")}
          >
            <DeleteOutlined />
          </span> */}
          <span
            className="mx-2 cursor-pointer text-base ml-4"
            onClick={() => navigate(`/folder/${data?._id}`)}
          >
            <EyeOutlined />
          </span>
        </span>
      ),
    },
  ];

  return (
    <DashboardContainer selected={"2"}>
      <div className="min-h-[calc(100vh-60px)] w-full bg-themeGray-light">
        <div className="w-full container py-6 ">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-MontserratBold font-bold text-secondary">
              Folders
            </h1>
            <ThemeButton
              content={
                <div className="flex items-center">
                  <img
                    src={add_icon}
                    alt="icon"
                    className="w-[12px] h-[12px]"
                  />
                  <p className="text-sm font-medium text-white ml-2 font-MontserratRegular">
                    Add Folder
                  </p>
                </div>
              }
              onClick={() => setIsOpen(true)}
              className="w-32 rounded !h-10"
            />
          </div>

          <div className="mt-6">
            <ThemeTable
              columns={columns}
              data={data?.data || []}
              loader={isLoading}
            />
          </div>
        </div>

        <FoldarModal
          open={isOpen}
          onClose={onClose}
          mode={mode}
          onSubmit={onSubmit}
          formData={formData}
          setFormData={(e) => setFormData(e)}
          isLoading={mode === "create" ? submitting : updating}
        />
      </div>
    </DashboardContainer>
  );
};

export default Folder;
