import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getValueFromStorage, getUserInfo } from "../helpers/helpers";

export const FoldersApi = createApi({
  reducerPath: "folders",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getValueFromStorage("token")}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    addFolder: builder.mutation({
      query: (body) => ({
        url: `/api/folders`,
        method: "POST",
        body: body,
      }),
    }),
    putFolder: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/api/folders/${id}`,
          method: "PUT",
          body: body,
        };
      },
    }),
    getFolderByID: builder.query({
      query: (id) => ({
        url: `/api/folders/${id}`,
        method: "GET",
      }),
    }),
    getFolders: builder.query({
      query: () => ({
        url: `/api/folders`,
        method: "GET",
      }),
    }),
    getUserFolders: builder.query({
      query: () => ({
        url: `/api/folders/user/${getUserInfo()?.userId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useAddFolderMutation,
  usePutFolderMutation,
  useGetFolderByIDQuery,
  useLazyGetFolderByIDQuery,
  useGetFoldersQuery,
  useLazyGetFoldersQuery,
  useGetUserFoldersQuery,
  useLazyGetUserFoldersQuery,
} = FoldersApi;
