import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  andriod_icon,
  bio_icon,
  coupon_icon,
  card_icon,
  email_icon,
  event_icon,
  lead_generation_icon,
  link_icon,
  location_icon,
  menu_icon,
  multiUrl_icon,
  pdf_icon,
  phone_icon,
  product_icon,
  rating_icon,
  review_icon,
  share_icon,
  whatsapp_icon,
  wifi_icon,
} from "../../assets/icons/types/typesIcon";
import { message } from "antd";
import { getUserInfo } from "../../helpers/helpers";
import { LoginWarnModal } from "../components";

const getIcon = (e) => {
  if (e === "andriod_icon") return andriod_icon;
  else if (e === "menu_icon") return menu_icon;
  else if (e === "coupon_icon") return coupon_icon;
  else if (e === "card_icon") return card_icon;
  else if (e === "bio_icon") return bio_icon;
  else if (e === "lead_generation_icon") return lead_generation_icon;
  else if (e === "rating_icon") return rating_icon;
  else if (e === "review_icon") return review_icon;
  else if (e === "email_icon") return email_icon;
  else if (e === "location_icon") return location_icon;
  else if (e === "share_icon") return share_icon;
  else if (e === "wifi_icon") return wifi_icon;
  else if (e === "phone_icon") return phone_icon;
  else if (e === "link_icon") return link_icon;
  else if (e === "pdf_icon") return pdf_icon;
  else if (e === "multiUrl_icon") return multiUrl_icon;
  else if (e === "whatsapp_icon") return whatsapp_icon;
  else if (e === "event_icon") return event_icon;
  else if (e === "product_icon") return product_icon;
};

const QrTypes = ({ isStatic, qrTypes, user }) => {
  const navigate = useNavigate();
  // const user = getUserInfo();
  const [open, setOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div className="flex flex-wrap md:justify-start justify-center">
      {qrTypes?.map((type, index) => {
        if (isStatic === type?.isStatic) {
          return (
            <div className="sm:p-2 md:px-2 sm:px-3 p-3 px-4" key={index}>
              <div
                className="rounded flex flex-col items-center justify-center md:w-24 w-[100px] h-[85px] type-box-shadow cursor-pointer hover:bg-secondary hover:text-white"
                onClick={() =>
                  user
                    ? user?.isAdmin
                      ? navigate(`/type/${type?._id}/create`)
                      : message.info("You aren't eligible to create QR.")
                    : setOpen(true)
                }
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <img
                  // src={hoveredIndex === index ? type?.iconActive : type?.icon}
                  src={getIcon(type?.icon)}
                  className=" hover:fill-white stroke-white"
                />
                <span className="text-xs mt-3 text-center ">{type?.label}</span>
              </div>
            </div>
          );
        }
      })}
      <LoginWarnModal open={open} onClose={() => setOpen(false)} />
    </div>
  );
};

export default QrTypes;
