import React, { useState } from "react";
import { ThemeDropDown, ThemeTimePicker } from "../../ui/ui";
import dayjs from "dayjs";

const TimeSlots = () => {
  const [format, setFormat] = useState("AM/PM");
  const formats = [
    { key: "AM/PM", label: "AM/PM" },
    { key: "AM", label: "AM" },
    { key: "PM", label: "PM" },
  ];
  const [slots, setSlots] = useState([
    { day: "Monday", startTime: "12:00 am", endTime: "12:00 am" },
    { day: "Tuesday", startTime: "12:00 am", endTime: "12:00 am" },
    { day: "Wednesday", startTime: "12:00 am", endTime: "12:00 am" },
    { day: "Thursday", startTime: "12:00 am", endTime: "12:00 am" },
    { day: "Friday", startTime: "12:00 am", endTime: "12:00 am" },
    { day: "Saturday", startTime: "12:00 am", endTime: "12:00 am" },
    { day: "Sunday", startTime: "12:00 am", endTime: "12:00 am" },
  ]);

  const onChangeSlotStart = (time, index) => {
    let slotsData = [...slots];
    slotsData[index] = {
      ...slotsData[index],
      startTime: time,
    };
    setSlots(slotsData);
  };

  const onChangeSlotEnd = (time, index) => {
    let slotsData = [...slots];
    slotsData[index] = {
      ...slotsData[index],
      endTime: time,
    };
    setSlots(slotsData);
  };
  return (
    <div className="-mt-3">
      <div className="w-full flex justify-end">
        <ThemeDropDown
          text={format}
          items={formats}
          setValue={(e) => setFormat(e)}
          className={
            "border-none outline-none shadow-none focus:shadow-none font-MontserratBold text-[16px]"
          }
        />
      </div>
      <div className="mt-6">
        {slots?.map((slot, index) => {
          return (
            <div
              key={index}
              className="mb-4 flex md:items-center md:justify-between md:flex-row flex-col"
            >
              <span className="flex items-center">
                <div className="h-[7px] w-[7px] rounded-full bg-primary mr-2"></div>
                <span className="text-sm">{slot?.day}</span>
              </span>
              <span className="md:ml-0 ml-auto md:mt-0 mt-2 flex items-center flex-nowrap">
                <ThemeTimePicker
                  placeholder={"12:00 am"}
                  value={
                    slot[index]?.startTime &&
                    dayjs(slot[index]?.startTime, "hh:mm a")
                  }
                  onChange={(_, time) => onChangeSlotStart(time, index)}
                />
                <ThemeTimePicker
                  className={"ml-6"}
                  placeholder={"12:00 am"}
                  value={
                    slot[index]?.startTime &&
                    dayjs(slot[index]?.startTime, "hh:mm a")
                  }
                  onChange={(_, time) => onChangeSlotEnd(time, index)}
                />
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TimeSlots;
