import React from "react";
import { Checkbox, Input, Radio } from "antd";
import PhoneInput from "react-phone-input-2";
import { whatsapp_icon } from "../../assets/icons/icons";
import * as images from "../../assets/images/images";
const LeadGenerationPreview = ({ type }) => {
  const leadData = {
    image: "lead_banner",
    title: "Loremipsum",
    description: "Meet fellow Sterlings and the 'people behind the jobs'",
  };

  const getInput = (input, index) => {
    if (input?.value === "text") {
      return (
        <Input
          key={index}
          type="text"
          className="w-full md:h-8 h-11 rounded border-[0.5px] border-[#A4A4A4] md:text-xs text-base focus:!border-primary"
        />
      );
    } else if (input?.value === "radio") {
      return (
        <Radio.Group key={index}>
          {input?.answerOptions?.map((radio, optionIndex) => {
            return (
              <Radio
                key={optionIndex}
                value={radio}
                className="md:text-xs text-base md:my-1 my-2"
              >
                {radio}
              </Radio>
            );
          })}
        </Radio.Group>
      );
    } else if (input?.value === "checkbox") {
      return (
        <div key={index}>
          {input?.answerOptions?.map((checkbox, optionIndex) => {
            return (
              <Checkbox
                key={optionIndex}
                className="md:text-xs text-base md:my-1 my-2"
              >
                {checkbox}
              </Checkbox>
            );
          })}
        </div>
      );
    } else if (input?.value === "textArea") {
      return <Input.TextArea className="md:text-xs text-base" />;
    }
  };

  return (
    <div className="h-full md:py-2 py-0 md:rounded-3xl rounded-none">
      <div
        className={`relative h-44 bg-[#0C1842] rounded-t-3xl ${
          type?.sections[0]?.inputs[1]?.value === "square"
            ? "flex items-center justify-center"
            : type?.sections[0]?.inputs[1]?.value === "circle" &&
              "flex items-center justify-center"
        }`}
      >
        <img
          src={type?.sections[0]?.inputs[0]?.value || images[leadData?.image]}
          alt="banner"
          className={`${
            type?.sections[0]?.inputs[1]?.value === "square"
              ? "h-32 w-32 rounded object-cover"
              : type?.sections[0]?.inputs[1]?.value === "circle"
              ? "h-32 w-32 rounded-full object-cover"
              : "w-full md:h-36 h-40 md:rounded-t-3xl rounded-none object-cover"
          }`}
        />
      </div>
      <div className="p-5 pt-0 bg-[#0C1842] text-white py-6">
        <p className="md:text-sm text-xl mb-2 font-MontserratBold">
          {type?.sections[0]?.inputs[2]?.value || leadData?.title}
        </p>
        <p className="md:text-xs text-base mb-2">
          {type?.sections[0]?.inputs[3]?.value || leadData?.description}
        </p>
      </div>
      <div className="bg-[#FAFAFA] p-5 md:py-6 py-8 h-full">
        {type?.sections[1]?.inputs[0]?.value && (
          <p className="md:text-xs text-base mb-4">
            {type?.sections[1]?.inputs[0]?.value}
          </p>
        )}

        {/* <div className="mb-8">
          <div className="flex flex-col mb-4">
            <p className="text-[10px] font-semibold text-[] mb-1">Full Name</p>
            <input
              type="text"
              className="w-full h-8 rounded border-[0.5px] border-[#A4A4A4] text-xs indent-2 focus:!border-primary"
            />
          </div>

          <div className="flex flex-col mb-4">
            <p className="text-[10px] font-semibold text-[#031D36] mb-1">
              Email
            </p>
            <input
              type="email"
              className="w-full h-8 rounded border-[0.5px] border-[#A4A4A4] text-xs indent-2 focus:!border-primary"
            />
          </div>

          <div className="flex flex-col mb-4">
            <p className="text-[10px] font-semibold text-[#031D36] mb-1">
              Organization
            </p>
            <input
              type="text"
              className="w-full h-8 rounded border-[0.5px] border-[#A4A4A4] text-xs indent-2 focus:!border-primary"
            />
          </div>
          <div className="flex flex-col mb-4">
            <p className="text-[10px] font-semibold text-[#031D36] mb-1">
              Number
            </p>
            <div className="w-full h-8 rounded text-xs indent-2 focus:!border-primary bg-white">
              <PhoneInput
                country={"pk"}
                className="border-none focus:border-none h-8 phone-input"
              />
            </div>
          </div>
          <div>
            <p className="text-[10px] font-semibold text-[#031D36] mb-1">
              Interested In
            </p>
            <Radio.Group>
              <Radio value={"WMS"}>WMS</Radio>
              <Radio value={"Other"}>Other Solution</Radio>
            </Radio.Group>
          </div>
        </div> */}
        <div className="mb-8">
          {type?.sections[1]?.inputs[1]?.value?.map(
            (question, questionIndex) => {
              return (
                <div className="md:mb-4 mb-6 flex flex-col" key={questionIndex}>
                  {question?.inputs?.map((input, inputIndex) => {
                    if (input?.type === "text") {
                      return (
                        <p
                          className="md:text-[10px] text-base font-semibold mb-1"
                          key={inputIndex}
                        >
                          {input?.value}
                        </p>
                      );
                    } else if (input?.type === "selectList") {
                      return getInput(input, inputIndex);
                    }
                  })}
                </div>
              );
            }
          )}
        </div>
        {(type?.sections[1]?.inputs[1]?.value[0]?.inputs[0]?.value ||
          type?.sections[1]?.inputs[1]?.value[0]?.inputs[1]?.value) && (
          <div className="flex flex-col items-center mb-8">
            <button className="w-11/12 bg-primary text-white md:text-xs text-base rounded mb-3 md:h-8 h-11">
              SEND
            </button>
            <button className="w-11/12 bg-primary text-white md:text-xs text-base rounded mb-3 md:h-8 h-11">
              DOWNLOAD
            </button>
          </div>
        )}
        {/* <div className="absolute bottom-0">
          <img src={whatsapp_icon} className="-mr-6 -mb-6" />
        </div> */}
      </div>
    </div>
  );
};

export default LeadGenerationPreview;
