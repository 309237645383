import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const RichText = ({
  value,
  onChange,
  labelPrimary,
  label,
  textMd,
  required,
  className,
}) => {
  return (
    <div className={className}>
      <p
        className={`${labelPrimary && "text-primary"} ${
          textMd ? "font-medium" : "font-semibold"
        } mb-1 flex items-center text-sm`}
      >
        {required && <p className="mr-1 text-themeRed">*</p>} {label}
      </p>{" "}
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        className="text-sm"
      />
    </div>
  );
};

export default RichText;
