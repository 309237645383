import React from "react";
import { food } from "../../assets/images/images";
import { rightDrop_icon } from "../../assets/icons/icons";

const ReviewPreview = ({ type }) => {
  const primaryColor = type?.sections[3]?.inputs[0]?.value[0];
  const buttonColor = type?.sections[3]?.inputs[0]?.value[1];
  const headingColor = type?.sections[3]?.inputs[0]?.value[2];
  return (
    <div className="h-full">
      <p
        className="rounded-t-3xl text-center text-base font-MontserratBold py-2 !my-0"
        style={{ backgroundColor: primaryColor, color: headingColor }}
      >
        Hotel Paradise
      </p>
      <div className="h-full p-6 py-8 bg-[#F0F0F0] relative">
        <div className="mb-12">
          <p className="text-xs font-MontserratBold mb-1">
            Give us your feedback
          </p>
          <p className="text-[11px] text-[#7D7D7D]">
            Please select to review a category.
          </p>
        </div>

        <div className="flex flex-col w-full">
          <div className="flex items-center bg-white rounded-lg h-10 px-4 preview-dropbox mb-2">
            <div className="flex items-center">
              <img src={food} />
              <p className="ml-4 text-sm font-semibold">Food</p>
            </div>
          </div>
          <div className="flex items-center justify-between bg-white rounded-lg h-10 px-4 preview-dropbox mb-2">
            <div className="flex items-center">
              <img src={food} />
              <p className="ml-4 text-sm font-semibold">Drinks</p>
            </div>
            <img src={rightDrop_icon} />
          </div>
          <div className="flex items-center bg-white rounded-lg h-10 px-4 preview-dropbox mb-2">
            <div className="flex items-center">
              <img src={food} />
              <p className="ml-4 text-sm font-semibold">Parking</p>
            </div>
          </div>
        </div>

        <p className="text-[10px] underline text-primary text-center absolute bottom-20 w-full -ml-7 font-semibold">
          Go to our website
        </p>
      </div>
    </div>
  );
};

export default ReviewPreview;
