import React, { useState } from "react";
import { ThemeButton } from "../../ui/ui";
import { ImagePreviewModal } from "../components";
import { InfoCircleOutlined } from "@ant-design/icons";

const ImageBox = ({
  label,
  placeholder,
  image,
  setImage,
  className,
  id,
  errorMessage,
  setErrorMessage,
  previewBtn,
}) => {
  const allowedFormats = ["image/jpeg", "image/png"];
  const [isOpen, setIsOpen] = useState(false);

  const onChangeImage = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      if (allowedFormats.includes(selectedImage.type)) {
        setImage(URL.createObjectURL(selectedImage), selectedImage);
        setErrorMessage("");
      } else {
        setImage("", "");
        setErrorMessage(
          "Invalid file format. Please select a JPEG or PNG image."
        );
      }
    }
  };
  return (
    <div className={`${className}`}>
      <label htmlFor={`${id}`}>
        <div className="w-full h-40 rounded-md inputBox-shadow flex items-center justify-center mb-2 cursor-pointer">
          {image ? (
            <img
              src={image}
              alt="image"
              className="w-full h-40 object-contain"
            />
          ) : (
            <span className="text-lg font-semibold text-themeGray-100">
              {label}
            </span>
          )}
        </div>
        <input
          type="file"
          onChange={(e) => onChangeImage(e)}
          id={id}
          className="hidden"
          accept=".png, .jpg, .jpeg"
        />
      </label>
      <p className="text-xs font-semibold text-themeGray-200">{placeholder}</p>
      {errorMessage && (
        <p className="text-xs text-red-600 font-semibold flex items-center mt-1">
          <InfoCircleOutlined className="mr-2" /> {errorMessage}
        </p>
      )}
      {previewBtn && (
        <div className="mt-6 flex items-center justify-between">
          <label htmlFor="uploader-change">
            {/* <ThemeButton
              bgColor={"bg-[#002B38]"}
              textMd
              className={"text-white rounded w-32 !h-10 hover:bg-primary"}
              content={ */}
            <div className="bg-[#002B38] text-white w-32 h-10 rounded font-medium flex justify-center items-center text-sm cursor-pointer">
              <p>Change</p>
              <input
                type="file"
                onChange={(e) => onChangeImage(e)}
                id={"uploader-change"}
                className="hidden"
                accept="image/png, image/jpeg, image/jpg"
              />
            </div>
            {/* } */}
            {/* /> */}
          </label>
          <ThemeButton
            textMd
            className={"text-white rounded w-32 !h-10"}
            content={<p>Preview</p>}
            disabled={!image}
            onClick={() => setIsOpen(true)}
          />
        </div>
      )}
      <ImagePreviewModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        image={image}
      />
    </div>
  );
};

export default ImageBox;
