import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getValueFromStorage } from "../helpers/helpers";

export const QrInteractionApi = createApi({
  reducerPath: "qrInteraction",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getValueFromStorage("token")}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    addQrInetraction: builder.mutation({
      query: (body) => ({
        url: `/api/qrinterception`,
        method: "POST",
        body: body,
      }),
    }),
    getQrInteractions: builder.query({
      query: ({ id, page = 1, perPage = 100000 }) => ({
        url: `/api/qrinterception/${id}`,
        method: "GET",
        params: { page: page, perPage: perPage },
      }),
    }),
  }),
});

export const { useAddQrInetractionMutation, useGetQrInteractionsQuery } =
  QrInteractionApi;
