import React, { useState } from "react";
import { file_icon } from "../../assets/icons/icons";
import { Spin, message } from "antd";

const PDFPreview = ({ type }) => {
  const [pdfLoader, setPdfLoader] = useState(false);
  const pdfData = {
    name: "Loremipsum",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s when an unknown printer took a galley of",
  };

  const handleDownload = async () => {
    const pdfUrl = type?.sections[0]?.inputs[2]?.value?.file; // Replace with the actual PDF file URL

    try {
      setPdfLoader(true);
      const response = await fetch(pdfUrl);
      const blob = await response.blob();

      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = type?.sections[0]?.inputs[2]?.value?.name; // You can change the downloaded file name

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      setPdfLoader(false);
    } catch (error) {
      setPdfLoader(false);
      message.error("Error downloading PDF");
    }
  };

  return (
    <div className="md:rounded-3xl rounded-none h-full">
      <div className="p-6 md:py-8 py-10 bg-[#002B38] md:rounded-t-3xl rounded-t-none min-h-[40%] flex flex-col justify-center">
        <p className="md:text-base text-3xl font-MontserratBold text-white text-center mb-6">
          {type?.sections[0]?.inputs[0]?.value || pdfData?.name}
        </p>
        <p className="md:text-xs text-lg md:leading-5 leading-8 text-white text-center">
          {type?.sections[0]?.inputs[1]?.value || pdfData?.description}
        </p>
      </div>
      <div className=" bg-[#F6F5F5] flex flex-col items-center md:rounded-b-3xl rounded-b-none h-full justify-between px-6">
        <div className="flex items-center justify-center md:py-16 mb-3 h-full">
          <img
            src={file_icon}
            alt="icon"
            className="md:w-auto w-24 md:h-auto h-24"
          />
          <span className="md:text-xs text-lg font-MontserratBold ml-2">
            {type?.sections[0]?.inputs[2]?.value?.name || "Testing file.docx"}
          </span>
        </div>
        <button
          className="md:text-xs text-lg font-semibold bg-primary text-white rounded md:h-8 h-14 md:w-8/12 w-full mb-4 flex items-center justify-center"
          onClick={handleDownload}
        >
          {pdfLoader ? (
            <Spin className="loader-white" />
          ) : (
            <span>Download PDF</span>
          )}
        </button>
      </div>
    </div>
  );
};

export default PDFPreview;
