import React from "react";
import { Progress } from "antd";
const ProgressBar = ({ currentStep, totalSteps }) => {
  const percent = (currentStep / totalSteps) * 100;

  return (
    <Progress percent={percent} className="md:w-72 w-48" showInfo={false} />
  );
};

export default ProgressBar;
