import React, { useState } from "react";
import { ThemeSwitch, ThemeInput } from "../../ui/ui";
import { Collapse, Divider } from "antd";
import TemplateSection from "./TemplateSection";
import EyesSection from "./EyesSection";
import LogoSection from "./LogoSection";
import PatternSection from "./PatternSection";
import BackgroundSection from "./BackgroundSection";
import FrameSection from "./FrameSection";
import { dropdown_icon } from "../../assets/icons/icons";
import * as customization_icons from "../../assets/icons/qrcustomization/qrcustomization";

const QrCustomization = ({ formData, setFormData }) => {
  const sections = [
    { icon: "templates_icon", title: "Templates" },
    { icon: "eyes_icon", title: "Eyes" },
    { icon: "logo_icon", title: "Logo" },
    { icon: "pattern_icon", title: "Pattern" },
    { icon: "background_icon", title: "Background" },
    { icon: "frame_icon", title: "Frames" },
  ];

  const [appearanceType, setAppearanceType] = useState(1);

  const resetForm = () => {
    setFormData({
      template: "Default",
      eyeBall: {
        type: "square",
        color: "#000000",
        eyeFrame: "square",
        eyeFrameColor: "#000000",
      },
      logo: {
        size: 5,
      },
      pattern: {
        type: "square",
        color: {
          type: "solid",
          color: ["#000000", "#000000"],
        },
      },
      background: {
        type: "transparent",
        color: ["#ffffff", "#000000"],
        isTransparent: true,
      },
      frame: {
        color: "#000000",
        textColor: "#ffffff",
      },
      settings: {
        autoGenerated: false,
        gpsLocation: false,
        isPasswordProtected: false,
      },
    });
    setAppearanceType(1);
  };

  return (
    <div className="bg-white rounded-md p-10 qrbox-shadow mb-12 sectionBox">
      <h2 className="mb-2">
        <span className="text-secondary font-MontserratBold font-bold text-3xl">
          QR Appearance
        </span>{" "}
        <span
          className="text-primary underline font-semibold ml-2 cursor-pointer"
          onClick={() => resetForm()}
        >
          RESET TO DEFAULT
        </span>
      </h2>
      <p className="text-xs text-themeGray-200 mb-4">
        Customize the design of your QR code with colors, logos and shapes.
      </p>
      <div className="flex mb-8">
        {sections.map((section, index) => {
          return (
            <div className="p-2" key={index}>
              <div className="flex flex-col items-center">
                <div
                  className={`flex flex-col items-center justify-center type-box-shadow w-[85px] h-[85px] rounded cursor-pointer border-2 hover:border-2 hover:border-primary ${
                    appearanceType == index + 1 && "border-primary"
                  }`}
                  onClick={() => setAppearanceType(index + 1)}
                >
                  <img
                    src={customization_icons[section.icon]}
                    alt="icon"
                    className="w-14 h-14"
                  />
                </div>
                <p className="text-primary text-xs mt-2 font-semibold">
                  {section.title}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div>
        {appearanceType === 1 && (
          <TemplateSection
            formData={formData}
            setFormData={(e) => setFormData(e)}
          />
        )}
        {appearanceType === 2 && (
          <EyesSection
            formData={formData}
            setFormData={(e) => setFormData(e)}
          />
        )}
        {appearanceType === 3 && (
          <LogoSection
            formData={formData}
            setFormData={(e) => setFormData(e)}
          />
        )}
        {appearanceType === 4 && (
          <PatternSection
            formData={formData}
            setFormData={(e) => setFormData(e)}
          />
        )}
        {appearanceType === 5 && (
          <BackgroundSection
            formData={formData}
            setFormData={(e) => setFormData(e)}
          />
        )}
        {appearanceType === 6 && (
          <FrameSection
            formData={formData}
            setFormData={(e) => setFormData(e)}
          />
        )}
        {/* <div className="mb-12">
          <div className="mb-10">
            <h1 className="text-base font-bold text-primary mb-1">SHORT URL</h1>
            <p className="text-xs text-themeGray-200 mb-3">
              This will be the short URL for this QR code
            </p>
            <div className="mb-4">
              <ThemeSwitch
                checked={formData?.settings?.autoGenerated}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    settings: { ...formData?.settings, autoGenerated: e },
                  })
                }
                label={"Auto-generated"}
              />
            </div>
            <ThemeInput
              placeholder={"https://"}
              value={formData?.settings?.shortUrl}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  settings: { ...formData?.settings, shortUrl: e.target.value },
                })
              }
              disabled={formData?.settings?.autoGenerated}
            />
          </div>

          <div className="mb-4">
            <h1 className="text-base font-bold text-primary mb-1">
              ADVANCED LOCATION ANALYTICS
            </h1>
            <p className="text-xs text-themeGray-200 mb-3">
              Enable GPS location analytics data collection for scans of this QR
              code
            </p>
            <div className="mb-4">
              <ThemeSwitch
                label={"GPS Location"}
                checked={formData?.settings?.gpsLocation}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    settings: { ...formData?.settings, gpsLocation: e },
                  })
                }
              />
            </div>
          </div>
        </div> */}
        <Divider dashed style={{ borderColor: "#C6C6C6" }} className="mb-2" />
        <Collapse
          expandIcon={({ isActive }) =>
            isActive ? (
              <img src={dropdown_icon} className=" rotate-180" />
            ) : (
              <img src={dropdown_icon} />
            )
          }
          expandIconPosition={"end"}
          ghost={true}
          items={[
            {
              label: (
                <p className="text-secondary text-xl font-semibold">
                  SHOW ADVANCED SETTINGS
                </p>
              ),
              key: "1",
              children: (
                <div>
                  <div className="flex flex-col mb-8">
                    <h2 className="text-lg text-primary font-semibold mb-2">
                      PASSWORD PROTECTION
                    </h2>
                    <div className="mb-2">
                      <ThemeSwitch
                        label={"Require a password to access content"}
                        checked={formData?.settings?.isPasswordProtected}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            settings: {
                              ...formData?.settings,
                              isPasswordProtected: e,
                            },
                          })
                        }
                      />
                    </div>
                    <ThemeInput
                      placeholder="password"
                      password
                      inputHeight={"h-11"}
                      disabled={!formData?.settings?.isPasswordProtected}
                      value={formData?.settings?.password}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          settings: {
                            ...formData?.settings,
                            password: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              ),
            },
          ]}
          className="-ml-4"
        />
      </div>
    </div>
  );
};

export default QrCustomization;
