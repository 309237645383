import React, { useState } from "react";
import * as images from "../../assets/images/images";
import { getSocialType } from "../../helpers/helpers";
import * as social_icons from "../../assets/icons/socials/socials";

const ListPreview = ({ type }) => {
  const primaryColor = type?.sections[3]?.inputs[0]?.value[0];
  const buttonColor = type?.sections[3]?.inputs[0]?.value[1];
  const headingColor = type?.sections[3]?.inputs[0]?.value[2];
  const linksData = {
    title: "Tech Company",
    description: "Top Tech Company",
    logo: "links_logo",
  };

  return (
    <div className="h-full flex flex-col items-center md:justify-center md:px-4 px-6">
      <div className="flex flex-col items-center md:mt-10 mt-20">
        <div
          className={`flex items-center justify-center border ${
            type?.sections[0]?.inputs[1]?.value === "circle"
              ? "rounded-full"
              : "rounded"
          } ${
            type?.sections[0]?.inputs[1]?.value === "rectangle"
              ? "md:w-16 md:h-14 w-36 h-32"
              : "md:w-14 md:h-14 w-32 h-32"
          }`}
          style={{ borderColor: primaryColor }}
        >
          <img
            src={type?.sections[0]?.inputs[0]?.value || images[linksData?.logo]}
            className="md:w-12 md:h-12 w-28 h-28"
          />
        </div>
        <h2 className="md:text-base text-3xl font-MontserratBold font-semibold text-secondary md:mt-1 mt-3 text-center">
          {type?.sections[0]?.inputs[2]?.value || linksData?.title}
        </h2>
        <p className="text-center md:text-xs text-base text-themeGray-200 font-semibold">
          {type?.sections[0]?.inputs[3]?.value || linksData?.description}
        </p>
      </div>

      <div className="flex flex-col items-center md:mt-4 mt-8 w-full">
        {type?.sections[1]?.inputs[0]?.value?.length > 0 &&
          type?.sections[1]?.inputs[0]?.value?.map((link, index) => {
            return (
              <a
                key={index}
                href={link?.url}
                target="_blank"
                className="w-full md:h-10 h-16 rounded md:mb-3 mb-4 md:text-xs text-base flex items-center justify-center font-semibold"
                style={{ backgroundColor: buttonColor, color: headingColor }}
              >
                <p className="text-center mb-0">
                  {link?.title || "Click here"}
                </p>
              </a>
            );
          })}
      </div>

      <div className="flex items-center justify-center flex-wrap my-6 md:mt-6 mt-12 ">
        {type?.sections[2]?.inputs[0]?.value?.length > 0 &&
          type?.sections[2]?.inputs[0]?.value?.map((link, index) => {
            return (
              <div className="flex items-center p-2" key={index}>
                <a
                  href={link?.value}
                  target="_blank"
                  className="cursor-pointer"
                >
                  <img
                    src={social_icons[getSocialType(link?.type)?.icon]}
                    alt="icon"
                    className="lg:w-7 lg:h-7 md:w-7 md:h-7 w-14 h-14"
                  />
                </a>
              </div>
            );
          })}
      </div>

      {/* <div
        className="md:rounded-t-3xl"
        style={{ backgroundColor: primaryColor }}
      >
        <h1 className="text-white p-4 rounded-t-3xl  md:text-sm text-xl font-semibold px-5 w-full">
          {type?.sections[0]?.inputs[2]?.value || linksData?.title}
        </h1>
        <div
          className={`md:h-36 h-48 ${
            type?.sections[0]?.inputs[1]?.value === "square"
              ? "flex items-center justify-center"
              : type?.sections[0]?.inputs[1]?.value === "circle" &&
                "flex items-center justify-center w-full"
          }`}
        >
          <img
            src={type?.sections[0]?.inputs[0]?.value || images[linksData?.logo]}
            alt="banner"
            className={`${
              type?.sections[0]?.inputs[1]?.value === "square"
                ? "md:h-32 md:w-32 w-40 h-40 rounded object-cover"
                : type?.sections[0]?.inputs[1]?.value === "circle"
                ? "md:h-32 md:w-32 h-40 w-40 rounded-full object-cover"
                : "w-full md:h-40 h-48 object-fill"
            }`}
          />
        </div>

        <div className="py-8 px-5 flex flex-col text-white">
          <p className="md:text-xs text-base  mb-2">
            {type?.sections[0]?.inputs[3]?.value || linksData?.description}
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default ListPreview;
