import React, { useState } from "react";
import { Footer, MenuDrawer, Navbar, SideBar } from "../components";
import { useNavigate } from "react-router-dom";
import { RiMenu3Fill } from "react-icons/ri";

const DashboardContainer = ({ selected, children }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const onHandleRoute = (e) => {
    e.key === "1" && navigate("/profile");
    e.key === "2" && navigate("/folder");
    e.key === "3" && navigate("/dashboard");
    e.key === "4" && navigate("/faq");
    e.key === "5" && navigate("/subscription");
    e.key === "6" && navigate("/support");
    e.key === "7" && navigate("/purchases");
  };
  function getItem(label, key, children, type) {
    return {
      key,
      children,
      label,
      type,
    };
  }
  const items = [
    getItem(
      <p
        className={`${
          selected === "1" ? "text-white" : "text-secondary"
        } font-semibold text-sm font-MontserratBold`}
      >
        Personal Info
      </p>,
      "1"
    ),
    getItem(
      <p
        className={`${
          selected === "2" ? "text-white" : "text-secondary"
        } font-semibold text-sm font-MontserratBold`}
      >
        Folders
      </p>,
      "2"
    ),
    getItem(
      <p
        className={`${
          selected === "3" ? "text-white" : "text-secondary"
        } font-semibold text-sm font-MontserratBold`}
      >
        Qr Codes
      </p>,
      "3"
    ),
    getItem(
      <p
        className={`${
          selected === "7" ? "text-white" : "text-secondary"
        } font-semibold text-sm font-MontserratBold`}
      >
        Purchases
      </p>,
      "7"
    ),
    getItem(
      <p
        className={`${
          selected === "4" ? "text-white" : "text-secondary"
        } font-semibold text-sm font-MontserratBold`}
      >
        FAQ
      </p>,
      "4"
    ),
    getItem(
      <p
        className={`${
          selected === "5" ? "text-white" : "text-secondary"
        } font-semibold text-sm font-MontserratBold`}
      >
        Premium Subscription
      </p>,
      "5"
    ),
    getItem(
      <p
        className={`${
          selected === "6" ? "text-white" : "text-secondary"
        } font-semibold text-sm font-MontserratBold`}
      >
        Support
      </p>,
      "6"
    ),
  ];

  return (
    <div className="min-h-screen">
      <Navbar
        menu={
          <span
            className="text-2xl cursor-pointer md:hidden flex ml-2"
            onClick={() => setIsOpen(true)}
          >
            <RiMenu3Fill />
          </span>
        }
      />
      <main className="main-box pt-[58px] flex">
        <div
          // style={{ width: 200 }}
          className="fixed top-[58px] min-h-[calc(100vh-60px)] md:flex hidden"
        >
          <SideBar selected={selected} items={items} onClick={onHandleRoute} />
        </div>
        <div className="md:pl-[189px] pl-0 w-full">{children}</div>
      </main>

      <Footer />
      <div className="md:hidden flex">
        <MenuDrawer
          open={isOpen}
          onClose={() => setIsOpen(false)}
          selected={selected}
          items={items}
          onHandleRoute={onHandleRoute}
        />
      </div>
    </div>
  );
};

export default DashboardContainer;
