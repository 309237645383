import React from "react";

const Footer = () => {
  return (
    <div className="body-font bg-themeGray-lighter navbar-shadow flex justify-center fixed w-full bottom-0 z-50">
      <p className=" font-MontserratBold p-3 text-center text-base">
        Powered By
        <a
          href="https://techoid.co/"
          target="_blank"
          className="text-primary font-quicksandBold ml-1"
        >
          Techoid
        </a>
      </p>
    </div>
  );
};

export default Footer;
