import React, { useEffect, useState } from "react";
import QrCodePreviewContainer from "../../components/QrCodePreviewContaienr";
import { Result, Spin, message } from "antd";
import { useGetQrCodeByUrlIdQuery } from "../../services/qrCode";
import { useParams } from "react-router-dom";
import MenuPreview from "../../components/Preview/MenuPreview";
import CouponPreview from "../../components/Preview/CouponPreview";
import AppStorePreview from "../../components/Preview/AppstorePreview";
import VcardPreview from "../../components/Preview/VcardPreview";
import ProductPreview from "../../components/Preview/ProductPreview";
import EventPreview from "../../components/Preview/EventPreview";
import BioPreview from "../../components/Preview/BioPreview";
import LeadGenerationPreview from "../../components/Preview/LeadGenerationPreview";
import RatingPreview from "../../components/Preview/RatingPreview";
import ReviewPreview from "../../components/Preview/ReviewPreview";
import PDFPreview from "../../components/Preview/PDFPreview";
import UrlPreview from "../../components/Preview/UrlPreview";
import LocationPreview from "../../components/Preview/locationPreview";
import { useAddStatMutation } from "../../services/qrStats";
import { getUserMetaData } from "../../helpers/helpers";
import { useAddQrInetractionMutation } from "../../services/qrInteraction";
import ListPreview from "../../components/Preview/ListPreview";
import SocialPreview from "../../components/Preview/SocialPreview";

const ConsumerPreview = () => {
  // const [formData, setFormData] = useState({});
  const { urlid } = useParams();
  // const { data, isLoading, refetch } = useGetQrCodeByUrlIdQuery(urlid);
  // const [addStat, { isLoading: addingStats }] = useAddStatMutation();
  // const [addQrInteraction, { isLoading: submittingLead }] =
  //   useAddQrInetractionMutation();

  // useEffect(() => {
  //   if (data?.status?.code === 200) {
  //     if (data?.data) {
  //       onQrScan();
  //       let qrData = data?.data?.qrinsightdata;
  //       if (data?.data?.type?.type === "email") {
  //         const emailLink = `mailto:${qrData[0]?.inputs[0]?.value}?subject=${qrData[0]?.inputs[1].value}&body=${qrData[0]?.inputs[2].value}`;
  //         window.location.href = emailLink;
  //       } else if (data?.data?.type?.type === "call") {
  //         const phoneLink = `tel:${qrData[0]?.inputs[0]?.value}`;
  //         window.location.href = phoneLink;
  //       } else if (data?.data?.type?.type === "link") {
  //         const link = qrData[0]?.inputs[0]?.value;
  //         window.location.href = link;
  //       } else if (data?.data?.type?.type === "wifi") {
  //         const wifi = `WIFI:T:${qrData[0]?.inputs[1]?.value};S:${qrData[0]?.inputs[0]?.value};P:${qrData[0]?.inputs[2]?.value};`;
  //         window.location.href = wifi;
  //       } else if (data?.data?.type?.type === "whatsapp") {
  //         const whatsappURL = `whatsapp://send?phone=${
  //           qrData[0]?.inputs[0]?.value
  //         }&text=${encodeURIComponent(qrData[0]?.inputs[1]?.value)}`;
  //         window.location.href = whatsappURL;
  //       } else if (data?.data?.type?.type === "maps") {
  //         const googleMapURL = `https://www.google.com/maps?q=${qrData[0]?.inputs[1]?.value},${qrData[0]?.inputs[2]?.value}`;
  //         window.open(googleMapURL, "_blank");
  //       }
  //     }
  //   }
  // }, [data]);

  // const onQrScan = async () => {
  //   const userMetadata = await getUserMetaData();
  //   const response = await addStat({
  //     ...userMetadata,
  //     qrCodeId: data?.data?._id,
  //     userId: data?.data?.userId,
  //   });
  // };

  // const onSubmit = async () => {
  //   const response = await addQrInteraction({
  //     data: { ...formData },
  //     qrCodeId: data?.data?._id,
  //     qrType: data?.data?.type?._id,
  //   });

  //   if (response?.data?.status?.code === 201) {
  //     message.success("Lead submit successfully");
  //     setFormData({});
  //   } else {
  //     message.error("Lead submit failed");
  //   }
  // };

  useEffect(() => {
    window.location.assign(`https://www.myqr.live/${urlid}`);
  }, [urlid]);

  return (
    // <div className="w-full min-h-screen flex md:items-center items-start md:justify-center justify-start">
    //   {isLoading ? (
    //     <div className="flex items-center justify-center h-screen w-full">
    //       <Spin size="large" />
    //     </div>
    //   ) : (
    //     <div className="bg-white rounded-3xl md:p-3 md:h-[500px] h-full md:w-64 w-full  md:inputBox-shadow shadow-none z-20 ">
    //       {data?.data ? (
    //         <div className="h-full overflow-y-auto overflow-x-hidden preview-scroll md:rounded-3xl">
    //           {data?.data?.type?.type === "menu" && (
    //             <MenuPreview
    //               type={{ sections: [...data?.data?.qrinsightdata] }}
    //             />
    //           )}
    //           {data?.data?.type?.type === "coupon" && (
    //             <CouponPreview
    //               type={{ sections: [...data?.data?.qrinsightdata] }}
    //             />
    //           )}
    //           {data?.data?.type?.type === "appstore" && (
    //             <AppStorePreview
    //               type={{ sections: [...data?.data?.qrinsightdata] }}
    //             />
    //           )}
    //           {data?.data?.type?.type === "vcard" && (
    //             <VcardPreview
    //               type={{ sections: [...data?.data?.qrinsightdata] }}
    //               qrCodeData={data?.data?.customization}
    //               formData={formData}
    //               setFormData={setFormData}
    //               onSubmit={onSubmit}
    //               isLoading={submittingLead}
    //               isConsumer={true}
    //               urlid={urlid}
    //             />
    //           )}
    //           {data?.data?.type?.type === "product" && (
    //             <ProductPreview
    //               type={{ sections: [...data?.data?.qrinsightdata] }}
    //             />
    //           )}
    //           {data?.data?.type?.type === "bio" && (
    //             <BioPreview
    //               type={{ sections: [...data?.data?.qrinsightdata] }}
    //             />
    //           )}
    //           {data?.data?.type?.type === "lead" && (
    //             <LeadGenerationPreview
    //               type={{ sections: [...data?.data?.qrinsightdata] }}
    //             />
    //           )}
    //           {data?.data?.type?.type === "event" && (
    //             <EventPreview
    //               type={{ sections: [...data?.data?.qrinsightdata] }}
    //             />
    //           )}
    //           {data?.data?.type?.type === "rating" && (
    //             <RatingPreview
    //               type={{ sections: [...data?.data?.qrinsightdata] }}
    //             />
    //           )}
    //           {data?.data?.type?.type === "review" && (
    //             <ReviewPreview
    //               type={{ sections: [...data?.data?.qrinsightdata] }}
    //             />
    //           )}
    //           {data?.data?.type?.type === "pdf" && (
    //             <PDFPreview
    //               type={{ sections: [...data?.data?.qrinsightdata] }}
    //             />
    //           )}
    //           {data?.data?.type?.type === "url" && (
    //             <UrlPreview
    //               type={{ sections: [...data?.data?.qrinsightdata] }}
    //             />
    //           )}
    //           {data?.data?.type?.type === "listoflinks" && (
    //             <ListPreview
    //               type={{ sections: [...data?.data?.qrinsightdata] }}
    //             />
    //           )}
    //           {data?.data?.type?.type === "socials" && (
    //             <SocialPreview
    //               type={{ sections: [...data?.data?.qrinsightdata] }}
    //               isConsumer={true}
    //             />
    //           )}
    //         </div>
    //       ) : (
    //         // <QrCodePreviewContainer data={data?.data} />
    //         <Result
    //           status="404"
    //           title="404"
    //           subTitle="Sorry, the page you visited does not exist."
    //         />
    //       )}
    //     </div>
    //   )}
    // </div>
    <div></div>
  );
};

export default ConsumerPreview;
