import React from "react";
import { Dropdown, Button, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { dropdownBlue_icon } from "../assets/icons/icons";

const ThemeDropDown = ({
  text,
  items,
  className,
  large,
  setValue,
  label,
  labelPrimary,
  textMd,
  required,
}) => {
  const handleMenuClick = (e) => {
    setValue(e.key);
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  return (
    <div>
      <p
        className={`${labelPrimary && "text-primary"} ${
          textMd ? "font-medium" : "font-semibold"
        } mb-1 flex items-center text-sm`}
      >
        {required && <p className="mr-1 text-themeRed">*</p>} {label}
      </p>

      <Dropdown
        menu={menuProps}
        className={` ${large && "h-14"}  ${className}`}
      >
        <Button className={large && "h-10"}>
          <Space className="flex items-center justify-between">
            {text}
            <img src={dropdownBlue_icon} alt="icon" />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};

export default ThemeDropDown;
