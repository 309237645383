import { useEffect, useLayoutEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import {
  ConsumerPreview,
  Dashboard,
  Home,
  Login,
  Signup,
  Setup,
  TypeDetail,
  Verification,
  Statistics,
  FolderDetail,
  Profile,
  Folder,
  FAQ,
  Subscription,
  Support,
  Purchases,
} from "../pages/pages";
import { getUserInfo } from "../helpers/helpers";
import { Result } from "antd";

function ScrollToTop() {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function AppRouter() {
  const [user, setUser] = useState(getUserInfo()?.userId);

  const ProtectedRoute = ({ user, children }) => {
    if (!user) {
      return <Navigate to="/" />;
    }
    return children;
  };

  useEffect(() => {
    setAuthUser();
  }, []);

  const setAuthUser = (auth) => {
    const id = getUserInfo()?.userId;
    setUser(auth || id);
  };
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />

        <Route
          exact
          path="/login"
          element={<Login setAuthUser={setAuthUser} />}
        />

        <Route exact path="/signup" element={<Signup />} />

        <Route exact path="/:urlid" element={<ConsumerPreview />} />

        <Route
          exact
          path="/verification"
          element={
            <ProtectedRoute user={user}>
              <Verification />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/setup"
          element={
            <ProtectedRoute user={user}>
              <Setup />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/dashboard"
          element={
            <ProtectedRoute user={user}>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/profile"
          element={
            <ProtectedRoute user={user}>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/folder"
          element={
            <ProtectedRoute user={user}>
              <Folder />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/faq"
          element={
            <ProtectedRoute user={user}>
              <FAQ />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/subscription"
          element={
            <ProtectedRoute user={user}>
              <Subscription />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/support"
          element={
            <ProtectedRoute user={user}>
              <Support />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/folder/:id"
          element={
            <ProtectedRoute user={user}>
              <FolderDetail />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/statistics/:id"
          element={
            <ProtectedRoute user={user}>
              <Statistics />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/type/:type/:id"
          element={
            <ProtectedRoute user={user}>
              <TypeDetail />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/purchases"
          element={
            <ProtectedRoute user={user}>
              <Purchases />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="*"
          element={
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
