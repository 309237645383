import { Menu } from "antd";

const SideBar = ({ selected, items, onClick }) => {
  return (
    <div>
      <div className="mb-16"></div>
      <Menu
        onClick={onClick}
        style={{
          minHeight: "68vh",
        }}
        defaultSelectedKeys={[selected]}
        mode="inline"
        items={items}
      />
      <div className="flex justify-center">
        <button className="border border-primary w-40 h-10 text-sm font-semibold text-primary hover:bg-primary hover:text-white cursor-pointer mb-10">
          Upgrade Now
        </button>
      </div>
    </div>
  );
};

export default SideBar;
