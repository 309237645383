import React from "react";
import Rating from "react-rating";
import { star_fill_icon, star_icon } from "../assets/icons/icons";

const ThemeRating = ({
  labelPrimary,
  label,
  textMd,
  required,
  value,
  onChange,
  empty,
  full,
  readonly,
  resolation,
}) => {
  return (
    <div>
      <p
        className={`${labelPrimary && "text-primary"} ${
          textMd ? "font-medium" : "font-semibold"
        } mb-1 flex items-center text-sm`}
      >
        {required && <p className="mr-1 text-themeRed">*</p>} {label}
      </p>
      <Rating
        emptySymbol={
          <img
            src={`${empty ? empty : star_icon}`}
            className={`icon ${resolation ? resolation : "w-auto h-auto"}`}
          />
        }
        fullSymbol={
          <img
            src={`${full ? full : star_fill_icon} `}
            className={`icon ${resolation ? resolation : "w-auto h-auto"}`}
          />
        }
        initialRating={value}
        onChange={onChange}
        readonly={readonly}
      />
    </div>
  );
};

export default ThemeRating;
