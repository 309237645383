import React, { useState, useEffect } from "react";
import * as images from "../../assets/images/images";
import { getSocialType } from "../../helpers/helpers";
import * as social_icons from "../../assets/icons/socials/socials";
import { RiShareFill } from "react-icons/ri";
import { message } from "antd";

const SocialPreview = ({ type, isConsumer }) => {
  const [showInitialImage, setShowInitialImage] = useState(true);
  const primaryColor = type?.sections[3]?.inputs[0]?.value[0];
  const buttonColor = type?.sections[3]?.inputs[0]?.value[1];
  const headingColor = type?.sections[3]?.inputs[0]?.value[2];
  const socialData = {
    image: "social_banner",
    title: "Connect with us on social media",
    description:
      "Follow us and get updates delivered to your favorite social media channel.",
  };

  // useEffect(() => {
  //   if (isConsumer) {
  //     // Display the initial image for 2 seconds
  //     const timeout = setTimeout(() => {
  //       setShowInitialImage(false);
  //     }, 3000);

  //     // Clear the timeout on component unmount
  //     return () => clearTimeout(timeout);
  //   } else {
  //     // If isConsumer is false, don't show the initial image
  //     setShowInitialImage(false);
  //   }
  // }, [isConsumer]);

  const handleShare = async () => {
    try {
      const currentUrl = window.location.href;

      // Check if the Clipboard API is available
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(currentUrl);
        message.success("copied to clipboard");
      } else {
        // Fallback for browsers that do not support the Clipboard API
        const textarea = document.createElement("textarea");
        textarea.value = currentUrl;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        message.success("copied to clipboard");
      }
    } catch (error) {
      console.error("Error copying URL:", error);
    }
    try {
      const currentUrl = window.location.href;
      await navigator.clipboard.writeText(currentUrl);
      message.success("copied to clipboard");
    } catch (error) {
      console.error("Error copying URL:", error);
    }
  };

  // const handleShare = async (title, url) => {
  //   window.location.href = `intent://share?text=${title}&url=${url}`;
  // try {
  //   if (navigator.share) {
  //     await navigator.share({
  //       title,
  //       url,
  //     });
  //   } else {
  //     // Fallback for browsers that do not support the Web Share API
  //     alert("Web Share API not supported in this browser.");
  //   }
  // } catch (error) {
  //   console.error("Error sharing:", error.message);
  // }
  // };

  return (
    // <>
    //   {showInitialImage && (
    //     <div className="w-full h-screen flex items-center justify-center">
    //       {type?.sections[4]?.inputs[0]?.value ? (
    //         <img
    //           src={type?.sections[4]?.inputs[0]?.value}
    //           alt="welcome"
    //           className="w-full h-full object-cover"
    //         />
    //       ) : (
    //         <img src={images.logo} alt="logo" className="w-20 h-20" />
    //       )}
    //     </div>
    //   )}
    //   {!showInitialImage && (
    <div className="h-full md:py-2 py-0 md:rounded-3xl rounded-none">
      <img
        src={type?.sections[0]?.inputs[0]?.value || images[socialData?.image]}
        alt="banner"
        className="w-full md:h-36 h-60 md:rounded-t-3xl rounded-none object-cover"
      />
      <div
        className="p-5 text-white md:py-6 py-10"
        style={{ backgroundColor: primaryColor }}
      >
        <div
          className={`flex justify-between items-center ${
            type?.sections[1]?.inputs[0]?.value && "md:mb-2 mb-4"
          } `}
        >
          <p className="md:text-sm text-xl mb-2 font-MontserratBold">
            {type?.sections[1]?.inputs[1]?.value || socialData?.title}
          </p>
          {type?.sections[1]?.inputs[0]?.value && (
            <img
              src={type?.sections[1]?.inputs[0]?.value}
              alt="logo"
              className="md:w-12 md:h-12 w-24 h-24 rounded-full ml-4"
            />
          )}
        </div>
        <p className="md:text-xs text-base mb-2">
          {type?.sections[1]?.inputs[2]?.value || socialData?.description}
        </p>
      </div>

      <div className="pb-4 last:border-b-none">
        {type?.sections[2]?.inputs[0]?.value?.length > 0 &&
          type?.sections[2]?.inputs[0]?.value?.map((link, index) => {
            return (
              <div
                key={index}
                className="flex items-center p-5 border-b border-b-[#BEBEBE] last:border-none"
              >
                <a
                  href={link?.value?.url}
                  target="_blank"
                  className="flex items-center"
                >
                  <img
                    src={social_icons[getSocialType(link?.type)?.icon]}
                    alt="icon"
                    className="lg:w-7 lg:h-7 md:w-7 md:h-7 w-10 h-10"
                  />
                  <div className="flex flex-col ml-4">
                    <p className="md:text-sm text-xl font-MontserratRegular font-400 text-[#031D36] capitalize">
                      {getSocialType(link?.type)?.name}
                    </p>
                    <p className="md:text-xs text-sm font-MontserratRegular  font-400 text-[#7D7D7D]">
                      {link?.value?.text}
                    </p>
                  </div>
                </a>
              </div>
            );
          })}
      </div>

      <div className="pb-4">
        <div
          className="md:text-lg md:w-9 md:h-9 w-16 h-16 text-3xl flex items-center justify-center text-white rounded-full shadow-lg shadow-[rgba(0,0,0,0.24)] bg-[#e92163] ml-auto mr-4 -mt-4 md:relative fixed md:bottom-auto md:right-auto bottom-10 right-2"
          onClick={() => handleShare()}
        >
          <RiShareFill />
        </div>
      </div>
    </div>
    //   )}
    // </>
  );
};

export default SocialPreview;
