import React from "react";

const UrlPreview = () => {
  return (
    <div className="bg-[#F5F5F5] rounded-3xl h-full flex flex-col p-4 justify-center">
      <h1 className="text-base font-MontserratBold mb-6 text-center">
        Select Country
      </h1>
      <div className="flex flex-col">
        <div className="w-full rounded-full text-xs mb-3 bg-[#E6E6E6] py-3">
          <p className="text-center">Italy</p>
        </div>
        <div className="w-full rounded-full text-xs mb-3 bg-[#E6E6E6] py-3">
          <p className="text-center">Australia</p>
        </div>
        <div className="w-full rounded-full text-xs mb-3 bg-[#E6E6E6] py-3">
          <p className="text-center">Brazil</p>
        </div>
        <div className="w-full rounded-full text-xs mb-3 bg-[#E6E6E6] py-3">
          <p className="text-center">Spain</p>
        </div>
      </div>
    </div>
  );
};

export default UrlPreview;
