import React from "react";
import { Modal, Button, Image } from "antd";
import { ExclamationCircleOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ThemeButton } from "../../ui/ui";
import { profile_icon } from "../../assets/icons/icons";

const LoginWarnModal = ({ open, onClose }) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      centered={true}
      title={
        <div className="flex items-center">
          <ExclamationCircleOutlined className="text-red-500 text-2xl mr-2" />
          <span className="text-xl font-bold">Login Warning</span>
        </div>
      }
      onCancel={onClose}
      footer={[
        <div className="flex items-center justify-end">
          <Button key="cancel" onClick={onClose} className="h-9">
            Cancel
          </Button>
          <ThemeButton
            content={<p>Login</p>}
            onClick={() => navigate("/login")}
          />
        </div>,
      ]}
    >
      <div className="flex items-center justify-center my-4 mt-6">
        <img src={profile_icon} alt="user" width={50} height={50} />
        <span className="ml-2">You are not logged in.</span>
      </div>
      <p className="text-center mb-6">
        Please login to access exclusive features and personalize your
        experience.
      </p>
    </Modal>
  );
};

export default LoginWarnModal;
