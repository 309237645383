import { ConfigProvider } from "antd";
import AppRouter from "./config/router";
import Montserrat from "./assets/fonts/Roboto-Regular.ttf";
import { store } from "./store/store";
import { Provider } from "react-redux";
import "react-phone-input-2/lib/style.css";
import "react-multi-carousel/lib/styles.css";

function App() {
  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#2965FF",
            fontFamily: Montserrat,
          },
        }}
      >
        <AppRouter />
      </ConfigProvider>
    </Provider>
  );
}

export default App;
