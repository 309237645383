import React from "react";
import { Input } from "antd";

const BadgeInput = ({
  labelPrimary,
  textMd,
  required,
  label,
  onKeyDown,
  name,
  placeholder,
  value,
  onChange,
  disabled,
}) => {
  return (
    <div>
      <p
        className={`${labelPrimary && "text-primary"} ${
          textMd ? "font-medium" : "font-semibold"
        } mb-1 flex items-center text-sm`}
      >
        {required && <p className="mr-1 text-themeRed">*</p>} {label}
      </p>

      <Input
        type={"text"}
        onKeyDown={onKeyDown}
        name={name}
        placeholder={placeholder}
        value={value}
        className="h-14 font-medium text-secondary inputBox-shadow"
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

export default BadgeInput;
