import React from "react";
import { DashboardContainer } from "../../components/components";
import faqs from "../../constants/data/faqs.json";
import { Collapse } from "antd";

const FAQ = () => {
  return (
    <DashboardContainer selected={"4"}>
      <div className="min-h-[calc(100vh-60px)] w-full bg-themeGray-light">
        <div className="w-full container py-6 ">
          <div>
            <h1 className="text-2xl font-MontserratBold font-bold text-secondary">
              FAQ
            </h1>

            <div className="mt-6">
              {faqs?.map((faq, index) => {
                return (
                  <Collapse
                    items={[
                      {
                        key: index,
                        label: <p>{faq?.question}</p>,
                        children: <p>{faq?.answer}</p>,
                      },
                    ]}
                    key={index}
                    className="my-4"
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default FAQ;
