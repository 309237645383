import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { Skeleton, Spin, message } from "antd";
import {
  SectionHeader,
  Section,
  Preview,
  QrCustomization,
  Container,
  QrSubmitModal,
} from "../../components/components";
import { getUserInfo, uploadImage } from "../../helpers/helpers";
import { useLazyGetQrTypeByIDQuery } from "../../services/qrTypes";
import {
  useAddQrCodeMutation,
  useLazyGetQrCodeByIdQuery,
  usePutQrCodeMutation,
} from "../../services/qrCode";
import { right_icon } from "../../assets/icons/icons";
import { useGetUserQuery } from "../../services/auth";

const TypeDetail = () => {
  const navigate = useNavigate();
  const { type, id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const step = searchParams.get("step");
  const [getQrTypeByID, { isLoading }] = useLazyGetQrTypeByIDQuery();
  const [getQrCode, { isLoading: fetchingQr }] = useLazyGetQrCodeByIdQuery();
  const [addQrCode, { isLoading: creating }] = useAddQrCodeMutation();
  const [updateQrCode, { isLoading: customizing }] = usePutQrCodeMutation();
  const [creatingQrLoader, setQrCreatingLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedType, setSelectedType] = useState({});
  const [selectedSection, setSelectedSection] = useState("1");
  const [clientWindowHeight, setClientWindowHeight] = useState("");
  const [totalSteps, setTotalSteps] = useState("2");
  const [currentStep, setCurrentStep] = useState(step || "1");
  const [qrValue, setQrValue] = useState("");
  const [qrID, setQrID] = useState("");

  const [qrCustomizationData, setQrCustomizationData] = useState({
    template: "Default",
    eyeBall: {
      type: "square",
      color: "#000000",
      eyeFrame: "square",
      eyeFrameColor: "#000000",
    },
    logo: {
      size: 5,
    },
    pattern: {
      type: "square",
      color: {
        type: "solid",
        color: ["#000000", "#000000"],
      },
    },
    background: {
      type: "transparent",
      color: ["#ffffff", "#000000"],
      isTransparent: true,
    },
    frame: {
      color: "#000000",
      textColor: "#ffffff",
    },
    settings: {
      isPasswordProtected: false,
    },
  });
  const [qrLabel, setQrLabel] = useState("");
  const [qrFolder, setQrFolder] = useState("");

  useEffect(() => {
    fetchQrType();
  }, [type]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const fetchQrType = async () => {
    if (id === "create") {
      const response = await getQrTypeByID(type);
      setSelectedType({ ...response?.data?.data } || {});
    } else {
      const response = await getQrCode(id);
      if (response?.data?.status?.code === 200) {
        let data = response.data?.data;
        setSelectedType({
          sections: [...data?.qrinsightdata],
          createdAt: data?.createdAt,
          type: data?.qrCodeTypeDetail?.type,
          userId: data?.userId,
          _id: data?._id,
        });
        setCurrentStep(1);
        setQrCustomizationData(data?.customization);
        setQrID(data?._id);
        setQrValue(data?.urlId);
        setQrLabel(data?.label);
        setQrFolder(data?.folder?._id);
      }
    }
  };

  const onChangeStep = (key) => {
    setSearchParams({ step: key });
    setCurrentStep(key);
  };

  const handleScroll = () => {
    setClientWindowHeight(window.scrollY);
  };

  const onChangeSection = (section, i) => {
    let sections = [...selectedType?.sections];
    console.log("sestions", sections);
    sections[i] = section;
    setSelectedType({ ...selectedType, sections: sections });
  };

  const validateForm = () => {
    let sections = [...selectedType?.sections];
    let isValidated = true;

    sections?.map((section, i) => {
      section?.inputs?.map((input, inputIndex) => {
        console.log("input", input);
        if (input?.required) {
          console.log("in", input);
          if (!input?.value || input?.value?.length < 1) {
            isValidated = false;
            return false;
          }
        }
      });
    });

    sections?.map((section, i) => {
      let inputs = [...sections[i]?.inputs];
      section?.inputs?.map((input, inputIndex) => {
        if (input?.required) {
          if (input?.type === "productInput") {
            if (!input?.value?.length) {
              inputs[inputIndex] = {
                ...input,
                errorMessage: `At least one category is required!`,
              };
            }
          }
          if (input?.type === "multiImage") {
            if (!input?.value?.length) {
              inputs[inputIndex] = {
                ...input,
                errorMessage: `At least one image is required!`,
              };
            }
          }
          if (input?.type === "social") {
            let socials = Array.isArray(input?.value) ? [...input?.value] : [];
            if (socials?.length) {
              socials?.map((social, socialIndex) => {
                if (!social?.value) {
                  socials[socialIndex] = {
                    ...socials[socialIndex],
                    errorMessage: `${social?.type} url is required!`,
                  };
                }
              });
              inputs[inputIndex] = { ...input, value: socials };
            } else {
              inputs[inputIndex] = {
                ...input,
                errorMessage: `At least one social link is required!`,
              };
            }
          } else {
            if (!input?.value) {
              inputs[inputIndex] = {
                ...input,
                errorMessage: `${input?.label} is required!`,
              };
            }
          }
        }
      });
      sections[i] = { ...sections[i], inputs: inputs };
    });
    console.log("sections", sections);
    setSelectedType({ ...selectedType, sections });

    return isValidated;
  };

  const creatingQr = async () => {
    setQrCreatingLoader(true);
    let isValidated = validateForm();
    console.log("isValidated", isValidated);
    if (isValidated) {
      const userId = getUserInfo()?.userId;
      let sections = [...selectedType?.sections];

      console.log("on start function", sections);

      // Create an array of promises
      const imageUploadPromises = sections?.map(async (section, i) => {
        return Promise.all(
          section?.inputs?.map(async (input, j) => {
            if (input?.type === "image" && input?.image) {
              const url = await uploadImage(input?.image);
              sections[i].inputs[j] = { ...input, value: url };
              delete sections[i].inputs[j]?.image;
            } else if (input?.type === "file" && input?.value) {
              const url = await uploadImage(input?.value?.file);
              sections[i].inputs[j] = {
                ...input,
                value: { ...input?.value, file: url },
              };
            } else if (input?.type === "productInput") {
              await Promise.all(
                input?.value?.map(async (category, categoryIndex) => {
                  await Promise.all(
                    category?.products?.map(async (product, productIndex) => {
                      if (product?.imageFile) {
                        const imageUrl = await uploadImage(product?.imageFile);
                        sections[i].inputs[j].value[categoryIndex].products[
                          productIndex
                        ] = { ...product, image: imageUrl };
                        delete sections[i].inputs[j].value[categoryIndex]
                          .products[productIndex]?.imageFile;
                      }
                    })
                  );
                })
              );
            } else if (input?.type === "multiImage") {
              await Promise.all(
                input?.value?.map(async (value, valueIndex) => {
                  if (value?.image) {
                    const imageUrl = await uploadImage(value?.image);
                    sections[i].inputs[j].value[valueIndex] = {
                      ...value,
                      url: imageUrl,
                    };
                    delete sections[i].inputs[j].value[valueIndex]?.image;
                  }
                })
              );
            }
          })
        );
      });

      // Wait for all the image uploads to complete
      await Promise.all(imageUploadPromises);

      console.log("sections final data ====>", sections);
      if (id === "create") {
        const body = {
          type: type,
          userId: userId,
          qrinsightdata: sections,
        };

        const response = await addQrCode(body);

        console.log("response", response);
        if (response?.data?.status?.code === 201) {
          message.success("Qr Code Create Successfully");
          setQrCreatingLoader(false);
          setQrValue(response?.data?.data?.urlId);
          setQrID(response?.data?.data?._id);
          onChangeStep(2);
        } else {
          setQrCreatingLoader(false);
          message.error(
            response?.error?.data?.error || "Something went wrong!"
          );
        }
      } else {
        const body = { qrinsightdata: sections };
        const response = await updateQrCode({ id: qrID, body: body });
        if (response?.data?.status?.code === 200) {
          message.success("Qr Code Update Successfully");
          setQrCreatingLoader(false);
          onChangeStep(2);
          setQrCustomizationData(response?.data?.data?.customization);
          setQrValue(response?.data?.data?.urlId);
        } else {
          setQrCreatingLoader(false);
          message.error(
            response?.error?.data?.error || "Something went wrong!"
          );
        }
      }
    } else {
      setQrCreatingLoader(false);
    }
  };

  const onHandleCustomizeQr = async () => {
    let customizationData = { ...qrCustomizationData };

    if (qrLabel && qrFolder) {
      if (customizationData?.logo?.imageFile) {
        const url = await uploadImage(customizationData.logo?.imageFile);
        customizationData = {
          ...customizationData,
          logo: { ...customizationData?.logo, image: url },
        };
        delete customizationData.logo?.imageFile;
      }

      let body = {
        customization: customizationData,
        label: qrLabel,
        folderId: qrFolder,
      };
      const response = await updateQrCode({ id: qrID, body });
      if (response?.data?.status?.code === 200) {
        message.success("Qr Appearance Done Successfully");
        setIsOpen(false);
        setQrLabel("");
        setQrFolder("");
        navigate("/dashboard");
      }
      console.log("response", response);
    } else {
      if (!qrLabel) {
        message.error("Label is required!");
      } else if (!qrFolder) {
        message.error("Folder is required!");
      } else {
        message.success("Something went wrong!");
      }
    }
  };

  return (
    <Container>
      <div className="w-full mx-auto bg-themeGray-medium relative">
        <div
          className={`container mx-auto flex w-full relative min-h-screen ${
            clientWindowHeight >= 70 && currentStep === 1 && "pt-28"
          }`}
        >
          {isLoading || fetchingQr ? (
            <Skeleton className="mt-4" />
          ) : (
            <div className="flex w-full relative">
              <div className="my-10 md:px-8 px-2 flex-flex-col xl:w-8/12 lg:w-[65%]  w-full relative">
                <SectionHeader
                  type={selectedType}
                  selected={selectedSection}
                  setSelected={(e) => {
                    setSelectedSection(e);
                  }}
                  isOverflow={clientWindowHeight}
                  // scrollToSection={scrollToSection}
                  totalSteps={totalSteps}
                  currentStep={currentStep}
                  onChangeStep={onChangeStep}
                />
                {currentStep == 1 && (
                  <div
                    className={`${clientWindowHeight >= 70 ? "pt-24" : "pt-0"}`}
                  >
                    <div className="mt-6 transition-all">
                      {selectedType?.sections?.map((section, index) => {
                        return (
                          <Section
                            key={index}
                            section={{ ...section }}
                            onChangeSection={(e, i) => onChangeSection(e, i)}
                            index={index}
                          />
                        );
                      })}
                    </div>
                    <button
                      className="flex items-center bg-primary text-white px-4 py-2 rounded-md hover:bg-secondary outline-none mx-auto my-12 w-[17rem] justify-center"
                      onClick={creatingQr}
                    >
                      {creatingQrLoader ? (
                        <Spin className="loader-white" />
                      ) : (
                        <span className="flex items-center">
                          <p>Customize & Download QR</p>
                          <img src={right_icon} alt="icon" className="ml-2" />
                        </span>
                      )}
                    </button>
                  </div>
                )}
                {currentStep == 2 && (
                  <div className="mt-6">
                    <QrCustomization
                      formData={qrCustomizationData}
                      setFormData={(e) => setQrCustomizationData(e)}
                    />
                  </div>
                )}
              </div>
              <Preview
                type={selectedType}
                clientWindowHeight={clientWindowHeight}
                currentStep={currentStep}
                qrValue={qrValue}
                formData={qrCustomizationData}
                onSubmit={() => setIsOpen(true)}
              />
            </div>
          )}
        </div>
        <QrSubmitModal
          open={isOpen}
          onClose={() => setIsOpen(false)}
          qrLabel={qrLabel}
          setQrLabel={(e) => setQrLabel(e)}
          qrFolder={qrFolder}
          setQrFolder={(e) => setQrFolder(e)}
          onSubmit={onHandleCustomizeQr}
          submitting={customizing}
        />
      </div>
    </Container>
  );
};

export default TypeDetail;
