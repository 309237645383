import React, { useEffect, useState, useRef } from "react";
import QRCodeStyling from "qr-code-styling";
import MenuPreview from "./MenuPreview";
import CouponPreview from "./CouponPreview";
import AppStorePreview from "./AppstorePreview";
import VcardPreview from "./VcardPreview";
import ProductPreview from "./ProductPreview";
import BioPreview from "./BioPreview";
import LeadGenerationPreview from "./LeadGenerationPreview";
import EventPreview from "./EventPreview";
import RatingPreview from "./RatingPreview";
import ReviewPreview from "./ReviewPreview";
import PDFPreview from "./PDFPreview";
import UrlPreview from "./UrlPreview";
import StaticPreview from "./StaticPreview";
import LocationPreview from "./locationPreview";
import { ThemeButton } from "../../ui/ui";
import { preview_hero } from "../../assets/images/images";
import * as socail_icons from "../../assets/icons/socials/socials";
import ListPreview from "./ListPreview";
import SocialPreview from "./SocialPreview";

const Preview = ({
  type,
  clientWindowHeight,
  currentStep,
  qrValue,
  formData,
  onSubmit,
}) => {
  const ref = useRef(null);

  const [qrOptions, setQrOptions] = useState({
    width: 150,
    height: 150,
    data: qrValue,
    backgroundOptions: {
      color: "#E3E8EA",
    },
    cornersDotOptions: {
      color: "#000000",
      type: "extra-rounded",
    },
    cornersSquareOptions: {
      color: "#000000",
    },
    dotsOptions: {
      type: "square",
      color: "#000000",
    },
  });

  const qrCode = new QRCodeStyling(qrOptions);

  useEffect(() => {
    let styles = {
      width: 150,
      height: 150,
      data: qrValue,
      backgroundOptions: {
        color: !formData?.background?.isTransparent
          ? formData?.background?.type === "solid" &&
            formData?.background?.color[0]
          : "transparent",
        gradient: {
          type: "linear",
          rotation: 0,
          colorStops: [
            {
              offset: 0,
              color: formData?.background?.color[0],
            },
            {
              offset: 1,
              color: formData?.backgroundColor?.color[1],
            },
          ],
        },
      },
      cornersDotOptions: {
        type: formData?.eyeBall?.type,
        color: formData?.eyeBall?.color,
      },
      cornersSquareOptions: {
        type: formData?.eyeBall?.eyeFrame,
        color: formData?.eyeBall?.eyeFrameColor,
      },
      dotsOptions: {
        type: formData?.pattern?.type,
        color: Array.isArray(formData?.pattern?.color?.color)
          ? formData?.pattern?.color?.color[0]
          : formData?.pattern?.color[0],
        gradient: {
          type: "linear",
          rotation: 0,
          colorStops: [
            {
              offset: 0,
              color: Array.isArray(formData?.pattern?.color?.color)
                ? formData?.pattern?.color?.color[0]
                : formData?.pattern?.color[0],
            },
            {
              offset: 1,
              color: Array.isArray(formData?.pattern?.color?.color)
                ? formData?.pattern?.color?.color[1]
                : formData?.pattern?.color[1],
            },
          ],
        },
      },
      image: formData?.logo?.image || socail_icons[formData?.logo?.logo],
      imageOptions: {
        hideBackgroundDots: formData?.logo?.isWhite,
        imageSize: formData?.logo?.size / 10,
        margin: 2,
      },
    };
    (formData?.background?.type !== "gradient" || formData?.frame?.type) &&
      delete styles?.backgroundOptions?.gradient;
    formData?.pattern?.color?.type === "solid" &&
      delete styles?.dotsOptions?.gradient;

    setQrOptions(styles);
  }, [qrValue, formData]);

  useEffect(() => {
    if (currentStep == 2) {
      const qrContainer = document.getElementById("qr-container");
      if (qrContainer) {
        qrContainer.innerHTML = "";

        qrCode.append(ref.current);
      }
    }
  }, [qrOptions, formData]);

  return (
    <div
      className={`bg-themeGray-dark pt-10 px-4 lg:flex flex-col items-center hidden fixed ${
        type?.sections?.length > 1 && currentStep == 1
          ? clientWindowHeight > 70
            ? "h-[calc(100vh-108px)] top-[108px]"
            : "h-[calc(100vh-60px)] top-[60px]"
          : "h-[calc(100vh-60px)] top-[60px]"
      } right-[10%] min-w-[288px]`}
    >
      <div className="bg-white rounded-3xl p-3 h-[500px] w-64  inputBox-shadow z-20">
        {currentStep == 1 && (
          <div className="h-full overflow-y-auto overflow-x-hidden preview-scroll rounded-3xl">
            {type?.type === "menu" && <MenuPreview type={type} />}
            {type?.type === "coupon" && <CouponPreview type={type} />}
            {type?.type === "appstore" && <AppStorePreview type={type} />}
            {type?.type === "vcard" && <VcardPreview type={type} />}
            {type?.type === "product" && <ProductPreview type={type} />}
            {type?.type === "bio" && <BioPreview type={type} />}
            {type?.type === "lead" && <LeadGenerationPreview type={type} />}
            {type?.type === "event" && <EventPreview type={type} />}
            {type?.type === "rating" && <RatingPreview type={type} />}
            {type?.type === "review" && <ReviewPreview type={type} />}
            {type?.type === "pdf" && <PDFPreview type={type} />}
            {type?.type === "url" && <UrlPreview type={type} />}
            {type?.type === "listoflinks" && <ListPreview type={type} />}
            {type?.type === "maps" && <LocationPreview type={type} />}
            {type?.type === "socials" && <SocialPreview type={type} />}

            {(type?.type === "email" ||
              type?.type === "wifi" ||
              type?.type === "call" ||
              type?.type === "whatsapp" ||
              type?.type === "link") && <StaticPreview />}
          </div>
        )}
        {currentStep == 2 && (
          <div className="h-full w-full rounded-3xl bg-[#ECECEC] flex items-center justify-center">
            <div className="flex flex-col items-center justify-center">
              {formData?.frame?.type ? (
                <div id="qr-main">
                  {formData?.frame?.type === "frame1" && (
                    <div className="flex-col w-full justify-center items-center">
                      <div
                        className="p-4 border-8 rounded-lg mb-1"
                        style={{
                          borderColor: formData?.frame?.color,
                          background: formData?.background?.isTransparent
                            ? "transparent"
                            : formData?.background?.type === "solid"
                            ? formData?.background?.color[0]
                            : formData?.background?.type === "gradient" &&
                              `linear-gradient(to right, ${formData?.background?.color[0]}, ${formData?.background?.color[1]})`,
                        }}
                      >
                        <div
                          ref={ref}
                          id="qr-container"
                          className={`${
                            formData?.template === "Circular" &&
                            "rounded-full border-4 border-secondary p-8"
                          }`}
                        />
                      </div>
                      <div
                        className="rounded-lg p-1 py-3"
                        style={{
                          backgroundColor: formData?.frame?.color,
                        }}
                      >
                        <p
                          className="text-sm text-center"
                          style={{
                            color: formData?.frame?.textColor,
                          }}
                        >
                          {formData?.frame?.text}
                        </p>
                      </div>
                    </div>
                  )}
                  {formData?.frame?.type === "frame2" && (
                    <div className="flex-col w-full justify-center items-center">
                      <div
                        className="border-8 rounded-lg mb-1"
                        style={{
                          borderColor: formData?.frame?.color,
                          background: formData?.background?.isTransparent
                            ? "transparent"
                            : formData?.background?.type === "solid"
                            ? formData?.background?.color[0]
                            : formData?.background?.type === "gradient" &&
                              `linear-gradient(to right, ${formData?.background?.color[0]}, ${formData?.background?.color[1]})`,
                        }}
                      >
                        <div
                          ref={ref}
                          id="qr-container"
                          className={`p-4 ${
                            formData?.template === "Circular" &&
                            "rounded-full border-4 border-secondary p-8"
                          }`}
                        />
                        <div
                          className="p-1 py-3 mt-2"
                          style={{
                            backgroundColor: formData?.frame?.color,
                          }}
                        >
                          <p
                            className="text-sm text-center"
                            style={{
                              color: formData?.frame?.textColor,
                            }}
                          >
                            {formData?.frame?.text}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {formData?.frame?.type === "frame3" && (
                    <div className="flex-col w-full justify-center items-center">
                      <div
                        className="border-8 rounded-lg mb-1"
                        style={{
                          borderColor: formData?.frame?.color,
                          background: formData?.background?.isTransparent
                            ? "transparent"
                            : formData?.background?.type === "solid"
                            ? formData?.background?.color[0]
                            : formData?.background?.type === "gradient" &&
                              `linear-gradient(to right, ${formData?.background?.color[0]}, ${formData?.background?.color[1]})`,
                        }}
                      >
                        <div
                          ref={ref}
                          id="qr-container"
                          className={`p-4 ${
                            formData?.template === "Circular" &&
                            "rounded-full border-4 border-secondary p-8"
                          }`}
                        />
                        <div className="bg-transparent p-1 py-3 mt-2">
                          <p
                            className="text-sm text-center"
                            style={{
                              color: formData?.frame?.textColor,
                            }}
                          >
                            {formData?.frame?.text}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {formData?.frame?.type === "frame4" && (
                    <div className="flex-col w-full justify-center items-center">
                      <div
                        className="mb-1"
                        style={{
                          background: formData?.background?.isTransparent
                            ? "transparent"
                            : formData?.background?.type === "solid"
                            ? formData?.background?.color[0]
                            : formData?.background?.type === "gradient" &&
                              `linear-gradient(to right, ${formData?.background?.color[0]}, ${formData?.background?.color[1]})`,
                        }}
                      >
                        <div
                          ref={ref}
                          id="qr-container"
                          className={`${
                            formData?.template === "Circular" &&
                            "rounded-full border-4 border-secondary p-8"
                          }`}
                        />
                        <div className="bg-transparent p-1 py-3 mt-2">
                          <p
                            className="text-sm text-center"
                            style={{
                              color: formData?.frame?.textColor,
                            }}
                          >
                            {formData?.frame?.text}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {formData?.frame?.type === "frame5" && (
                    <div className="flex-col w-full justify-center items-center">
                      <div
                        className="border-8 rounded-lg mb-1"
                        style={{
                          borderColor: formData?.frame?.color,
                          background: formData?.background?.isTransparent
                            ? "transparent"
                            : formData?.background?.type === "solid"
                            ? formData?.background?.color[0]
                            : formData?.background?.type === "gradient" &&
                              `linear-gradient(to right, ${formData?.background?.color[0]}, ${formData?.background?.color[1]})`,
                        }}
                      >
                        <div
                          ref={ref}
                          id="qr-container"
                          className={`p-4 ${
                            formData?.template === "Circular" &&
                            "rounded-full border-4 border-secondary p-8"
                          }`}
                        />
                        <div
                          className="border-t-8 bg-transparent p-1 py-3 mt-2"
                          style={{
                            borderColor: formData?.frame?.color,
                          }}
                        >
                          <p
                            className="text-sm text-center"
                            style={{
                              color: formData?.frame?.textColor,
                            }}
                          >
                            {formData?.frame?.text}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div id="qr-main">
                  <div
                    ref={ref}
                    id="qr-container"
                    className={`${
                      formData?.template === "Circular" &&
                      "rounded-full border-4 border-secondary p-8"
                    }`}
                  />
                </div>
              )}

              <ThemeButton
                content={<p className="text-sm">NEXT</p>}
                className={"w-32 !rounded mt-20"}
                onClick={() => onSubmit()}
              />
            </div>
          </div>
        )}
      </div>
      <img src={preview_hero} className="-mt-[15rem] z-10" />
    </div>
  );
};

export default Preview;
