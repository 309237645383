import React, { useState } from "react";
import { ImageSlider } from "../components";
import { ThemeRating, ThemeButton } from "../../ui/ui";
import { getSocialType } from "../../helpers/helpers";
import { star_fill2_icon, star_gray_icon } from "../../assets/icons/icons";
import * as social_icons from "../../assets/icons/socials/socials";
import { Carousel } from "antd";

const ProductPreview = ({ type }) => {
  const primaryColor = type?.sections[3]?.inputs[0]?.value[0];
  const buttonColor = type?.sections[3]?.inputs[0]?.value[1];
  const headingColor = type?.sections[3]?.inputs[0]?.value[2];
  const productData = {
    name: "Keyboard",
    breadcrumb: "Keyboards/Mechanical Keyboards",
    image: [{ url: "/images/keyboard.svg" }],
    price: 40.99,
    rating: 4,
    detail:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    specification: "Unit   Lorem Ipsum is simply dummy",
    email: "loremipsum@gmail.com",
    phone: "0123456789",
    linkBtn: "Order now",
    socialLinks: [
      { icon: "/icons/socials/facebook.svg" },
      { icon: "/icons/socials/instagram.svg" },
      { icon: "/icons/socials/twitter.svg" },
      { icon: "/icons/socials/youtube.svg" },
    ],
  };

  const [selectedTab, setSelectedTab] = useState(1);
  return (
    <div className="h-full">
      <div className="bg-[#F5F5F5] h-full md:rounded-3xl rounded-none py-6 px-4">
        <p className="font-semibold md:text-base text-2xl font-MontserratBold">
          {type?.sections[0]?.inputs[1]?.value || productData?.name}
        </p>
        <p
          className="md:text-[8px] text-sm -mt-1"
          style={{ color: headingColor }}
        >
          {type?.sections[0]?.inputs[2]?.value}
        </p>
        <div className="my-4">
          <Carousel autoplay dots={true}>
            {type?.sections[0]?.inputs[0]?.value[0]?.url &&
              type?.sections[0]?.inputs[0]?.value?.map((image, index) => {
                if (image?.url) {
                  return (
                    <img
                      key={index}
                      src={image?.url}
                      alt="image"
                      className="w-full md:h-32 h-60 object-contain mix-blend-multiply"
                    />
                  );
                }
              })}
          </Carousel>
        </div>
        <p className="font-semibold md:text-xl text-2xl">
          $ {type?.sections[0]?.inputs[3]?.value || productData?.price}
        </p>
        <ThemeRating
          empty={star_gray_icon}
          full={star_fill2_icon}
          value={type?.sections[0]?.inputs[4]?.value || productData?.rating}
          readonly
          resolation={"md:w-auto md:h-auto w-8 h-8"}
        />
        <div className="flex md:text-[11px] tex-lg font-semibold items-center my-6">
          <div className="flex flex-col">
            <span
              className={`px-1 cursor-pointer`}
              style={{ color: headingColor }}
              onClick={() => setSelectedTab(1)}
            >
              Details
            </span>
            {selectedTab === 1 && (
              <div
                className="h-[1px] w-full"
                style={{ backgroundColor: primaryColor }}
              ></div>
            )}
          </div>
          <div className="flex flex-col ml-2">
            <span
              className={`px-1 cursor-pointer`}
              style={{ color: headingColor }}
              onClick={() => setSelectedTab(2)}
            >
              Specifications
            </span>
            {selectedTab === 2 && (
              <div
                className="h-[1px] w-full"
                style={{ backgroundColor: primaryColor }}
              ></div>
            )}
          </div>
        </div>

        <div className={`mb-6 ${selectedTab === 1 ? "block" : "hidden"}`}>
          <div
            className="text-[#7D7D7D] md:text-xs text-base"
            dangerouslySetInnerHTML={{
              __html:
                type?.sections[0]?.inputs[5]?.value || productData?.detail,
            }}
          />
          {/* <p className="text-#7D7D7D md:text-xss text-base>
            {type?.sections[0]?.inputs[5]?.value || productData?.detail}
          </p> */}
        </div>
        <div className={`mb-6 ${selectedTab === 2 ? "block" : "hidden"}`}>
          <div
            className="text-[#7D7D7D] md:text-xs text-base"
            dangerouslySetInnerHTML={{
              __html:
                type?.sections[0]?.inputs[6]?.value || productData?.detail,
            }}
          />
          {/* <p className="text-#7D7D7D text-xs">
            {type?.sections[0]?.inputs[6]?.value || productData?.specification}
          </p> */}
        </div>

        {/* <ul className="mb-6">
          <li>
            <p className="text-#7D7D7D text-xs mb-3 flex items-start">
              <span className="p-[5px] rounded-full bg-primary w-2 h-2 mr-2 mt-1"></span>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </li>
        </ul> */}

        {type?.sections[0]?.inputs[7]?.value && (
          <a href={type?.sections[0]?.inputs[7]?.value[1]} target="_blank">
            <ThemeButton
              className="md:!text-xs !text-base !rounded w-full mb-8 md:!h-9 !h-12"
              style={{ backgroundColor: buttonColor }}
              content={<p>{type?.sections[0]?.inputs[7]?.value[0]}</p>}
            />
          </a>
        )}
        <p className="md:text-base text-xl font-semibold mb-3">Contact</p>
        <div className="mb-4">
          <p
            className="md:text-sm text-lg font-semibold mb-1"
            style={{ color: headingColor }}
          >
            Email
          </p>
          <p className="text-[#7D7D7D] md:text-xs text-base">
            {type?.sections[1]?.inputs[1]?.value || productData?.email}
          </p>
        </div>
        <div className="mb-6">
          <p
            className="md:text-sm text-lg font-semibold mb-1"
            style={{ color: headingColor }}
          >
            Phone
          </p>
          <p className="text-[#7D7D7D] md:text-xs text-base">
            {type?.sections[1]?.inputs[0]?.value || productData?.phone}
          </p>
        </div>

        <p className="md:text-base text-xl font-semibold mb-3">Social</p>
        <div className="flex items-center mb-6 flex-wrap">
          {type?.sections[2]?.inputs[0]?.value?.map((link, index) => {
            return (
              <div className="flex items-center p-2" key={index}>
                <a
                  href={link?.value}
                  target="_blank"
                  className="cursor-pointer"
                >
                  <img
                    src={social_icons[getSocialType(link?.type)?.icon]}
                    alt="icon"
                    className="lg:w-7 lg:h-7 md:w-7 md:h-7 w-10 h-10"
                  />
                </a>
              </div>
            );
          })}
        </div>
        {/* <div className="flex mb-6 flex-wrap items-center">
          {type?.sections[1]?.inputs[0]?.value?.length > 0
            ? type?.sections[1]?.inputs[0]?.value?.map((link, index) => {
                return (
                  <span className="p-1" key={index}>
                    <img
                      key={index}
                      src={getSocialType(link?.type)?.icon}
                      alt="social-icon"
                      className="w-8 h-8"
                    />
                  </span>
                );
              })
            : productData.socialLinks?.map((link, index) => {
                return (
                  <span className="p-1" key={index}>
                    <img
                      key={index}
                      src={link?.icon}
                      alt="social-icon"
                      className="w-8 h-8"
                    />
                  </span>
                );
              })}
        </div> */}
      </div>
    </div>
  );
};

export default ProductPreview;
