import { Button } from "antd";

const ThemeButton = ({
  content,
  className,
  onClick,
  bgColor,
  textMd,
  style,
  ...props
}) => {
  return (
    <Button
      onClick={onClick}
      type="primary"
      className={`${className} ${bgColor ? bgColor : "bg-primary"} h-9 ${
        textMd ? "font-medium" : "font-semibold"
      } flex justify-center items-center`}
      style={style}
      {...props}
    >
      {content}
    </Button>
  );
};

export default ThemeButton;
