import React from "react";
import { Row, Col } from "antd";
import { ThemeInput } from "../../ui/ui";

const LinkButton = ({
  label,
  placeholder,
  value,
  onChange,
  extra,
  onRemoveButton,
}) => {
  const onChangeInput = (e, i) => {
    let values = value?.length > 0 ? value : [];
    values[i] = e;
    onChange(values);
  };

  return (
    <div>
      <p className="text-primary font-semibold text-sm mb-2">{label}</p>
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 20 }}>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <ThemeInput
            type={"text"}
            placeholder={placeholder?.length > 0 ? placeholder[0] : ""}
            value={value?.length > 0 ? value[0] : ""}
            onChange={(e) => onChangeInput(e.target.value, 0)}
            className={"md:mb-0 mb-4"}
          />
        </Col>
        <Col xl={16} lg={16} md={16} sm={24} xs={24}>
          <ThemeInput
            type={"text"}
            placeholder={placeholder?.length > 0 ? placeholder[1] : ""}
            value={value?.length > 0 ? value[1] : ""}
            onChange={(e) => onChangeInput(e.target.value, 1)}
            className={"md:mb-0 mb-4"}
          />
        </Col>
      </Row>
      {extra && (
        <p className="text-xs text-right font-semibold text-primary underline cursor-pointer mt-2">
          <span onClick={() => onRemoveButton()}>{extra}</span>
        </p>
      )}
    </div>
  );
};

export default LinkButton;
