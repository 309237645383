import React from "react";
import { ThemeColorPicker } from "../../ui/ui";

const EyesSection = ({ formData, setFormData }) => {
  return (
    <div>
      <div className="mb-10">
        <div className="mb-8">
          <h1 className="text-secondary text-lg font-bold mb-1">
            SELECT EYE-BALL
          </h1>
          <p className="text-xs text-themeGray-200 mb-3">
            This will be the color and shape of your QR code's eye-balls
          </p>
        </div>

        <div className="mb-8">
          <p className="text-sm font-bold text-primary mb-2">Eye-ball color</p>
          <ThemeColorPicker
            value={formData?.eyeBall?.color}
            onChange={(e) =>
              setFormData({
                ...formData,
                eyeBall: { ...formData?.eyeBall, color: e.toHexString() },
              })
            }
          />
          <div className="flex items-center mt-4 -ml-2">
            <div className="p-2">
              <div
                className="p-2 qrbox-shadow rounded cursor-pointer border hover:border-primary"
                style={{
                  borderColor:
                    formData?.eyeBall?.type === "square"
                      ? "#2965FF"
                      : "transparent",
                }}
                onClick={() =>
                  setFormData({
                    ...formData,
                    eyeBall: { ...formData?.eyeBall, type: "square" },
                  })
                }
              >
                <div className="w-6 h-6 bg-secondary"></div>
              </div>
            </div>
            <div className="p-2">
              <div
                className="p-2 qrbox-shadow rounded cursor-pointer border hover:border-primary"
                style={{
                  borderColor:
                    formData?.eyeBall?.type === "dot"
                      ? "#2965FF"
                      : "transparent",
                }}
                onClick={() =>
                  setFormData({
                    ...formData,
                    eyeBall: { ...formData?.eyeBall, type: "dot" },
                  })
                }
              >
                <div className="w-6 h-6 bg-secondary rounded-full"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-8">
          <h1 className="text-base font-bold text-primary mb-1">
            SELECT EYE-FRAME
          </h1>
          <p className="text-xs text-themeGray-200 mb-3">
            This will be the color and shape of your QR code's eye-frames
          </p>
        </div>

        <div className="mb-4">
          <p className="text-sm font-bold text-primary mb-2">Eye-frame color</p>
          <ThemeColorPicker
            value={formData?.eyeBall?.eyeFrameColor}
            onChange={(e) =>
              setFormData({
                ...formData,
                eyeBall: {
                  ...formData?.eyeBall,
                  eyeFrameColor: e.toHexString(),
                },
              })
            }
          />
          <div className="flex items-center mt-4 -ml-2">
            <div className="p-2">
              <div
                className="p-2 qrbox-shadow rounded cursor-pointer border hover:border-primar"
                style={{
                  borderColor:
                    formData?.eyeBall?.eyeFrame === "square"
                      ? "#2965FF"
                      : "transparent",
                }}
                onClick={() =>
                  setFormData({
                    ...formData,
                    eyeBall: { ...formData?.eyeBall, eyeFrame: "square" },
                  })
                }
              >
                <div className="w-6 h-6 border-[3px] border-secondary"></div>
              </div>
            </div>
            <div className="p-2">
              <div
                className="p-2 qrbox-shadow rounded cursor-pointer border hover:border-primary"
                style={{
                  borderColor:
                    formData?.eyeBall?.eyeFrame === "dot"
                      ? "#2965FF"
                      : "transparent",
                }}
                onClick={() =>
                  setFormData({
                    ...formData,
                    eyeBall: { ...formData?.eyeBall, eyeFrame: "dot" },
                  })
                }
              >
                <div className="w-6 h-6 border-[3px] border-secondary rounded-full"></div>
              </div>
            </div>
            <div className="p-2">
              <div
                className="p-2 qrbox-shadow rounded cursor-pointer border hover:border-primary"
                style={{
                  borderColor:
                    formData?.eyeBall?.eyeFrame === "extra-rounded"
                      ? "#2965FF"
                      : "transparent",
                }}
                onClick={() =>
                  setFormData({
                    ...formData,
                    eyeBall: {
                      ...formData?.eyeBall,
                      eyeFrame: "extra-rounded",
                    },
                  })
                }
              >
                <div className="w-6 h-6 border-[3px] border-secondary rounded-lg"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EyesSection;
