import React, { useState, useEffect } from "react";
import { MdOutlineFolderOpen } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  add_icon,
  search_icon,
  delete_icon,
  redirect_icon,
  add_blue_icon,
  folder_icon,
  edit_icon,
} from "../../assets/icons/icons";
import {
  Divider,
  Input,
  Spin,
  Pagination,
  Popover,
  Radio,
  Space,
  Empty,
  message,
  Skeleton,
} from "antd";
import { CiEdit } from "react-icons/ci";
import { useGetQrTypesQuery } from "../../services/qrTypes";
import { useNavigate, useParams } from "react-router-dom";
import ThemeButton from "../../ui/ThemeButton";
import {
  useGetQrCodesQuery,
  usePutQrCodeMutation,
} from "../../services/qrCode";
import { PREVIEW_URL } from "../../constants/constants";
import * as social_icons from "../../assets/icons/socials/socials";
import {
  DashboardContainer,
  FoldarModal,
  MoveFolder,
} from "../../components/components";
import dayjs from "dayjs";
import QRCodeStyling from "qr-code-styling";
import ThemeSelect from "../../ui/ThemeSelect";
import {
  useGetFolderByIDQuery,
  usePutFolderMutation,
} from "../../services/folders";
import { configConsumerProps } from "antd/es/config-provider";

const FolderDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [isMoveFoldarOpen, setIsMoveFoldarModalOpen] = useState(false);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [moveFoldarId, setMoveFoldarId] = useState("");
  const [folderModalMode, setFolderModalMode] = useState("edit");
  const [moveFolderMode, setMoveFoldarMode] = useState("move");
  const [moveFormData, setMoveFormData] = useState("");
  const [folderModalData, setFolderModalData] = useState({});
  const [inputKey, setInputKey] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(20);
  const [formData, setFormData] = useState({});
  const [filter, setFilter] = useState({ type: "All Types" });
  const [qrTypesList, setQrTpesList] = useState([]);
  const [qrCodes, setQrCodes] = useState([]);
  const {
    data: folder,
    isLoading: fetchingFolder,
    refetch: refetchFolder,
  } = useGetFolderByIDQuery(id);
  const { data: qrTypes, isLoading: fetchingQRTypes } = useGetQrTypesQuery();
  const { data, isLoading, refetch } = useGetQrCodesQuery({
    page: 1,
    perPage: 100000,
    folderId: id,
    type: filter?.type === "All Types" ? "" : filter?.type,
  });
  const [updateQrCode, { isLoading: updatingQr }] = usePutQrCodeMutation();
  const [updateFolder, { isLoading: updatingFolder }] = usePutFolderMutation();

  useEffect(() => {
    if (searchValue) {
      const filteredQrCodes = data?.data?.qrCodes?.filter((qrCode) => {
        let urlId = qrCode?.urlId?.toLowerCase();
        let qrLabel = qrCode?.label?.toLowerCase();

        if (
          urlId?.includes(searchValue.toLowerCase()) ||
          (qrCode?.label && qrLabel.includes(searchValue.toLowerCase()))
        ) {
          return qrCode;
        }
      });
      setQrCodes(filteredQrCodes);
    } else {
      setQrCodes(data?.data?.qrCodes || []);
    }
  }, [searchValue, data]);

  useEffect(() => {
    qrCodes?.length > 0 &&
      qrCodes.slice(minValue, maxValue)?.map(async (qrCode, index) => {
        let styles = {
          width: 100,
          height: 100,
          data: `${PREVIEW_URL}${qrCode?.urlId}`,
          backgroundOptions: {
            color: !qrCode?.customization?.background?.isTransparent
              ? qrCode?.customization?.background?.type === "solid" &&
                qrCode?.customization?.background?.color[0]
              : "transparent",
            gradient: {
              type: "linear",
              rotation: 0,
              colorStops: [
                {
                  offset: 0,
                  color: qrCode?.customization?.background?.color[0],
                },
                {
                  offset: 1,
                  color: qrCode?.customization?.backgroundColor?.color[1],
                },
              ],
            },
          },
          cornersDotOptions: {
            type: qrCode?.customization?.eyeBall?.type,
            color: qrCode?.customization?.eyeBall?.color,
          },
          cornersSquareOptions: {
            type: qrCode?.customization?.eyeBall?.eyeFrame,
            color: qrCode?.customization?.eyeBall?.eyeFrameColor,
          },
          dotsOptions: {
            type: qrCode?.customization?.pattern?.type,
            color: Array.isArray(qrCode?.customization?.pattern?.color?.color)
              ? qrCode?.customization?.pattern?.color?.color[0]
              : qrCode?.customization?.pattern?.color[0],
            gradient: {
              type: "linear",
              rotation: 0,
              colorStops: [
                {
                  offset: 0,
                  color: Array.isArray(
                    qrCode?.customization?.pattern?.color?.color
                  )
                    ? qrCode?.customization?.pattern?.color?.color[0]
                    : qrCode?.customization?.pattern?.color[0],
                },
                {
                  offset: 1,
                  color: Array.isArray(
                    qrCode?.customization?.pattern?.color?.color
                  )
                    ? qrCode?.customization?.pattern?.color?.color[1]
                    : qrCode?.customization?.pattern?.color[1],
                },
              ],
            },
          },
          image:
            qrCode?.customization?.logo?.image ||
            social_icons[qrCode?.customization?.logo?.logo],
          imageOptions: {
            hideBackgroundDots: qrCode?.customization?.logo?.isWhite,
            imageSize: qrCode?.customization?.logo?.size / 10,
            margin: 2,
          },
        };
        (qrCode?.customization?.background?.type !== "gradient" ||
          qrCode?.customization?.frame?.type) &&
          delete styles?.backgroundOptions?.gradient;
        qrCode?.customization?.pattern?.color?.type === "solid" &&
          delete styles?.dotsOptions?.gradient;

        const qrCodeStylingInstance = new QRCodeStyling(styles);
        const qrCodeContainer = document.getElementById(`qrCode-${index}`);
        qrCodeContainer.innerHTML = null;
        qrCodeStylingInstance.append(qrCodeContainer);
      });
  }, [qrCodes, minValue, maxValue]);

  const setPagination = (page, pageSize) => {
    setMinValue((page - 1) * pageSize);
    setMaxValue(page * pageSize);
    setCurrentPage(page);
  };

  const onDownloadQR = (e, data) => {
    let styles = {
      width: 150,
      height: 150,
      data: `${PREVIEW_URL}${data?.urlId}`,
      backgroundOptions: {
        color: !data?.background?.isTransparent
          ? data?.background?.type === "solid" && data?.background?.color[0]
          : "transparent",
        gradient: {
          type: "linear",
          rotation: 0,
          colorStops: [
            {
              offset: 0,
              color: data?.background?.color[0],
            },
            {
              offset: 1,
              color: data?.backgroundColor?.color[1],
            },
          ],
        },
      },
      cornersDotOptions: {
        type: data?.eyeBall?.type,
        color: data?.eyeBall?.color,
      },
      cornersSquareOptions: {
        type: data?.eyeBall?.eyeFrame,
        color: data?.eyeBall?.eyeFrameColor,
      },
      dotsOptions: {
        type: data?.pattern?.type,
        color: data?.pattern?.color?.color[0],
        gradient: {
          type: "linear",
          rotation: 0,
          colorStops: [
            {
              offset: 0,
              color: data?.pattern?.color?.color[0],
            },
            {
              offset: 1,
              color: data?.pattern?.color?.color[1],
            },
          ],
        },
      },
      image: data?.logo?.image || social_icons[data?.logo?.logo],
      imageOptions: {
        hideBackgroundDots: data?.logo?.isWhite,
        imageSize: data?.logo?.size / 10,
        margin: 2,
      },
    };
    (data?.background?.type !== "gradient" || data?.frame?.type) &&
      delete styles?.backgroundOptions?.gradient;
    data?.pattern?.color?.type === "solid" &&
      delete styles?.dotsOptions?.gradient;

    const qrCode = new QRCodeStyling(styles);

    qrCode.download({ name: `QRInsight-${data?.urlId}`, extension: e });
  };

  const onUpdateLabel = async (id) => {
    const response = await updateQrCode({
      id,
      body: { label: editInputValue },
    });
    if (response?.data?.status?.code === 200) {
      message.success("Label updated successfully");
      setInputKey(-1);
      setEditInputValue("");
      refetch();
    } else {
      message.error(response?.error?.data?.error || "Something went wrong!");
    }
  };

  const handleKeyDown = async (e, id) => {
    if (e.key === "Enter") {
      const response = await updateQrCode({
        id,
        body: { label: editInputValue },
      });
      if (response?.data?.status?.code === 200) {
        message.success("Label updated successfully");
        setInputKey(-1);
        setEditInputValue("");
        refetch();
      } else {
        message.error(response?.error?.data?.error || "Something went wrong!");
      }
    }
  };

  const onCloseMoveFoldar = () => {
    setIsMoveFoldarModalOpen(false);
    setMoveFoldarId("");
    setMoveFormData("");
  };

  const onCloseFolderModal = () => {
    setIsFolderModalOpen(false);
    setFolderModalData({});
  };

  const onMoveFolder = async () => {
    const response = await updateQrCode({
      id: moveFoldarId,
      body: { folderId: moveFormData },
    });
    if (response?.data?.status?.code === 200) {
      message.success(
        moveFolderMode === "move"
          ? "Qr Code Moved Successfully"
          : "Folder Added Successfully"
      );
      onCloseMoveFoldar();
      refetch();
      setMoveFoldarMode("move");
    } else {
      message.error(response?.error?.data?.error || "Something went wrong!");
    }
  };

  const onSubmitFolderModal = async () => {
    const response = await updateFolder({ id, body: folderModalData });
    if (response?.data?.status?.code === 200) {
      onCloseFolderModal();
      refetchFolder();
      message.success("Folder Updated Successfully");
    } else {
      message.error(response?.data?.error || "Something went wrong!");
    }
  };

  useEffect(() => {
    let list = [];
    list.push({ value: "All Types", label: "All Types" });
    qrTypes?.data?.map((type) => {
      list.push({ value: type?._id, label: type?.label });
    });
    setQrTpesList(list);
  }, [qrTypes]);

  return (
    <DashboardContainer selected={"2"}>
      <div className="min-h-[calc(100vh-60px)] w-full bg-themeGray-light">
        {fetchingFolder ? (
          <div className="w-full container py-6">
            <Skeleton />
          </div>
        ) : (
          <div className="w-full container py-6 ">
            <div className="flex items-center justify-between">
              <div className="flex items-start">
                <MdOutlineFolderOpen className="text-primary text-3xl" />
                <div className="flex flex-col items-start ml-2">
                  <h1 className="text-2xl font-MontserratBold font-bold text-primary">
                    {folder?.data?.name}
                  </h1>
                  <span className="text-xs text-[#4D4D4D] font-MontserratRegular font-semibold">
                    Created:{" "}
                    {folder?.data?.createdAt
                      ? dayjs(folder?.createdAt).format("MMM DD, YYYY")
                      : "---"}
                  </span>
                </div>
              </div>
              <div className="flex items-center">
                <div
                  className="rounded-md type-box-shadow w-10 h-10 flex items-center justify-center cursor-pointer"
                  onClick={() => {
                    setIsFolderModalOpen(true);
                    setFolderModalData({ name: folder?.data?.name });
                  }}
                >
                  <img src={edit_icon} alt="edit" />
                </div>
                <div className="rounded-md type-box-shadow w-10 h-10 flex items-center justify-center text-red-600 text-3xl ml-4 cursor-pointer">
                  <RiDeleteBinLine />
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between mt-10">
              <div className="flex items-center">
                <ThemeSelect
                  options={qrTypesList}
                  value={filter?.type}
                  onChange={(e) => setFilter({ ...filter, type: e })}
                  className={"bg-white inputBox-shadow !w-40 filterSelectBox"}
                />
              </div>
              <div className="flex items-center">
                <div className="flex items-end">
                  <div className="flex flex-col">
                    <Input
                      placeholder="Search entry by id or title"
                      className="!h-10 text-sm border-none outline-none shadow-none p-0 bg-transparent focus:outline-none focus:shadow-none placeholder:text-themeGray-200 w-60"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <span className="w-full h-[1.5px] bg-themeGray-100 -mt-1"></span>
                  </div>
                  <img src={search_icon} alt="icon" className="h-6 w-6 ml-3" />
                </div>
                <ThemeButton
                  content={
                    <div className="flex items-center">
                      <img
                        src={add_icon}
                        alt="icon"
                        className="w-[10px] h-[10px]"
                      />
                      <p className="text-xs font-medium text-white ml-2 font-MontserratRegular">
                        CREATE NEW QR
                      </p>
                    </div>
                  }
                  onClick={() => navigate("/")}
                  className="w-40 rounded !h-8 ml-6"
                />
              </div>
            </div>

            <div className="mt-8">
              {isLoading ? (
                <div className="w-full h-96 flex items-center justify-center">
                  <Spin size="large" />
                </div>
              ) : (
                <div>
                  {qrCodes?.length > 0 ? (
                    qrCodes.slice(minValue, maxValue)?.map((qrCode, index) => {
                      return (
                        <div
                          className="w-full bg-white inputBox-shadow rounded mb-8 p-8 px-14"
                          key={index}
                        >
                          <div className="flex items-center">
                            <div className="flex items-center justify-between w-full">
                              <div className="flex items-center">
                                <div className="flex flex-col justify-center">
                                  {/* <img
                                  src={qrDefault_icon}
                                  alt="qrcode"
                                  className="mb-5 h-28 w-28"
                                /> */}
                                  <div
                                    id={`qrCode-${index}`}
                                    className="w-[100] h-[100] flex items-center justify-center"
                                  >
                                    <Spin />
                                  </div>
                                  <ThemeButton
                                    className={
                                      "w-full border-primary rounded-md hover:bg-primary hover:text-white !h-7 mt-4"
                                    }
                                    bgColor={"bg-transparent"}
                                    content={
                                      <p className="text-sm text-primary">
                                        EDIT
                                      </p>
                                    }
                                    onClick={() =>
                                      navigate(
                                        `/type/${qrCode?.qrCodeTypeDetail?._id}/${qrCode?._id}`
                                      )
                                    }
                                  />
                                </div>
                                <div className="ml-16 flex flex-col justify-between h-full">
                                  <div className="mb-6">
                                    <div className="flex items-center">
                                      {inputKey == index ? (
                                        <input
                                          value={editInputValue}
                                          className="text-lg font-MontserratBold text-primary outline-none border-b border-b-primary"
                                          onChange={(e) =>
                                            setEditInputValue(e.target.value)
                                          }
                                          onBlur={() =>
                                            onUpdateLabel(qrCode?._id)
                                          }
                                          onKeyDown={(e) =>
                                            handleKeyDown(e, qrCode?._id)
                                          }
                                          autoFocus
                                        />
                                      ) : (
                                        <h1 className="text-lg text-primary font-MontserratBold">
                                          {qrCode?.label || "UnTitled"}
                                        </h1>
                                      )}

                                      <span
                                        className="text-xl ml-2 text-secondary hover:text-primary cursor-pointer"
                                        onClick={() => {
                                          setInputKey(index);
                                          setEditInputValue(qrCode?.label);
                                        }}
                                      >
                                        <CiEdit />
                                      </span>
                                    </div>
                                    <div className="flex items-center">
                                      <img src={folder_icon} alt="icon" />{" "}
                                      {qrCode?.folder ? (
                                        <p
                                          className="mb-0 text-xs text-themeGray-200 ml-3 cursor-pointer"
                                          onClick={() => {
                                            setIsMoveFoldarModalOpen(true);
                                            setMoveFormData(
                                              qrCode?.folder?._id
                                            );
                                            setMoveFoldarId(qrCode?._id);
                                            setMoveFoldarMode("move");
                                          }}
                                        >
                                          {qrCode?.folder?.name}
                                        </p>
                                      ) : (
                                        <img
                                          src={add_blue_icon}
                                          alt="add"
                                          className="cursor-pointer ml-2"
                                          onClick={() => {
                                            setIsMoveFoldarModalOpen(true);
                                            setMoveFoldarId(qrCode?._id);
                                            setMoveFoldarMode("add");
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>

                                  <ul>
                                    <li className="mb-2 flex items-center">
                                      <p className="text-[#ACACAC] text-xs w-16 font-MontserratRegular">
                                        Link:
                                      </p>

                                      <a
                                        href={`${PREVIEW_URL}${qrCode?.urlId}`}
                                        target="_blank"
                                        className="text-[#393838] text-xs font-MontserratRegular underline cursor-pointer"
                                      >
                                        {`${PREVIEW_URL}${qrCode?.urlId}`}
                                      </a>
                                    </li>
                                    <li className="mb-2 flex items-center">
                                      <p className="text-[#ACACAC] text-xs w-16 font-MontserratRegular">
                                        Created:
                                      </p>
                                      <p className="text-[#393838] text-xs font-MontserratRegular">
                                        {qrCode?.createdAt
                                          ? dayjs(qrCode?.createdAt).format(
                                              "MMM DD, YYYY"
                                            )
                                          : "---"}
                                      </p>
                                    </li>
                                    <li className="mb-2 flex items-center">
                                      <p className="text-[#ACACAC] text-xs w-16 font-MontserratRegular">
                                        Type:
                                      </p>
                                      <p className="text-[#393838] text-xs font-MontserratRegular">
                                        {qrCode?.qrCodeTypeDetail?.label ||
                                          "---"}
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="flex flex-col justify-center items-center">
                                <a
                                  href={`${PREVIEW_URL}${qrCode?.urlId}`}
                                  target="_blank"
                                >
                                  <img
                                    src={redirect_icon}
                                    alt="redirect"
                                    className="w-10 h-10 mb-4"
                                  />
                                </a>
                                <p className="text-xl font-MontserratBold text-[#002B38] font-bold !m-0">
                                  {qrCode?.scan}
                                </p>
                                <span className="text-themeGray-200 text-sm font-MontserratRegular -mt-1 font-medium">
                                  Scans
                                </span>
                              </div>
                            </div>
                            <div className="mx-8 h-full">
                              <Divider
                                type="vertical"
                                style={{
                                  height: "170px",
                                  backgroundColor: "#D7D1D1",
                                }}
                              />
                            </div>
                            <div className="flex flex-col items-center justify-center">
                              <ThemeButton
                                className={
                                  "w-40 border-primary hover:bg-primary hover:text-white mb-3 !h-11 !rounded"
                                }
                                bgColor={"bg-transparent"}
                                content={
                                  <p className="text-sm text-primary">
                                    Statistics
                                  </p>
                                }
                                onClick={() =>
                                  navigate(`/statistics/${qrCode?._id}`)
                                }
                              />
                              <Popover
                                content={
                                  <div>
                                    <Radio.Group
                                      onChange={(e) =>
                                        onDownloadQR(e.target.value, {
                                          ...qrCode?.customization,
                                          urlId: qrCode?.urlId,
                                        })
                                      }
                                    >
                                      <Space direction="vertical">
                                        <Radio value={"png"}>PNG</Radio>
                                        <Radio value={"svg"}>SVG</Radio>
                                      </Space>
                                    </Radio.Group>
                                  </div>
                                }
                                title="Select Format"
                              >
                                <ThemeButton
                                  className={"w-40 mb-3 !h-11 !rounded"}
                                  content={
                                    <p className="text-sm ">Download QR</p>
                                  }
                                />
                              </Popover>
                              <ThemeButton
                                className={
                                  "w-40 border-red-600 hover:bg-red-600 hover:text-white mb-3 !h-11 !rounded"
                                }
                                bgColor={"bg-transparent"}
                                content={
                                  <div className="flex items-center">
                                    <img
                                      src={delete_icon}
                                      alt="icon"
                                      className="w-[20px] h-[20px]"
                                    />
                                    <p className="text-sm text-red-600 ml-2">
                                      Delete QR
                                    </p>
                                  </div>
                                }
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <Empty className="bg-white rounded-md mb-8 p-6 py-20" />
                  )}
                </div>
              )}
            </div>
            <div className="flex justify-end">
              <Pagination
                defaultCurrent={1}
                total={data?.data?.qrCodes?.length}
                current={currentPage}
                defaultPageSize={10}
                onChange={(page, pageSize) => setPagination(page, pageSize)}
              />
            </div>
          </div>
        )}
        <FoldarModal
          open={isFolderModalOpen}
          onClose={onCloseFolderModal}
          formData={folderModalData}
          setFormData={(e) => setFolderModalData(e)}
          onSubmit={onSubmitFolderModal}
          isLoading={updatingFolder}
          model={folderModalMode}
        />
        <MoveFolder
          open={isMoveFoldarOpen}
          onClose={onCloseMoveFoldar}
          formData={moveFormData}
          setFormData={(e) => setMoveFormData(e)}
          onSubmit={onMoveFolder}
          isLoading={updatingQr}
          mode={moveFolderMode}
        />
      </div>
    </DashboardContainer>
  );
};

export default FolderDetail;
