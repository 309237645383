import React, { useState } from "react";
import { Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { logo } from "../../assets/images/images";
import {
  crown_icon,
  logout_icon,
  profile_icon,
  setting_icon,
} from "../../assets/icons/icons";
import { deleteKeyFromStorage, getUserInfo } from "../../helpers/helpers";

const Navbar = ({ menu }) => {
  const navigate = useNavigate();
  const user = getUserInfo();

  const items = [
    {
      label: (
        <span className="flex items-center">
          <img src={setting_icon} />
          <span className="ml-4">Setting</span>
        </span>
      ),
      key: "1",
    },
    {
      label: (
        <span className="flex items-center">
          <img src={crown_icon} />
          <span className="ml-4">Upgrade Plan</span>
        </span>
      ),
      key: "2",
    },
    {
      label: (
        <span className="flex items-center">
          <img src={logout_icon} />
          <span className="ml-4">Logout</span>
        </span>
      ),
      key: "3",
    },
  ];

  const handleMenuClick = (e) => {
    if (e.key === "3") {
      deleteKeyFromStorage("token");
      navigate("/login");
    }
  };

  const menuProps = {
    items: [
      {
        label: (
          <div className="flex flex-col">
            <span className="text-base text-primary font-semibold text-center">
              {user?.user?.fullname}
            </span>
            <span className="h-[2px]  bg-primary"></span>
          </div>
        ),
      },
      ...items,
    ],
    onClick: handleMenuClick,
  };
  return (
    <header className="body-font bg-themeGray-lighter navbar-shadow h-14 flex justify-center fixed w-full top-0 z-50">
      <div className="flex flex-wrap px-5 flex-row items-center md:container w-full justify-center">
        <a
          className="flex title-font font-medium items-center text-gray-900 cursor-pointer"
          onClick={() => navigate("/")}
        >
          <img src={logo} className="h-6" />
          <span className="md:ml-2 ml-1 text-lg text-lightPurple font-quicksand-bold font-quicksandBold">
            DYNAMIC QR
          </span>
        </a>
        <nav className="ml-auto flex flex-wrap items-center text-base justify-center">
          {user ? (
            <div className="flex items-center">
              <button
                className="inline-flex items-center bg-secondary text-white border-0 py-1 md:px-4 px-2 focus:outline-none hover:bg-primary rounded text-base"
                onClick={() => navigate("/dashboard")}
              >
                Dashboard
              </button>

              <Dropdown menu={menuProps} placement="bottom">
                <div className="flex items-center">
                  <img
                    src={profile_icon}
                    alt="icon"
                    // style={{ width: 35, height: 35, objectFit: "cover" }}
                    className="md:w-10 w-8 md:h-10 h-8 sm:ml-14 ml-2 cursor-pointer rounded-full object-cover"
                  />
                </div>
              </Dropdown>
            </div>
          ) : (
            <button
              className="inline-flex items-center bg-secondary text-white border-0 py-1 px-4 focus:outline-none hover:bg-primary rounded text-base ml-4"
              onClick={() => navigate("/login")}
            >
              Login
            </button>
          )}
          {menu}
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
