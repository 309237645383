import React from "react";
import { Modal } from "antd";

const ImagePreviewModal = ({ open, onClose, image }) => {
  return (
    <Modal open={open} onCancel={onClose} footer={false} width={300}>
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-xl font-semibold">Image Preview</h1>
        <div className="bg-[#7d7d7d] h-[1px] w-full my-2"></div>
        <div className="bg-white rounded-3xl p-3 h-[500px] w-64 inputBox-shadow z-20">
          <img src={image} className="w-full h-full rounded-3xl" />
        </div>
      </div>
    </Modal>
  );
};

export default ImagePreviewModal;
