import React from "react";
import { DashboardContainer } from "../../components/components";

const Support = () => {
  return (
    <DashboardContainer selected={"6"}>
      <div className="min-h-[calc(100vh-60px)] w-full bg-themeGray-light">
        <div className="w-full container py-6 ">
          <div>
            <h1 className="text-2xl font-MontserratBold font-bold text-secondary">
              Support
            </h1>
            <p>this page will coming soon!</p>
          </div>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default Support;
