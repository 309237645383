import React, { useEffect, useState } from "react";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";
import { Spin } from "antd";

const LocationPreview = ({ type }) => {
  const [map, setMap] = useState(/** @type google.maps.GoogleMap */);
  const center = { lat: 24.905180900318342, lng: 67.07836398292596 };
  const location = {
    lat: Number(type?.sections[0]?.inputs[1]?.value),
    lng: Number(type?.sections[0]?.inputs[2]?.value),
  };
  const googleMapsApiKey = process.env.GOOGLE_MAP_API; // Make sure your API key is in the environment variables.

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey,
    libraries: ["places"],
  });

  useEffect(() => {
    if (map) {
      map.panTo(location.lat && location.lng ? location : center);
    }
  }, [
    type?.sections[0]?.inputs[1]?.value,
    type?.sections[0]?.inputs[2]?.value,
  ]);

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  return (
    <div className="w-full h-full">
      {isLoaded ? (
        <GoogleMap
          center={center}
          zoom={15}
          mapContainerStyle={{ width: "100%", height: "100%" }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onLoad={(map) => setMap(map)}
        >
          <Marker position={location.lat && location.lng ? location : center} />
        </GoogleMap>
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default LocationPreview;
