import React from "react";
import { ProgressBar } from "../components";
import { useNavigate } from "react-router-dom";
import { Anchor } from "antd";
import { IoArrowBack } from "react-icons/io5";
const { Link } = Anchor;

const SectionHeader = ({
  type,
  selected,
  setSelected,
  isOverflow,
  scrollToSection,
  currentStep,
  totalSteps,
  onChangeStep,
}) => {
  // console.log("currentStep", currentStep);
  // console.log("condition check", isOverflow >= 70);
  const navigate = useNavigate();
  return (
    <>
      <div
        className={isOverflow >= 70 && currentStep == 1 ? "block" : "hidden"}
      >
        {type?.sections?.length > 1 && (
          <div className=" bg-themeGray-lighter z-50 fixed w-full top-[56px] left-0 right-0 navbar-shadow">
            <Anchor targetOffset={130}>
              <div className="flex items-center justify-center flex-nowrap overflow-auto">
                {type?.sections?.map((item, index) => {
                  return (
                    <Link
                      key={index}
                      href={`#${item?.title.trim()}`}
                      title={item?.title}
                      className="text-secondary text-sm font-semibold hover:bg-themeGray-dark rounded-md m-2 cursor-pointer !py-2 !px-4"
                      onClick={() => {
                        setSelected(index + 1);
                      }}
                    />
                  );
                })}
              </div>
            </Anchor>
          </div>
        )}
      </div>

      <div
        className={`bg-themeGray-medium z-50 ${
          isOverflow >= 70 && currentStep == 1 ? "hidden" : "block"
        }`}
      >
        <div className="flex items-center mb-4">
          <button
            className="bg-transparent md:rounded-md rounded-full border-primary text-primary hover:bg-primary hover:text-white hover:outline-none focus:outline-none shadow-none border md:px-6 px-2 md:py-1 py-2 md:text-sm text-base"
            onClick={() => (currentStep == 1 ? navigate(-1) : onChangeStep(1))}
          >
            <IoArrowBack className="md:hidden flex" />
            <p className="md:flex hidden">Back</p>
          </button>
          <div className="flex flex-col items-center w-full justify-between">
            <h1 className="text-sm text-primary font-bold mb-1">
              Enter Content
            </h1>
            <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
          </div>
        </div>
        {type?.sections?.length > 1 && currentStep == 1 && (
          <Anchor targetOffset={160}>
            <div className="flex items-center justify-center flex-nowrap overflow-auto">
              {type?.sections?.map((item, index) => {
                return (
                  <Link
                    key={index}
                    href={`#${item?.title.trim()}`}
                    title={item?.title}
                    className="text-secondary text-sm font-semibold hover:bg-themeGray-dark rounded-md m-2 cursor-pointer !py-2 !px-4"
                    onClick={() => {
                      setSelected(index + 1);
                    }}
                  />
                  // <span
                  //   key={index}
                  //   className={`px-4 py-2 text-secondary text-sm font-semibold hover:bg-themeGray-dark rounded-md m-2 cursor-pointer ${
                  //     selected == index + 1
                  //       ? "bg-themeGray-dark"
                  //       : "bg-transparent"
                  //   }`}
                  //   onClick={() => setSelected(index + 1)}
                  // >
                  //   <a className="whitespace-nowrap" href={`#${item?.title}`}>
                  //     {item?.title}
                  //   </a>
                  // </span>
                );
              })}
            </div>
          </Anchor>
        )}
      </div>
    </>
  );
};

export default SectionHeader;
