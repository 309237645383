import React, { useState } from "react";
import { Collapse, Row, Col } from "antd";
import ImageBox from "../ImageBox/ImageBox";
import { ThemeTable, ThemeInput } from "../../ui/ui";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const ProductInput = ({
  input,
  category,
  setCategory,
  formData,
  setFormData,
  onAddCategory,
  onAddProduct,
  errorMessage,
  setErrorMessage,
  onDeleteCategory,
  onEditCategory,
  onDeleteProduct,
  onEditProduct,
  mode,
  setMode,
  productMode,
  setProductMode,
  productErrorMessage,
}) => {
  const [editId, setEditId] = useState("");
  const [productEditId, setProductEditId] = useState("");
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (data, _, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },

    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (image) => (
        <img src={image} className="w-16 h-12 mx-auto" alt="" />
      ),
    },
    {
      title: "Product Title",
      dataIndex: "title",
      key: "title",
      render: (title) => <p className="text-center font-medium">{title}</p>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => <p className="text-center font-medium">{price}</p>,
    },
    // {
    //   title: "",
    //   dataIndex: "_id",
    //   key: "_id",
    //   width: "10%",
    //   render: (text, data, index) => (
    //     <div className="flex justify-center items-center">
    //       <button
    //         className="text-primary"
    //         onClick={() => {
    //           setFormData(data);
    //           setProductMode("edit");
    //           setProductEditId(index);
    //         }}
    //       >
    //         <EditOutlined />
    //       </button>
    //       <button
    //         className="ml-2 text-red-600"
    //         onClick={() => onDeleteProduct(index)}
    //       >
    //         <DeleteOutlined />
    //       </button>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div>
      <div className="flex md:flex-row flex-col items-center justify-between w-full">
        <ThemeInput
          label={"Enter category name"}
          labelPrimary
          placeholder={"Enter Category name "}
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className={"w-full"}
        />

        <button
          className="md:w-40 w-full h-14 text-sm bg-primary text-white rounded md:ml-4 ml-0 mt-6 font-semibold"
          onClick={
            mode === "create" ? onAddCategory : () => onEditCategory(editId)
          }
        >
          {mode === "create" ? "Add Category" : "Edit Category"}
        </button>
      </div>
      <p className="text-sm text-red-600 my-1">
        {productErrorMessage?.category}
      </p>

      <div>
        {input?.value?.length ? (
          <div>
            {input?.value?.map((category, index) => {
              return (
                <Collapse
                  items={[
                    {
                      key: index,
                      label: (
                        <div className="flex items-center justify-between">
                          {category?.name}
                          <div className="flex items-center">
                            <button
                              className="ml-3 text-primary"
                              onClick={() => {
                                setCategory(category?.name);
                                setMode("edit");
                                setEditId(index);
                              }}
                            >
                              <EditOutlined />
                            </button>
                            <button
                              className="ml-3 text-red-600"
                              onClick={() => onDeleteCategory(index)}
                            >
                              <DeleteOutlined />
                            </button>
                          </div>
                        </div>
                      ),
                      children: (
                        <div>
                          <div className={"mb-4"}>
                            <ThemeInput
                              label={"Product Name"}
                              labelPrimary
                              placeholder={"Enter Product Name"}
                              value={formData?.title}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  title: e.target.value,
                                })
                              }
                            />
                            <p className="text-sm text-red-600 mt-1">
                              {productErrorMessage?.title}
                            </p>
                          </div>
                          <ImageBox
                            label="Upload your image"
                            placeholder="Supported File Formats: PNG, JPEG."
                            className={"mb-4"}
                            id={`${input?.type}${index}`}
                            image={formData?.image}
                            setImage={(url, file) =>
                              setFormData({
                                ...formData,
                                image: url,
                                imageFile: file,
                              })
                            }
                            errorMessage={errorMessage}
                            setErrorMessage={(e) => setErrorMessage(e)}
                          />
                          <div className={"mb-6"}>
                            <ThemeInput
                              label={"Price"}
                              labelPrimary
                              placeholder={"Enter Price"}
                              type={"number"}
                              value={formData?.price}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  price: e.target.value,
                                })
                              }
                            />
                            <p className="text-sm text-red-600 mt-1">
                              {productErrorMessage?.price}
                            </p>
                          </div>
                          <button
                            className="w-full border-primary border rounded h-12 text-primary hover:bg-primary hover:text-white"
                            onClick={
                              () => onAddProduct(category, index)
                              // productMode === "create"
                              //   ? onAddProduct(category, index)
                              //   : onEditProduct(category, index, productEditId)
                            }
                          >
                            {/* {productMode === "create"
                              ? "Add Product"
                              : "Edit Product"} */}
                            Add Product
                          </button>
                          <div className="mt-6">
                            <ThemeTable
                              columns={columns}
                              data={input?.value[index]?.products || []}
                            />
                          </div>
                        </div>
                      ),
                    },
                  ]}
                  key={index}
                  className="my-2"
                />
              );
            })}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default ProductInput;
