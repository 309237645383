import React, { useRef, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useGetStatsByIdQuery } from "../../services/qrStats";
import { useGetQrCodeByIdQuery } from "../../services/qrCode";
import { left_arrow_icon, time_icon } from "../../assets/icons/icons";
import { BiTimeFive } from "react-icons/bi";
import { FiLink } from "react-icons/fi";
import dayjs from "dayjs";
import { PREVIEW_URL } from "../../constants/constants";
import QRCodeStyling from "qr-code-styling";
import * as social_icons from "../../assets/icons/socials/socials";
import { useGetQrInteractionsQuery } from "../../services/qrInteraction";
import ThemeTable from "../../ui/ThemeTable";

const Statistics = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const ref = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [qrOptions, setQrOptions] = useState({});
  const { data: qrData, isLoading } = useGetQrCodeByIdQuery(id);
  const { data: stats, isLoading: fetchingStats } = useGetStatsByIdQuery(id);
  const { data: leads, isLoading: fetchingLeads } = useGetQrInteractionsQuery({
    id: id,
    page: currentPage,
    perPage: resultPerPage,
  });

  console.log("qr code data", qrData);
  console.log("stats", stats);
  console.log("leads", leads);


  const qrCode = new QRCodeStyling(qrOptions);

  useEffect(() => {
    setTotal(leads?.data?.total || 0);
  }, [leads]);

  useEffect(() => {
    let qrCodeData = qrData?.data?.customization;
    let styles = {
      width: 70,
      height: 70,
      data: `${PREVIEW_URL}${qrData?.data?.urlId}`,
      backgroundOptions: {
        color: !qrCodeData?.background?.isTransparent
          ? qrCodeData?.background?.type === "solid" &&
            qrCodeData?.background?.color[0]
          : "transparent",
        gradient: {
          type: "linear",
          rotation: 0,
          colorStops: [
            {
              offset: 0,
              color: qrCodeData?.background?.color[0],
            },
            {
              offset: 1,
              color: qrCodeData?.backgroundColor?.color[1],
            },
          ],
        },
      },
      cornersDotOptions: {
        type: qrCodeData?.eyeBall?.type,
        color: qrCodeData?.eyeBall?.color,
      },
      cornersSquareOptions: {
        type: qrCodeData?.eyeBall?.eyeFrame,
        color: qrCodeData?.eyeBall?.eyeFrameColor,
      },
      dotsOptions: {
        type: qrCodeData?.pattern?.type,
        color: qrCodeData?.pattern?.color?.color[0],
        gradient: {
          type: "linear",
          rotation: 0,
          colorStops: [
            {
              offset: 0,
              color: qrCodeData?.pattern?.color?.color[0],
            },
            {
              offset: 1,
              color: qrCodeData?.pattern?.color?.color[1],
            },
          ],
        },
      },
      image: qrCodeData?.logo?.image || social_icons[qrCodeData?.logo?.logo],
      imageOptions: {
        hideBackgroundDots: qrCodeData?.logo?.isWhite,
        imageSize: qrCodeData?.logo?.size / 10,
        margin: 2,
      },
    };
    (qrCodeData?.background?.type !== "gradient" || qrCodeData?.frame?.type) &&
      delete styles?.backgroundOptions?.gradient;
    qrCodeData?.pattern?.colorType === "solid" &&
      delete styles?.dotsOptions?.gradient;

    setQrOptions(styles);
  }, [qrData, isLoading]);

  useEffect(() => {
    const qrContainer = document.getElementById("qr-container");
    if (qrContainer) {
      qrContainer.innerHTML = "";

      qrCode.append(ref.current);
    }
  }, [qrOptions]);

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (item, record, index) => (
        <p className="text-center font-medium">{index + 1}</p>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, data) => (
        <p className="text-center font-medium">{data?.data?.name}</p>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, data) => (
        <p className="text-center font-medium">{data?.data?.email}</p>
      ),
    },
    {
      title: "Organization",
      dataIndex: "organization",
      key: "organization",
      render: (text, data) => (
        <p className="text-center font-medium">{data?.data?.organization}</p>
      ),
    },
    {
      title: "Interested In",
      dataIndex: "interestedIn",
      key: "interestedIn",
      render: (text, data) => (
        <p className="text-center font-medium">{data?.data?.interestedIn}</p>
      ),
    },
    {
      title: "Timestamp",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (
        <p className="text-center font-medium">
          {new Date(text).toDateString()}
        </p>
      ),
    },
  ];

  return (
    <div className="container mx-auto py-4">
      <div className="flex items-center">
        <img
          src={left_arrow_icon}
          width={18}
          height={18}
          alt=""
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <p className="ml-3 font-semibold text-2xl">Statistics</p>
      </div>

      <div className="inputBox-shadow rounded-md p-6 mt-6 flex items-center justify-between">
        <div>
          <h1 className="text-xl font-semibold font-MontserratBold text-secondary">
            {qrData?.data?.label || "---"}
          </h1>
          <p className="text-sm text-themeGray-200">
            {qrData?.data?.qrCodeTypeDetail?.label || "---"}
          </p>
          <div className="flex items-center mt-6 text-themeGray-200 text-base">
            <BiTimeFive />
            <p className="ml-2 text-xs">
              {qrData?.data?.createdAt
                ? dayjs(qrData?.data?.createdAt).format("MMM DD, YYYY")
                : "---"}
            </p>
          </div>
          <div className="flex items-center text-themeGray-200 text-base mt-3">
            <FiLink />
            <a
              href={`${PREVIEW_URL}${qrData?.data?.urlId}`}
              className="ml-2 text-xs text-primary"
            >
              {`${PREVIEW_URL}${qrData?.data?.urlId}`}
            </a>
          </div>
        </div>

        <div>
          {qrData?.data?.customization?.frame?.type ? (
            <div id="qr-main">
              {qrData?.data?.customization?.frame?.type === "frame1" && (
                <div className="flex-col w-full justify-center items-center">
                  <div
                    className="p-2 border-4 rounded-lg mb-1"
                    style={{
                      borderColor: qrData?.data?.customization?.frame?.color,
                      background: qrData?.data?.customization?.background
                        ?.isTransparent
                        ? "transparent"
                        : qrData?.data?.customization?.background?.type ===
                          "solid"
                        ? qrData?.data?.customization?.background?.color[0]
                        : qrData?.data?.customization?.background?.type ===
                            "gradient" &&
                          `linear-gradient(to right, ${qrData?.data?.customization?.background?.color[0]}, ${qrData?.data?.customization?.background?.color[1]})`,
                    }}
                  >
                    <div
                      ref={ref}
                      id="qr-container"
                      className={`${
                        qrData?.data?.customization?.template === "Circular" &&
                        "rounded-full border-4 border-secondary p-8"
                      }`}
                    />
                  </div>
                  <div
                    className="rounded-lg p-1 py-2"
                    style={{
                      backgroundColor:
                        qrData?.data?.customization?.frame?.color,
                    }}
                  >
                    <p
                      className="text-xs text-center"
                      style={{
                        color: qrData?.data?.customization?.frame?.textColor,
                      }}
                    >
                      {qrData?.data?.customization?.frame?.text}
                    </p>
                  </div>
                </div>
              )}
              {qrData?.data?.customization?.frame?.type === "frame2" && (
                <div className="flex-col w-full justify-center items-center">
                  <div
                    className="border-4 rounded-lg mb-1"
                    style={{
                      borderColor: qrData?.data?.customization?.frame?.color,
                      background: qrData?.data?.customization?.background
                        ?.isTransparent
                        ? "transparent"
                        : qrData?.data?.customization?.background?.type ===
                          "solid"
                        ? qrData?.data?.customization?.background?.color[0]
                        : qrData?.data?.customization?.background?.type ===
                            "gradient" &&
                          `linear-gradient(to right, ${qrData?.data?.customization?.background?.color[0]}, ${qrData?.data?.customization?.background?.color[1]})`,
                    }}
                  >
                    <div
                      ref={ref}
                      id="qr-container"
                      className={`p-2 ${
                        qrData?.data?.customization?.template === "Circular" &&
                        "rounded-full border-4 border-secondary p-8"
                      }`}
                    />
                    <div
                      className="p-1 py-2 mt-1"
                      style={{
                        backgroundColor:
                          qrData?.data?.customization?.frame?.color,
                      }}
                    >
                      <p
                        className="text-xs text-center"
                        style={{
                          color: qrData?.data?.customization?.frame?.textColor,
                        }}
                      >
                        {qrData?.data?.customization?.frame?.text}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {qrData?.data?.customization?.frame?.type === "frame3" && (
                <div className="flex-col w-full justify-center items-center">
                  <div
                    className="border-4 rounded-lg mb-1"
                    style={{
                      borderColor: qrData?.data?.customization?.frame?.color,
                      background: qrData?.data?.customization?.background
                        ?.isTransparent
                        ? "transparent"
                        : qrData?.data?.customization?.background?.type ===
                          "solid"
                        ? qrData?.data?.customization?.background?.color[0]
                        : qrData?.data?.customization?.background?.type ===
                            "gradient" &&
                          `linear-gradient(to right, ${qrData?.data?.customization?.background?.color[0]}, ${qrData?.data?.customization?.background?.color[1]})`,
                    }}
                  >
                    <div
                      ref={ref}
                      id="qr-container"
                      className={`p-2 ${
                        qrData?.data?.customization?.template === "Circular" &&
                        "rounded-full border-4 border-secondary p-8"
                      }`}
                    />
                    <div className="bg-transparent p-1 py-2 mt-1">
                      <p
                        className="text-xs text-center"
                        style={{
                          color: qrData?.data?.customization?.frame?.textColor,
                        }}
                      >
                        {qrData?.data?.customization?.frame?.text}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {qrData?.data?.customization?.frame?.type === "frame4" && (
                <div className="flex-col w-full justify-center items-center">
                  <div
                    className="mb-1"
                    style={{
                      background: qrData?.data?.customization?.background
                        ?.isTransparent
                        ? "transparent"
                        : qrData?.data?.customization?.background?.type ===
                          "solid"
                        ? qrData?.data?.customization?.background?.color[0]
                        : qrData?.data?.customization?.background?.type ===
                            "gradient" &&
                          `linear-gradient(to right, ${qrData?.data?.customization?.background?.color[0]}, ${qrData?.data?.customization?.background?.color[1]})`,
                    }}
                  >
                    <div
                      ref={ref}
                      id="qr-container"
                      className={`${
                        qrData?.data?.customization?.template === "Circular" &&
                        "rounded-full border-4 border-secondary p-8"
                      }`}
                    />
                    <div className="bg-transparent p-1 py-2 mt-1">
                      <p
                        className="text-xs text-center"
                        style={{
                          color: qrData?.data?.customization?.frame?.textColor,
                        }}
                      >
                        {qrData?.data?.customization?.frame?.text}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {qrData?.data?.customization?.frame?.type === "frame5" && (
                <div className="flex-col w-full justify-center items-center">
                  <div
                    className="border-4 rounded-lg mb-1"
                    style={{
                      borderColor: qrData?.data?.customization?.frame?.color,
                      background: qrData?.data?.customization?.background
                        ?.isTransparent
                        ? "transparent"
                        : qrData?.data?.customization?.background?.type ===
                          "solid"
                        ? qrData?.data?.customization?.background?.color[0]
                        : qrData?.data?.customization?.background?.type ===
                            "gradient" &&
                          `linear-gradient(to right, ${qrData?.data?.customization?.background?.color[0]}, ${qrData?.data?.customization?.background?.color[1]})`,
                    }}
                  >
                    <div
                      ref={ref}
                      id="qr-container"
                      className={`p-2 ${
                        qrData?.data?.customization?.template === "Circular" &&
                        "rounded-full border-4 border-secondary p-8"
                      }`}
                    />
                    <div
                      className="border-t-4 bg-transparent p-1 py-2 mt-1"
                      style={{
                        borderColor: qrData?.data?.customization?.frame?.color,
                      }}
                    >
                      <p
                        className="text-xs text-center"
                        style={{
                          color: qrData?.data?.customization?.frame?.textColor,
                        }}
                      >
                        {qrData?.data?.customization?.frame?.text}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div id="qr-main">
              <div
                ref={ref}
                id="qr-container"
                className={`${
                  qrData?.data?.customization?.template === "Circular" &&
                  "rounded-full border-4 border-secondary p-8"
                }`}
              />
            </div>
          )}
        </div>
      </div>

      <div className="inputBox-shadow rounded-md mt-4 flex items-center">
        <div className="border border-themeGray-dark rounded-xl p-4 px-6 m-4 w-4/12">
          <span className="text-base font-semibold">
            {stats?.data?.totalScans}
          </span>
          <p className="text-sm font-medium mt-1">Total Scans</p>
        </div>
        <div className="border border-themeGray-dark rounded-xl p-4 px-6 m-4 w-4/12">
          <span className="text-base font-semibold">
            {stats?.data?.totalUniqueScans}
          </span>
          <p className="text-sm font-medium mt-1">Unique Scans</p>
        </div>
      </div>
      <div className="mt-8">
        <h1 className="text-xl font-semibold font-MontserratBold text-secondary mb-4">
          Leads
        </h1>
        <ThemeTable
          pagination={{ total, setCurrentPage, setResultPerPage }}
          columns={columns}
          data={leads?.data?.data || []}
          loader={fetchingLeads}
        />
      </div>
    </div>
  );
};

export default Statistics;
