import React from "react";
import { message } from "antd";

const MultiImageUploader = ({ images, setImages }) => {
  const onHandleImage = (e, index) => {
    const list = [...images];
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        list[index] = {
          url: reader.result,
          image: file,
        };
        setImages(list);
      };
      reader.readAsDataURL(file);
    }
  };

  const addImageBox = () => {
    let list = [...images];
    if (list[list?.length - 1]?.url) {
      list.push({ image: "", url: "" });
    } else {
      message.error("First add previous image");
    }
    setImages(list);
  };

  return (
    <div className="mb-8">
      <div className="flex items-center -m-2 overflow-x-auto thin-scroll">
        {images?.length &&
          images?.map((image, index) => {
            if (image?.url) {
              return (
                <div className="p-2" key={index}>
                  <label htmlFor={`upload${index}`}>
                    <div className="w-56 h-32 rounded-md inputBox-shadow flex items-center justify-center mb-4 cursor-pointer">
                      <input
                        type="file"
                        onChange={(e) => onHandleImage(e, index)}
                        id={`upload${index}`}
                        className="hidden"
                        accept="image/*"
                      />
                      <img
                        src={image?.url}
                        alt="image"
                        className="rounded-md w-full h-32"
                      />
                    </div>
                  </label>
                </div>
              );
            } else {
              return (
                <div className="p-2" key={index}>
                  <label htmlFor="upload">
                    <div className="w-56 h-32 rounded-md inputBox-shadow flex items-center justify-center mb-4 cursor-pointer">
                      <span className="text-base font-semibold text-themeGray-300 text-center">
                        Upload your <br /> image
                      </span>
                      <input
                        type="file"
                        onChange={(e) => onHandleImage(e, index)}
                        id="upload"
                        className="hidden"
                        accept="image/*"
                      />
                    </div>
                  </label>
                </div>
              );
            }
          })}
        <div className="p-2">
          <div
            className="w-56 h-32 rounded-md inputBox-shadow flex items-center justify-center mb-4 cursor-pointer"
            onClick={addImageBox}
          >
            <span className="text-4xl font-semibold text-themeGray-300 text-center">
              +
            </span>
          </div>
        </div>
      </div>

      {/* <p className="text-xs font-semibold text-themeGray-200">
        {input?.placeholder}
      </p> */}
    </div>
  );
};

export default MultiImageUploader;
