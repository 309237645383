import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { ThemeColorPicker } from "../../ui/ui";

const Design = ({ value, onChange }) => {
  const [selected, setSelected] = useState(0);
  const colors = [
    { primary: "#2965FF", button: "#002B38", heading: "#2965FF" },
    { primary: "#BB2C2C", button: "#DC6D6D", heading: "#BB2C2C" },
    { primary: "#1E7130", button: "#6CD05C", heading: "#1E7130" },
    { primary: "#DB6D00", button: "#FCA85A", heading: "#DB6D00" },
  ];

  const onHandleColorChange = (e, index) => {
    let values = [...value];
    values[index] = e.toHexString();
    console.log("values", values);
    onChange(values);
  };

  const onSelectColor = (index) => () => {
    setSelected(index);
  };

  useEffect(() => {
    let values = [...value];
    values[0] = colors[selected]?.button;
    values[1] = colors[selected]?.primary;
    values[2] = colors[selected]?.heading;
    onChange(values);
  }, [selected]);
  return (
    <div>
      <h1 className="text-primary font-semibold text-base mb-3">COLORS</h1>
      <Row className="w-full">
        {colors?.map((color, index) => {
          return (
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className={`p-4`}
              key={index}
            >
              <div
                className={`rounded-md inputBox-shadow p-3 flex flex-col justify-center w-full h-40 cursor-pointer ${
                  selected === index && "border-2 border-primary"
                }`}
                onClick={onSelectColor(index)}
              >
                <div
                  className={`h-14 w-full mb-2`}
                  style={{ backgroundColor: color?.button }}
                ></div>
                <div
                  className={`w-8 h-8 rounded-full mb-1 ml-auto`}
                  style={{ backgroundColor: color?.primary }}
                ></div>
                <div className="h-1 w-full bg-themeGray-light mt-4">
                  <div
                    className="w-7/12 h-1"
                    style={{ backgroundColor: color?.heading }}
                  ></div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
      <Row className="w-full">
        <Col xl={8} lg={12} sm={12} xs={24} className="mb-4">
          <ThemeColorPicker
            label={"Primary"}
            labelPrimary
            value={value ? value[0] : ""}
            onChange={(e) => onHandleColorChange(e, 0)}
          />
        </Col>

        <Col xl={8} lg={12} sm={12} xs={24} className="mb-4">
          <ThemeColorPicker
            label={"Button"}
            labelPrimary
            value={value ? value[1] : ""}
            onChange={(e) => onHandleColorChange(e, 1)}
          />
        </Col>

        <Col xl={8} lg={12} sm={12} xs={24} className="mb-4">
          <ThemeColorPicker
            label={"Heading"}
            labelPrimary
            value={value ? value[2] : ""}
            onChange={(e) => onHandleColorChange(e, 2)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Design;
