import React from "react";
import { Modal, Skeleton } from "antd";
const QrStatisticModal = ({ isOpen, onClose, data, isLoading }) => {
  // console.log("statistic", data);
  return (
    <Modal
      width={600}
      footer={false}
      open={isOpen}
      onCancel={onClose}
      style={{
        top: 20,
      }}
    >
      <div className="my-8">
        {isLoading ? (
          <Skeleton />
        ) : (
          <div>
            <h1 className="text-xl text-secondary font-semibold font-MontserratBold mb-3">
              Statistics
            </h1>
            <div></div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default QrStatisticModal;
