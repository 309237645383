import React, { useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { file_icon } from "../../assets/icons/icons";

const FileUploader = ({
  label,
  placeholder,
  file,
  setFile,
  className,
  id,
  errorMessage,
  setErrorMessage,
}) => {
  const allowedFormats = ["application/pdf"];

  const onChangeImage = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (allowedFormats.includes(selectedFile.type)) {
        setFile({ name: selectedFile?.name, file: selectedFile });
        setErrorMessage("");
      } else {
        setFile("");
        setErrorMessage("Invalid file format. Please select a PDF.");
      }
    }
  };
  return (
    <div className={`${className}`}>
      <label htmlFor={`${id}`}>
        <div className="w-full h-40 rounded-md inputBox-shadow flex items-center justify-center mb-2 cursor-pointer">
          {file ? (
            <div className="flex items-center">
              <img src={file_icon} alt="file" />
              <span className="ml-2 font-semibold text-sm">{file?.name}</span>
            </div>
          ) : (
            <span className="text-lg font-semibold text-themeGray-100">
              {label}
            </span>
          )}
        </div>
        <input
          type="file"
          onChange={(e) => onChangeImage(e)}
          id={id}
          className="hidden"
          accept="application/pdf"
        />
      </label>
      <p className="text-xs font-semibold text-themeGray-200">{placeholder}</p>
      {errorMessage && (
        <p className="text-xs text-red-600 font-semibold flex items-center mt-1">
          <InfoCircleOutlined className="mr-2" /> {errorMessage}
        </p>
      )}
    </div>
  );
};

export default FileUploader;
