import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import QRCodeStyling from "qr-code-styling";
import {
  DashboardContainer,
  FoldarModal,
  MoveFolder,
  QrStatisticModal,
} from "../../components/components";
import {
  Divider,
  Input,
  Spin,
  Pagination,
  Popover,
  Radio,
  Space,
  Empty,
  Skeleton,
  message,
  DatePicker,
} from "antd";
import { ThemeButton, ThemeSelect } from "../../ui/ui";
import {
  add_icon,
  search_icon,
  qrDefault_icon,
  delete_icon,
  redirect_icon,
  add_blue_icon,
  folder_icon,
} from "../../assets/icons/icons";
import { CiEdit } from "react-icons/ci";
import { IoIosStats } from "react-icons/io";
import { MdOutlineFileDownload, MdDelete, MdEdit } from "react-icons/md";
import { FaExternalLinkAlt } from "react-icons/fa";
import {
  useGetQrCodesQuery,
  usePutQrCodeMutation,
} from "../../services/qrCode";
import { useLazyGetStatsByIdQuery } from "../../services/qrStats";
import { PREVIEW_URL } from "../../constants/constants";
import {
  useAddFolderMutation,
  useGetFoldersQuery,
} from "../../services/folders";
import { getUserInfo } from "../../helpers/helpers";
import * as social_icons from "../../assets/icons/socials/socials";
import { useGetQrTypesQuery } from "../../services/qrTypes";
import Carousel from "react-multi-carousel";
import { useGetUserQuery } from "../../services/auth";
const { RangePicker } = DatePicker;

const Dashboard = () => {
  const navigate = useNavigate();
  const [customDateRangeVisible, setCustomDateRangeVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isMoveFoldarOpen, setIsMoveFoldarModalOpen] = useState(false);
  const [moveFoldarId, setMoveFoldarId] = useState("");
  const [moveFolderMode, setMoveFoldarMode] = useState("move");
  const [moveFormData, setMoveFormData] = useState("");
  const [inputKey, setInputKey] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(20);
  const [filter, setFilter] = useState({
    type: "All Types",
    sort: "Lifetime",
    date: [dayjs(new Date(2023, 0, 1)), dayjs()],
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenFoldarModal, setIsOpenFoldarModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [statistics, setStatistics] = useState({});
  const [qrTypesList, setQrTypesList] = useState([]);
  const [qrCodes, setQrCodes] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isQrCodesLoading, setIsQrCodesLoading] = useState(false);
  const { data: userInfo, isLoading: fetchingUserInfo } = useGetUserQuery();
  const { data: qrTypes, isLoading: fetchingQRTypes } = useGetQrTypesQuery();
  const [addFolder, { isLoading: submittingFolder }] = useAddFolderMutation();
  const [updateQrCode, { isLoading: updatingQr }] = usePutQrCodeMutation();
  const { data, isLoading, refetch } = useGetQrCodesQuery({
    page: 1,
    perPage: 100000,
    folder: "",
    type: filter?.type === "All Types" ? "" : filter?.type,
    sortType: filter?.sort,
    startDate: filter?.date[0],
    endDate: filter?.date[1],
  });
  const {
    data: folders,
    isLoading: fetchingFolders,
    refetch: refetchFolder,
  } = useGetFoldersQuery();

  const [getStatsById, { isLoading: fetchingStats }] =
    useLazyGetStatsByIdQuery();

  const responsive = {
    xs: {
      breakpoint: { max: 576 },
      items: 1,
    },
    sm: {
      breakpoint: { min: 576, max: 768 },
      items: 2,
    },
    md: {
      breakpoint: { min: 768, max: 992 },
      items: 3,
    },
    lg: {
      breakpoint: { min: 992, max: 1200 },
      items: 4,
    },
    xl: {
      breakpoint: { min: 1200, max: 1440 },
      items: 5,
    },
  };

  useEffect(() => {
    if (userInfo?.status?.code === 200) {
      setIsAdmin(userInfo?.data?.isAdmin);
    }
  }, [fetchingUserInfo]);

  useEffect(() => {
    let list = [];
    list.push({ value: "All Types", label: "All Types" });
    qrTypes?.data?.map((type) => {
      list.push({ value: type?._id, label: type?.label });
    });
    setQrTypesList(list);
  }, [qrTypes]);

  useEffect(() => {
    setIsQrCodesLoading(true);
    if (searchValue) {
      const filteredQrCodes = data?.data?.qrCodes?.filter((qrCode) => {
        let urlId = qrCode?.urlId?.toLowerCase();
        let qrLabel = qrCode?.label?.toLowerCase();

        if (
          urlId?.includes(searchValue.toLowerCase()) ||
          (qrCode?.label && qrLabel.includes(searchValue.toLowerCase()))
        ) {
          return qrCode;
        }
      });
      setIsQrCodesLoading(false);
      setQrCodes(filteredQrCodes);
    } else {
      setIsQrCodesLoading(false);
      setQrCodes(data?.data?.qrCodes || []);
    }
  }, [searchValue, data]);

  useEffect(() => {
    qrCodes?.length > 0 &&
      qrCodes.slice(minValue, maxValue)?.map(async (qrCode, index) => {
        let styles = {
          width: 100,
          height: 100,
          data: `${PREVIEW_URL}${qrCode?.urlId}`,
          backgroundOptions: {
            color: !qrCode?.customization?.background?.isTransparent
              ? qrCode?.customization?.background?.type === "solid" &&
                qrCode?.customization?.background?.color[0]
              : "transparent",
            gradient: {
              type: "linear",
              rotation: 0,
              colorStops: [
                {
                  offset: 0,
                  color: qrCode?.customization?.background?.color[0],
                },
                {
                  offset: 1,
                  color: qrCode?.customization?.backgroundColor?.color[1],
                },
              ],
            },
          },
          cornersDotOptions: {
            type: qrCode?.customization?.eyeBall?.type,
            color: qrCode?.customization?.eyeBall?.color,
          },
          cornersSquareOptions: {
            type: qrCode?.customization?.eyeBall?.eyeFrame,
            color: qrCode?.customization?.eyeBall?.eyeFrameColor,
          },
          dotsOptions: {
            type: qrCode?.customization?.pattern?.type,
            color: Array.isArray(qrCode?.customization?.pattern?.color?.color)
              ? qrCode?.customization?.pattern?.color?.color[0]
              : qrCode?.customization?.pattern?.color[0],
            gradient: {
              type: "linear",
              rotation: 0,
              colorStops: [
                {
                  offset: 0,
                  color: Array.isArray(
                    qrCode?.customization?.pattern?.color?.color
                  )
                    ? qrCode?.customization?.pattern?.color?.color[0]
                    : qrCode?.customization?.pattern?.color[0],
                },
                {
                  offset: 1,
                  color: Array.isArray(
                    qrCode?.customization?.pattern?.color?.color
                  )
                    ? qrCode?.customization?.pattern?.color?.color[1]
                    : qrCode?.customization?.pattern?.color[1],
                },
              ],
            },
          },
          image:
            qrCode?.customization?.logo?.image ||
            social_icons[qrCode?.customization?.logo?.logo],
          imageOptions: {
            hideBackgroundDots: qrCode?.customization?.logo?.isWhite,
            imageSize: qrCode?.customization?.logo?.size / 10,
            margin: 2,
          },
        };
        (qrCode?.customization?.background?.type !== "gradient" ||
          qrCode?.customization?.frame?.type) &&
          delete styles?.backgroundOptions?.gradient;
        qrCode?.customization?.pattern?.color?.type === "solid" &&
          delete styles?.dotsOptions?.gradient;

        const qrCodeStylingInstance = new QRCodeStyling(styles);
        const qrCodeContainer = document.getElementById(`qrCode-${index}`);
        qrCodeContainer.innerHTML = null;
        qrCodeStylingInstance.append(qrCodeContainer);
      });
  }, [qrCodes, minValue, maxValue]);

  const setPagination = (page, pageSize) => {
    setMinValue((page - 1) * pageSize);
    setMaxValue(page * pageSize);
    setCurrentPage(page);
  };

  const onDownloadQR = (e, data) => {
    let styles = {
      width: 150,
      height: 150,
      data: `${PREVIEW_URL}${data?.urlId}`,
      backgroundOptions: {
        color: !data?.background?.isTransparent
          ? data?.background?.type === "solid" && data?.background?.color[0]
          : "transparent",
        gradient: {
          type: "linear",
          rotation: 0,
          colorStops: [
            {
              offset: 0,
              color: data?.background?.color[0],
            },
            {
              offset: 1,
              color: data?.backgroundColor?.color[1],
            },
          ],
        },
      },
      cornersDotOptions: {
        type: data?.eyeBall?.type,
        color: data?.eyeBall?.color,
      },
      cornersSquareOptions: {
        type: data?.eyeBall?.eyeFrame,
        color: data?.eyeBall?.eyeFrameColor,
      },
      dotsOptions: {
        type: data?.pattern?.type,
        color: Array.isArray(data?.pattern?.color?.color)
          ? data?.pattern?.color?.color[0]
          : data?.pattern?.color[0],
        gradient: {
          type: "linear",
          rotation: 0,
          colorStops: [
            {
              offset: 0,
              color: Array.isArray(data?.pattern?.color?.color)
                ? data?.pattern?.color?.color[0]
                : data?.pattern?.color[0],
            },
            {
              offset: 1,
              color: Array.isArray(data?.pattern?.color?.color)
                ? data?.pattern?.color?.color[1]
                : data?.pattern?.color[1],
            },
          ],
        },
      },
      image: data?.logo?.image || social_icons[data?.logo?.logo],
      imageOptions: {
        hideBackgroundDots: data?.logo?.isWhite,
        imageSize: data?.logo?.size / 10,
        margin: 2,
      },
    };
    (data?.background?.type !== "gradient" || data?.frame?.type) &&
      delete styles?.backgroundOptions?.gradient;
    data?.pattern?.color?.type === "solid" &&
      delete styles?.dotsOptions?.gradient;

    const qrCode = new QRCodeStyling(styles);

    qrCode.download({ name: `QRInsight-${data?.urlId}`, extension: e });
  };

  const onCloseModal = () => {
    setIsOpen(false);
    setStatistics({});
  };

  const onCloseFoldarModal = () => {
    setIsOpenFoldarModal(false);
    setFormData({});
  };

  const onSubmitFoldarModal = async () => {
    const response = await addFolder({
      name: formData?.name,
      userId: getUserInfo()?.userId,
    });
    if (response?.data?.status?.code === 201) {
      message.success("Folder Created Successfully");
      refetchFolder();
      onCloseFoldarModal();
    } else {
      message.error(response?.data?.error || "Something went wrong!");
    }
    console.log("response", response);
  };

  const onUpdateLabel = async (id) => {
    const response = await updateQrCode({
      id,
      body: { label: editInputValue },
    });
    if (response?.data?.status?.code === 200) {
      message.success("Label updated successfully");
      setInputKey(-1);
      setEditInputValue("");
      refetch();
    } else {
      message.error(response?.error?.data?.error || "Something went wrong!");
    }
  };

  const handleKeyDown = async (e, id) => {
    if (e.key === "Enter") {
      const response = await updateQrCode({
        id,
        body: { label: editInputValue },
      });
      if (response?.data?.status?.code === 200) {
        message.success("Label updated successfully");
        setInputKey(-1);
        setEditInputValue("");
        refetch();
      } else {
        message.error(response?.error?.data?.error || "Something went wrong!");
      }
    }
  };

  const onCloseMoveFoldar = () => {
    setIsMoveFoldarModalOpen(false);
    setMoveFoldarId("");
    setMoveFormData("");
  };

  const onMoveFolder = async () => {
    const response = await updateQrCode({
      id: moveFoldarId,
      body: { folderId: moveFormData },
    });
    if (response?.data?.status?.code === 200) {
      message.success(
        moveFolderMode === "move"
          ? "Qr Code Moved Successfully"
          : "Folder Added Successfully"
      );
      onCloseMoveFoldar();
      refetchFolder();
      refetch();
      setMoveFoldarMode("move");
    } else {
      message.error(response?.error?.data?.error || "Something went wrong!");
    }
  };

  if (fetchingUserInfo) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Spin size="large" />
      </div>
    );
  }

  const sortList = [
    {
      label: "Last Created",
      value: "last_created",
    },
    {
      label: "First Created",
      value: "first_created",
    },
    {
      label: "Most Scanned",
      value: "most_scanned",
    },
    {
      label: "Last 30 Days",
      value: "last_30_days",
    },
    {
      label: "Lifetime",
      value: "lifetime",
    },
  ];

  return (
    <DashboardContainer selected={"3"}>
      <div className="min-h-[calc(100vh-60px)] w-full bg-themeGray-light">
        <div className="w-full container py-6 ">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-MontserratBold font-bold text-secondary">
              Your QR-codes
            </h1>
            <ThemeButton
              content={
                <div className="flex items-center">
                  <img
                    src={add_icon}
                    alt="icon"
                    className="w-[10px] h-[10px]"
                  />
                  <p className="text-xs font-medium text-white ml-2 font-MontserratRegular">
                    CREATE NEW QR
                  </p>
                </div>
              }
              onClick={() => navigate("/")}
              className="w-40 rounded !h-10"
            />
          </div>

          <div className="mt-6 flex flex-col">
            <div className="flex items-center">
              <h1 className="text-base font-MontserratBold font-bold text-secondary">
                My Folders
              </h1>
              <img
                src={add_blue_icon}
                alt="add"
                className="w-3 h-3 ml-3 cursor-pointer"
                onClick={() => setIsOpenFoldarModal(true)}
              />
            </div>
            <div>
              {fetchingFolders ? (
                <Skeleton />
              ) : (
                <Carousel responsive={responsive} draggable={true}>
                  {folders?.data?.map((folder, index) => {
                    return (
                      <div className="p-2 w-full" key={index}>
                        <div
                          className="bg-white rounded-md inputBox-shadow flex flex-col p-3 py-2 cursor-pointer"
                          onClick={() => navigate(`/folder/${folder?._id}`)}
                        >
                          <h2 className="text-sm text-primary font-semibold">
                            {folder?.name}
                          </h2>
                          <div className="flex items-center justify-between mt-1">
                            <span className="text-themeGray-200 text-[10px]">
                              {folder?.createdAt
                                ? dayjs(folder?.createdAt).format(
                                    "MMM DD, YYYY"
                                  )
                                : "---"}
                            </span>
                            <p className="bg-[#E2EAFF] text-primary inputBox-shadow px-1 py-[2px] text-xs font-semibold rounded min-w-[20px] mb-0 text-center">
                              {folder?.numberOfQRCodes || 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              )}
            </div>
          </div>

          <div className="flex items-end justify-between flex-wrap">
            <div className="flex items-center flex-wrap">
              <ThemeSelect
                options={qrTypesList}
                value={filter?.type}
                onChange={(e) => setFilter({ ...filter, type: e })}
                className={
                  "bg-white inputBox-shadow !w-40 filterSelectBox mt-3"
                }
              />

              <ThemeSelect
                options={sortList}
                value={filter?.sort}
                onChange={(e) => {
                  e === "last_30_days"
                    ? setFilter({
                        ...filter,
                        sort: e,
                        date: [dayjs().subtract(30, "day"), dayjs()],
                      })
                    : e === "lifetime"
                    ? setFilter({
                        ...filter,
                        sort: e,
                        date: [dayjs("2023-01-1"), dayjs()],
                      })
                    : setFilter({ ...filter, sort: e });
                }}
                className={
                  "bg-white inputBox-shadow !w-40 filterSelectBox sm:ml-4 ml-0 mt-3"
                }
              />

              {isAdmin && (
                <RangePicker
                  value={filter?.date}
                  onChange={(dates) =>
                    setFilter({ ...filter, date: [dates[0], dates[1]] })
                  }
                  format="YYYY-MM-DD"
                  visible={customDateRangeVisible}
                  allowClear={false}
                  className="md:ml-4 ml-0 h-[2.2rem] inputBox-shadow mt-4"
                />
              )}
            </div>
            <div className="flex items-end ml-auto">
              <div className="flex flex-col mt-3">
                <Input
                  placeholder="Search entry by id or title"
                  className="!h-10 text-sm border-none outline-none shadow-none p-0 bg-transparent focus:outline-none focus:shadow-none placeholder:text-themeGray-200"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <span className="w-full h-[1.5px] bg-themeGray-100 -mt-1"></span>
              </div>
              <img src={search_icon} alt="icon" className="h-6 w-6 ml-3" />
            </div>
          </div>

          <div className="mt-8">
            {isLoading || isQrCodesLoading ? (
              <div className="w-full h-96 flex items-center justify-center">
                <Spin size="large" />
              </div>
            ) : (
              <div>
                {qrCodes.length > 0 ? (
                  qrCodes.slice(minValue, maxValue)?.map((qrCode, index) => {
                    return (
                      <div
                        className="w-full bg-white inputBox-shadow rounded mb-8 md:p-8 p-4 container"
                        key={index}
                      >
                        <div className="flex md:flex-row flex-col md:items-center items-start w-full">
                          <div className="flex md:flex-row flex-col md:items-center items-start md:justify-between justify-start w-full">
                            <div className="flex md:flex-row flex-col md:items-center items-start w-full">
                              <div className="flex flex-col justify-center md:w-auto w-full md:mb-0 mb-4">
                                <div
                                  id={`qrCode-${index}`}
                                  className="md:w-[100] md:h-[100] flex md:items-center justify-center"
                                >
                                  <Spin />
                                </div>
                                <ThemeButton
                                  className={
                                    "md:flex hidden w-full border-primary rounded-md hover:bg-primary hover:!text-white !h-7 mt-4"
                                  }
                                  bgColor={"bg-transparent"}
                                  content={
                                    <p className="text-sm text-primary">EDIT</p>
                                  }
                                  onClick={() =>
                                    navigate(
                                      `/type/${qrCode?.qrCodeTypeDetail?._id}/${qrCode?._id}`
                                    )
                                  }
                                />
                              </div>
                              <div className="xl:ml-16 lg:ml-16 md:ml-8 ml-4 flex flex-col justify-between h-full w-full">
                                <div className="mb-6">
                                  <div className="flex items-center">
                                    {inputKey == index ? (
                                      <input
                                        value={editInputValue}
                                        className="md:text-lg text-sm font-MontserratBold text-primary outline-none border-b border-b-primary"
                                        onChange={(e) =>
                                          setEditInputValue(e.target.value)
                                        }
                                        onBlur={() =>
                                          onUpdateLabel(qrCode?._id)
                                        }
                                        onKeyDown={(e) =>
                                          handleKeyDown(e, qrCode?._id)
                                        }
                                        autoFocus
                                      />
                                    ) : (
                                      <h1 className="md:text-lg text-2xl text-primary font-MontserratBold">
                                        {qrCode?.label || "UnTitled"}
                                      </h1>
                                    )}

                                    <span
                                      className="md:text-xl text-lg ml-2 text-secondary hover:text-primary cursor-pointer"
                                      onClick={() => {
                                        setInputKey(index);
                                        setEditInputValue(qrCode?.label);
                                      }}
                                    >
                                      <CiEdit />
                                    </span>
                                  </div>
                                  <div className="flex items-center">
                                    <img src={folder_icon} alt="icon" />{" "}
                                    {qrCode?.folder ? (
                                      <p
                                        className="mb-0 md:text-xs text-sm text-themeGray-200 ml-3 cursor-pointer"
                                        onClick={() => {
                                          setIsMoveFoldarModalOpen(true);
                                          setMoveFormData(qrCode?.folder?._id);
                                          setMoveFoldarId(qrCode?._id);
                                          setMoveFoldarMode("move");
                                        }}
                                      >
                                        {qrCode?.folder?.name}
                                      </p>
                                    ) : (
                                      <img
                                        src={add_blue_icon}
                                        alt="add"
                                        className="cursor-pointer ml-2"
                                        onClick={() => {
                                          setIsMoveFoldarModalOpen(true);
                                          setMoveFoldarId(qrCode?._id);
                                          setMoveFoldarMode("add");
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>

                                <ul>
                                  <li className="mb-2 md:flex items-center hidden">
                                    <p className="text-[#ACACAC] text-xs md:w-16 w-[70px] font-MontserratRegular">
                                      Link:
                                    </p>

                                    <a
                                      href={`${PREVIEW_URL}${qrCode?.urlId}`}
                                      target="_blank"
                                      className="text-[#393838] text-xs font-MontserratRegular underline cursor-pointer"
                                    >
                                      <p className="break-words break-all">{`${PREVIEW_URL}${qrCode?.urlId}`}</p>
                                    </a>
                                  </li>
                                  <li className="mb-2 flex items-center">
                                    <p className="text-[#ACACAC] md:text-xs text-base md:w-16 w-[70px] font-MontserratRegular">
                                      Created:
                                    </p>
                                    <p className="text-[#393838] md:text-xs text-base font-MontserratRegular">
                                      {qrCode?.createdAt
                                        ? dayjs(qrCode?.createdAt).format(
                                            "MMM DD, YYYY"
                                          )
                                        : "---"}
                                    </p>
                                  </li>
                                  <li className="mb-2 flex items-center">
                                    <p className="text-[#ACACAC] md:text-xs text-base md:w-16 w-[70px] font-MontserratRegular">
                                      Type:
                                    </p>
                                    <p className="text-[#393838] md:text-xs text-base font-MontserratRegular">
                                      {qrCode?.qrCodeTypeDetail?.label || "---"}
                                    </p>
                                  </li>
                                  <li className="mb-2 items-center hidden actionButton-display">
                                    <p className="text-[#ACACAC] md:text-xs text-base md:w-16 w-[70px] font-MontserratRegular">
                                      Scans:
                                    </p>
                                    <div>
                                      <p className="text-[#393838] md:text-xs text-base font-MontserratRegular">
                                        {qrCode?.scan}
                                      </p>
                                    </div>
                                  </li>
                                  <li className="hidden">
                                    <img
                                      src={redirect_icon}
                                      alt="redirect"
                                      className="w-4 h-4 cursor-pointer"
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="flex flex-col justify-center items-center actionButton-hide">
                              <a
                                href={`${PREVIEW_URL}${qrCode?.urlId}`}
                                target="_blank"
                              >
                                <img
                                  src={redirect_icon}
                                  alt="redirect"
                                  className="md:w-10 md:h-10 h-8 w-8 mb-4"
                                />
                              </a>
                              <p className="text-xl font-MontserratBold text-[#002B38] font-bold !m-0">
                                {qrCode?.scan}
                              </p>
                              <span className="text-themeGray-200 font-MontserratRegular -mt-1 md:font-medium text-sm">
                                Scans
                              </span>
                            </div>
                          </div>
                          <div className="md:mx-8 mx-4 h-full md:flex hidden">
                            <Divider
                              type="vertical"
                              style={{
                                height: "170px",
                                backgroundColor: "#D7D1D1",
                              }}
                            />
                          </div>
                          <div className="flex md:flex-col flex-row items-center md:justify-center justify-around md:w-auto w-full md:pt-0 pt-2 md:mt-0 mt-4 md:border-none border-t border-t-[#D7D1D1]">
                            <ThemeButton
                              className={
                                "flex w-40 border-primary hover:bg-primary hover:text-white mb-3 !h-11 !rounded actionButton-hide"
                              }
                              bgColor={"bg-transparent"}
                              content={
                                <p className="text-sm text-primary">
                                  Statistics
                                </p>
                              }
                              onClick={() =>
                                navigate(`/statistics/${qrCode?._id}`)
                              }
                            />
                            <div
                              className="hidden w-10 h-10 rounded-full border border-primary items-center justify-center text-lg text-primary hover:bg-primary hover:text-white mb-3 cursor-pointer actionButton-display"
                              onClick={() =>
                                navigate(`/statistics/${qrCode?._id}`)
                              }
                            >
                              <IoIosStats />
                            </div>

                            <Popover
                              content={
                                <div>
                                  <Radio.Group
                                    onChange={(e) =>
                                      onDownloadQR(e.target.value, {
                                        ...qrCode?.customization,
                                        urlId: qrCode?.urlId,
                                      })
                                    }
                                  >
                                    <Space direction="vertical">
                                      <Radio value={"png"}>PNG</Radio>
                                      <Radio value={"svg"}>SVG</Radio>
                                    </Space>
                                  </Radio.Group>
                                </div>
                              }
                              title="Select Format"
                            >
                              <ThemeButton
                                className={
                                  "w-40 mb-3 !h-11 !rounded actionButton-hide"
                                }
                                content={
                                  <p className="text-sm ">Download QR</p>
                                }
                              />
                              <div className="hidden  w-10 h-10 rounded-full border border-primary items-center justify-center text-lg text-primary hover:bg-primary hover:text-white mb-3 cursor-pointer actionButton-display">
                                <MdOutlineFileDownload />
                              </div>
                            </Popover>
                            <ThemeButton
                              className={
                                "w-40 border-red-600 hover:bg-red-600 hover:text-white mb-3 !h-11 !rounded actionButton-hide"
                              }
                              bgColor={"bg-transparent"}
                              content={
                                <div className="flex items-center">
                                  <img
                                    src={delete_icon}
                                    alt="icon"
                                    className="w-[20px] h-[20px]"
                                  />
                                  <p className="text-sm text-red-600 ml-2">
                                    Delete QR
                                  </p>
                                </div>
                              }
                            />
                            <div
                              className="hidden w-10 h-10 rounded-full border border-primary items-center justify-center text-lg text-primary hover:bg-primary hover:text-white mb-3 cursor-pointer actionButton-display"
                              onClick={() =>
                                navigate(
                                  `/type/${qrCode?.qrCodeTypeDetail?._id}/${qrCode?._id}`
                                )
                              }
                            >
                              <MdEdit />
                            </div>
                            <a
                              href={`${PREVIEW_URL}${qrCode?.urlId}`}
                              className="hidden w-10 h-10 rounded-full border border-primary items-center justify-center text-lg text-primary hover:bg-primary hover:text-white mb-3 cursor-pointer actionButton-display"
                            >
                              <FaExternalLinkAlt />
                            </a>
                            <div className="hidden w-10 h-10 rounded-full border border-red-600 items-center justify-center text-lg text-red-600 hover:bg-red-600 hover:text-white mb-3 cursor-pointer actionButton-display">
                              <MdDelete />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <Empty className="bg-white rounded-md mb-8 p-6 py-20" />
                )}
              </div>
            )}
          </div>
          <div className="flex justify-end">
            <Pagination
              defaultCurrent={1}
              total={data?.data?.qrCodes?.length}
              current={currentPage}
              defaultPageSize={10}
              onChange={(page, pageSize) => setPagination(page, pageSize)}
            />
          </div>
        </div>
      </div>
      <QrStatisticModal
        isOpen={isOpen}
        onClose={onCloseModal}
        data={statistics}
        isLoading={fetchingStats}
      />
      <FoldarModal
        open={isOpenFoldarModal}
        onClose={onCloseFoldarModal}
        onSubmit={onSubmitFoldarModal}
        formData={formData}
        setFormData={(e) => setFormData(e)}
        isLoading={submittingFolder}
        mode={"create"}
      />
      <MoveFolder
        open={isMoveFoldarOpen}
        onClose={onCloseMoveFoldar}
        formData={moveFormData}
        setFormData={(e) => setMoveFormData(e)}
        onSubmit={onMoveFolder}
        isLoading={updatingQr}
        mode={moveFolderMode}
      />
    </DashboardContainer>
  );
};

export default Dashboard;
