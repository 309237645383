import React from "react";
import { verification_banner } from "../../assets/images/images";

const verification = () => {
  return (
    <div className="h-screen w-full p-4 flex flex-col items-center justify-center bg-no-repeat bg-cover">
      <img src={verification_banner} alt="" className="mb-6" />
      <div className="w-full flex flex-col items-center">
        <p className="text-themeGray-200 text-center">
          We just emailed a confirmation link to loremipsum@gmail.com <br />
          Click the link and you will be signed in
        </p>
        <p className="text-themeGray-200 text-center my-16">
          Not your email?
          <a className="text-primary cursor-pointer hover:underline">Log out</a>
        </p>
        <p className="text-themeGray-200 text-center">
          Didn’t receive an email? wait a few more second or
          <a className="text-primary cursor-pointer hover:underline">
            Contact support
          </a>
        </p>
      </div>
    </div>
  );
};

export default verification;
