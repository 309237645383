import React from "react";
import { ThemeColorPicker } from "../../ui/ui";
import { Divider } from "antd";
import * as customization_icons from "../../assets/icons/qrcustomization/qrcustomization";

const PatternSection = ({ formData, setFormData }) => {
  const patterns = [
    { title: "square", icon: "pattern1_icon" },
    { title: "rounded", icon: "pattern2_icon" },
    { title: "dots", icon: "pattern3_icon" },
    { title: "extra-rounded", icon: "pattern4_icon" },
    { title: "classy-rounded", icon: "pattern5_icon" },
  ];

  const onHandleColorChange = (e, index) => {
    let values = [...formData?.pattern?.color?.color];
    values[index] = e.toHexString();
    setFormData({
      ...formData,
      pattern: {
        ...formData?.pattern,
        color: { ...formData?.pattern?.color, color: values },
      },
    });
  };

  return (
    <div>
      <div>
        <h1 className="text-secondary text-lg font-bold mb-1">
          SELECT A PATTERN
        </h1>
        <p className="text-xs text-themeGray-200 mb-4">
          This will appear at the center of your QR code
        </p>
      </div>

      <div className="flex items-center -ml-2 mb-4">
        {patterns.map((pattern, index) => {
          return (
            <div className="p-2" key={index}>
              <div
                className={`flex items-center justify-center type-box-shadow w-[80px] h-[80px] rounded cursor-pointer border-2 hover:border-2 hover:border-primary ${
                  pattern.title === formData?.pattern?.color?.type &&
                  "border-primary"
                }`}
                onClick={() =>
                  setFormData({
                    ...formData,
                    pattern: { ...formData?.pattern, type: pattern?.title },
                  })
                }
              >
                <img
                  src={customization_icons[pattern?.icon]}
                  alt="icon"
                  className="w-14 h-14"
                />
              </div>
            </div>
          );
        })}
      </div>

      <div className="mb-10">
        <p className="text-base font-bold text-primary mb-2">COLOR</p>
        <div className="flex items-center mb-2">
          <div
            className={`flex items-center cursor-pointer ${
              formData?.pattern?.color?.type === "solid" && "text-primary"
            } `}
            onClick={() =>
              setFormData({
                ...formData,
                pattern: {
                  ...formData?.pattern,
                  color: { ...formData?.pattern?.color, type: "solid" },
                },
              })
            }
          >
            <div className="h-2 w-2 rounded-full bg-primary"></div>
            <p className="text-sm ml-2">Solid color</p>
          </div>
          <div
            className={`flex items-center ml-10 cursor-pointer ${
              formData?.pattern?.color?.type === "gradient" && "text-primary"
            } `}
            onClick={() =>
              setFormData({
                ...formData,
                pattern: {
                  ...formData?.pattern,
                  color: { ...formData?.pattern?.color, type: "gradient" },
                },
              })
            }
          >
            <div className="h-2 w-2 rounded-full bg-primary"></div>
            <p className="text-sm ml-2">Gradient</p>
          </div>
        </div>
        <div className="flex items-center">
          <ThemeColorPicker
            value={formData?.pattern?.color?.color[0]}
            onChange={(e) => onHandleColorChange(e, 0)}
          />
          {formData?.pattern?.color?.type === "gradient" && (
            <div className="ml-10">
              <ThemeColorPicker
                value={formData?.pattern?.color?.color[1]}
                onChange={(e) => onHandleColorChange(e, 1)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PatternSection;
