import React from "react";
import { Navbar } from "../components";

const Container = ({ children }) => {
  return (
    <div>
      <Navbar />
      <main className="main-box pt-[58px]">{children}</main>
    </div>
  );
};

export default Container;
