import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../constants/constants";
import { getValueFromStorage } from "../helpers/helpers";

export const AuthApi = createApi({
  reducerPath: "auth",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-type", "application/json");
      headers.set("authorization", `Bearer ${getValueFromStorage("token")}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    setLogin: builder.mutation({
      query: (body) => ({
        url: "/api/auth/login",
        method: "POST",
        body: body,
      }),
    }),
    registerUser: builder.mutation({
      query: (body) => ({
        url: "/api/auth/signup",
        method: "POST",
        body: body,
      }),
    }),
    updateUser: builder.mutation({
      query: (body) => {
        return {
          url: "/api/auth/update-profile",
          method: "PUT",
          body: body,
        };
      },
    }),
    getUser: builder.query({
      query: () => ({
        url: `/api/auth/getUserInfo`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useSetLoginMutation,
  useRegisterUserMutation,
  useUpdateUserMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
} = AuthApi;
