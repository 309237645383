import React, { useEffect, useState } from "react";
import {
  ThemeInput,
  ThemeTextArea,
  ThemeDatePicker,
  ThemeDropDown,
  ThemeSwitch,
  ThemeRating,
  ThemeSelect,
} from "../../ui/ui";
import { Spin, message } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import ImageBox from "../ImageBox/ImageBox";
import ImageShape from "../ImageShape/ImageShape";
import TimeSlots from "../TimeSlots/TimeSlots";
import BadgeInput from "../BadgeINput/BadgeInput";
import MultiImageUploader from "../MultiImageUploader/MultiImageUploader";
import LinkButton from "../LinkButton/LinkButton";
import Design from "../Design/Design";
import ProductInput from "../ProductInput/ProductInput";
import RatingType from "../RatingType/RatingType";
import FeaturesBox from "../FeaturesBox/FeaturesBox";
import SocialLink from "../SocialLink/SocialLink";
import MultiLinks from "../MultiLinks/MultiLinks";
import RichText from "../RichText/RichText";
import { Autocomplete } from "@react-google-maps/api";
import FileUploader from "../fileUploader/fileUploader";
import { CreateQuestion } from "../components";

const Section = ({ section, onChangeSection, index }) => {
  const [sectionInfo, setSectionInfo] = useState(section);
  const [category, setCategory] = useState("");
  const [productForm, setProductForm] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [productErrorMessage, setProductErrorMessage] = useState({});
  const [mode, setMode] = useState("create");
  const [productMode, setProductMode] = useState("create");

  useEffect(() => {
    if (sectionInfo) {
      onChangeSection(sectionInfo, index);
    }
  }, [sectionInfo]);

  const onChange = (e, i) => {
    let data = [...sectionInfo?.inputs];
    data[i] = { ...data[i], value: e };
    setSectionInfo({ ...sectionInfo, inputs: data });
  };

  const onRemoveButton = (i) => {
    let data = sectionInfo?.inputs;
    data.splice(i, 1);
    setSectionInfo({ ...sectionInfo, inputs: data });
  };

  const onChangeAddableInput = (e, ii, i) => {
    console.log("in fnc");
    if (sectionInfo?.inputs[i]?.addable) {
      console.log("in if");
      let values =
        sectionInfo?.inputs[i]?.values?.length > 0
          ? sectionInfo?.inputs[i]?.values
          : [];
      values[ii] = e;
      onChange(values, i);
      console.log("values", values);
    } else {
      console.log("in else", e, "", i);
      onChange(e, i);
    }
  };

  const onAddInput = (index) => {
    let values = sectionInfo?.inputs[index]?.value;
    if (values?.length >= 1) {
      values.push("");
      onChange(values, index);
    } else {
      message.error("Please enter a value first!");
    }
  };

  const onRemoveInput = (ii, index) => {
    let values = sectionInfo?.inputs[index]?.value;
    values.splice(ii, 1);
    onChange(values, index);
  };

  const onAddCategory = (i) => {
    if (category) {
      let data = [...sectionInfo?.inputs];
      let list = [...data[i]?.value];
      list?.push({ name: category });
      data[i] = { ...data[i], value: list };
      setSectionInfo({ ...sectionInfo, inputs: data });
      setCategory("");
      setProductErrorMessage({});
    } else {
      setProductErrorMessage({
        ...productErrorMessage,
        category: "Category is required!",
      });
    }
  };

  const onEditCategory = (e, i) => {
    if (category) {
      let data = [...sectionInfo?.inputs];
      let list = [...data[i]?.value];
      list[e] = { ...list[e], name: category };
      data[i] = { ...data[i], value: list };
      setSectionInfo({ ...sectionInfo, inputs: data });
      setMode("create");
      setCategory("");
      setProductErrorMessage({});
    } else {
      setProductErrorMessage({
        ...productErrorMessage,
        category: "Category is required!",
      });
    }
  };

  const onDeleteCategory = (e, i) => {
    let data = [...sectionInfo?.inputs];
    let list = [...data[i]?.value];
    list?.splice(e, 1);
    data[i] = { ...data[i], value: list };
    setSectionInfo({ ...sectionInfo, inputs: data });
  };

  const onAddProduct = (data, categoryIndex, i) => {
    if (productForm?.title && productForm.price) {
      let sectionData = [...sectionInfo?.inputs];
      let values = [...sectionData[i]?.value];
      let categoryData = data;
      let products = Array.isArray(categoryData?.products)
        ? [...categoryData.products]
        : [];
      products.push(productForm);
      categoryData = { ...categoryData, products: products };
      values[categoryIndex] = categoryData;
      sectionData[i] = { ...sectionData[i], value: values };
      setSectionInfo({ ...sectionInfo, inputs: sectionData });
      setProductForm({});
      setProductErrorMessage({});
    } else {
      setProductErrorMessage({
        title: !productForm?.title ? "Title is required!" : "",
        price: !productForm?.price ? "Price is required!" : "",
      });
    }
  };

  const onAddQuestion = (data, i) => {
    let qaFormat = {
      inputs: [
        {
          type: "text",
          label: "Question",
          placeholder: "Type question/instruction text",
        },
        {
          type: "selectList",
          label: "Answer Type",
          placeholder: "",
          options: [
            {
              label: "Short Paragraph",
              key: "text",
              value: "text",
            },
            {
              label: "Paragraph",
              key: "textArea",
              value: "textArea",
            },
            {
              label: "Multiple Choice",
              key: "radio",
              value: "radio",
            },
            {
              label: "Checkbox",
              key: "checkbox",
              value: "checkbox",
            },
          ],
        },
      ],
    };
    let sectionData = [...sectionInfo?.inputs];
    let values = [...data?.value];
    values.push(qaFormat);
    sectionData[i] = { ...sectionData[i], value: values };
    setSectionInfo({ ...sectionInfo, inputs: sectionData });
  };

  const onRemoveQuestion = (i, deleteIndex) => {
    let sectionData = [...sectionInfo?.inputs];
    let values = [...sectionData[i]?.value];
    values.splice(deleteIndex, 1);
    sectionData[i] = { ...sectionData[i], value: values };
    setSectionInfo({ ...sectionInfo, inputs: sectionData });
  };

  const onChangeImage = (url, image, i) => {
    let data = [...sectionInfo?.inputs];
    data[i] = { ...data[i], value: url, image: image };
    setSectionInfo({ ...sectionInfo, inputs: data });
  };

  const onChangeFile = (file, i) => {
    let data = [...sectionInfo?.inputs];
    data[i] = { ...data[i], value: file };
    setSectionInfo({ ...sectionInfo, inputs: data });
  };

  return (
    <>
      <section
        key={index}
        className="bg-white rounded-md md:p-10 p-6 qrbox-shadow mb-12 sectionBox"
        id={section?.title.trim()}
        // ref={(ref) => (sectionRefs.current[index] = ref)}
      >
        <h2 className="text-base font-bold text-secondary mb-1">
          {section?.title}
        </h2>
        <p className="text-xs text-themeGray-200 mb-2">{section?.desc}</p>
        <div className="mb-4">
          {section?.inputs?.map((input, index) => {
            if (input?.type === "image") {
              return (
                <div className="mb-8" key={index}>
                  <ImageBox
                    label={input?.label}
                    placeholder={input?.placeholder}
                    image={input?.value}
                    id={`${sectionInfo?.title.trim()}${index}`}
                    setImage={(url, image) => onChangeImage(url, image, index)}
                    errorMessage={errorMessage}
                    setErrorMessage={(e) => setErrorMessage(e)}
                    previewBtn={input?.showPreview}
                  />
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            } else if (input?.type === "file") {
              return (
                <div className="mb-8" key={index}>
                  <FileUploader
                    label={input?.label}
                    placeholder={input?.placeholder}
                    file={input?.value}
                    id={`${sectionInfo?.title.trim()}${index}`}
                    setFile={(file) => onChangeFile(file, index)}
                    errorMessage={errorMessage}
                    setErrorMessage={(e) => setErrorMessage(e)}
                  />
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            } else if (input?.type === "imageShape") {
              return (
                <div key={index}>
                  <ImageShape
                    input={input}
                    selected={input?.value}
                    onChange={(e) => onChange(e, index)}
                  />
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            } else if (input?.type === "text") {
              return (
                <div key={index} className="mb-8">
                  <ThemeInput
                    label={input?.label}
                    labelPrimary
                    type={"text"}
                    placeholder={input?.placeholder}
                    value={
                      input?.addable
                        ? input?.value?.length > 0
                          ? input?.value[0]
                          : ""
                        : input?.value
                    }
                    onChange={(e) =>
                      onChangeAddableInput(e.target.value, 0, index)
                    }
                    // suffix={
                    //   input?.addable && (
                    //     <span
                    //       className="cursor-pointer"
                    //       onClick={() => onAddInput(index)}
                    //     >
                    //       <PlusCircleOutlined
                    //         className={"text-primary text-lg"}
                    //       />
                    //     </span>
                    //   )
                    // }
                  />
                  {/* {input?.addable && (
                    <div>
                      {input?.value?.length > 1 &&
                        input?.value.slice(1)?.map((val, i) => {
                          return (
                            <ThemeInput
                              key={i}
                              label={input?.label}
                              labelPrimary
                              type={"text"}
                              placeholder={input?.placeholder}
                              className={"mb-8"}
                              value={input?.value[i + 1]}
                              onChange={(e) =>
                                onChangeAddableInput(
                                  e.target.value,
                                  i + 1,
                                  index
                                )
                              }
                              suffix={
                                <span
                                  className="cursor-pointer"
                                  onClick={() => onRemoveInput(i, index)}
                                >
                                  <MinusCircleOutlined
                                    className={"text-primary text-lg"}
                                  />
                                </span>
                              }
                            />
                          );
                        })}
                    </div>
                  )} */}
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            } else if (input?.type === "textArea") {
              return (
                <div key={index} className={"mb-8"}>
                  <ThemeTextArea
                    label={input?.label}
                    labelPrimary
                    placeholder={input?.placeholder}
                    value={input?.value}
                    onChange={(e) => onChange(e.target.value, index)}
                    minRows={4}
                    maxRows={4}
                  />
                  {input?.extra && (
                    <p
                      className="text-xs text-right text-primary underline cursor-pointer mt-2 font-semibold"
                      onClick={() => onChange("", index)}
                    >
                      Reset Address
                    </p>
                  )}
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            } else if (input?.type === "phone") {
              return (
                <div key={index} className={"mb-8"}>
                  <ThemeInput
                    label={input?.label}
                    labelPrimary
                    type={"number"}
                    placeholder={input?.placeholder}
                    value={
                      input?.addable
                        ? input?.value?.length > 0
                          ? input?.value[0]
                          : ""
                        : input?.value
                    }
                    onChange={(e) =>
                      onChangeAddableInput(e.target.value, 0, index)
                    }
                    // suffix={
                    //   input?.addable && (
                    //     <span
                    //       className="cursor-pointer"
                    //       onClick={() => onAddInput(index)}
                    //     >
                    //       <PlusCircleOutlined
                    //         className={"text-primary text-lg"}
                    //       />
                    //     </span>
                    //   )
                    // }
                  />
                  {/* {input?.addable && (
                    <div>
                      {input?.value?.length > 1 &&
                        input?.value.slice(1)?.map((val, i) => {
                          return (
                            <ThemeInput
                              key={i}
                              label={input?.label}
                              labelPrimary
                              type={"number"}
                              placeholder={input?.placeholder}
                              className={"mb-8"}
                              value={input?.value[i + 1]}
                              onChange={(e) =>
                                onChangeAddableInput(
                                  e.target.value,
                                  i + 1,
                                  index
                                )
                              }
                              suffix={
                                <span
                                  className="cursor-pointer"
                                  onClick={() => onRemoveInput(i, index)}
                                >
                                  <MinusCircleOutlined
                                    className={"text-primary text-lg"}
                                  />
                                </span>
                              }
                            />
                          );
                        })}
                    </div>
                  )} */}
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            } else if (input?.type === "email") {
              return (
                <div key={index} className={"mb-8"}>
                  <ThemeInput
                    label={input?.label}
                    labelPrimary
                    type={"email"}
                    placeholder={input?.placeholder}
                    value={
                      input?.addable
                        ? input?.value?.length > 0
                          ? input?.value[0]
                          : ""
                        : input?.value
                    }
                    onChange={(e) =>
                      onChangeAddableInput(e.target.value, 0, index)
                    }
                    // suffix={
                    //   input?.addable && (
                    //     <span
                    //       className="cursor-pointer"
                    //       onClick={() => onAddInput(index)}
                    //     >
                    //       <PlusCircleOutlined
                    //         className={"text-primary text-lg"}
                    //       />
                    //     </span>
                    //   )
                    // }
                  />
                  {/* {input?.addable && (
                    <div>
                      {input?.value?.length > 1 &&
                        input?.value.slice(1)?.map((val, i) => {
                          return (
                            <ThemeInput
                              key={i}
                              label={input?.label}
                              labelPrimary
                              type={"email"}
                              placeholder={input?.placeholder}
                              className={"mb-8"}
                              value={input?.value[i + 1]}
                              onChange={(e) =>
                                onChangeAddableInput(
                                  e.target.value,
                                  i + 1,
                                  index
                                )
                              }
                              suffix={
                                <span
                                  className="cursor-pointer"
                                  onClick={() => onRemoveInput(i, index)}
                                >
                                  <MinusCircleOutlined
                                    className={"text-primary text-lg"}
                                  />
                                </span>
                              }
                            />
                          );
                        })}
                    </div>
                  )} */}
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            } else if (input?.type === "date") {
              return (
                <div key={index} className={"mb-4"}>
                  <ThemeDatePicker
                    label={input?.label}
                    labelPrimary
                    value={input?.value}
                    onChange={(e, date) => onChange(date, index)}
                  />
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            } else if (input?.type === "selectList") {
              return (
                <div key={index} className="mb-10">
                  <p
                    className={
                      "text-primary font-semibold mb-1 flex items-center text-sm"
                    }
                  >
                    {input?.label}
                  </p>
                  <ThemeSelect
                    options={input?.options || []}
                    value={input?.value}
                    placeholder={input?.placeholder}
                    className={"w-full sectionSelectBox"}
                    onChange={(e) => onChange(e, index)}
                  />
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            } else if (input?.type === "switch") {
              return (
                <div className="mb-6" key={index}>
                  <ThemeSwitch
                    label={input?.label}
                    checked={input?.value}
                    onChange={(e) => onChange(e, index)}
                  />
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            } else if (input?.type === "badge") {
              return (
                <div className="mb-6" key={index}>
                  <BadgeInput
                    label={input?.label}
                    labelPrimary
                    placeholder={input?.placeholder}
                    value={input?.value}
                    onChange={(e) => onChange(e.target.value, index)}
                  />
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            } else if (input?.type === "colorPicker") {
              return (
                <div className="mb-8" key={index}>
                  <Design
                    value={input?.value}
                    onChange={(e) => onChange(e, index)}
                  />
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            } else if (input?.type === "weeklyTimings") {
              return (
                <div key={index}>
                  <TimeSlots />
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            } else if (input?.type === "rating") {
              return (
                <div className="mb-4" key={index}>
                  <ThemeRating
                    label={input?.label}
                    labelPrimary
                    value={input?.value}
                    onChange={(e) => onChange(e, index)}
                  />
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            } else if (input?.type === "multiImage") {
              return (
                <div className="mb-4" key={index}>
                  <MultiImageUploader
                    images={input?.value}
                    setImages={(e) => onChange(e, index)}
                    errorMessage={errorMessage}
                    setErrorMessage={(e) => setErrorMessage(e)}
                  />
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            } else if (input?.type === "linkButton") {
              return (
                <div className="mb-8" key={index}>
                  <LinkButton
                    label={input?.label}
                    placeholder={input?.placeholder}
                    value={input?.value}
                    onChange={(e) => onChange(e, index)}
                    extra={input?.extra}
                    onRemoveButton={() => onRemoveButton(index)}
                  />
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            } else if (input?.type === "productInput") {
              return (
                <div className="mb-4" key={index}>
                  <ProductInput
                    input={input}
                    category={category}
                    setCategory={(e) => setCategory(e)}
                    formData={productForm}
                    setFormData={(e) => setProductForm(e)}
                    onAddCategory={() => onAddCategory(index)}
                    onAddProduct={(data, categoryIndex) =>
                      onAddProduct(data, categoryIndex, index)
                    }
                    onDeleteCategory={(e) => onDeleteCategory(e, index)}
                    onEditCategory={(e) => onEditCategory(e, index)}
                    errorMessage={errorMessage}
                    setErrorMessage={(e) => setErrorMessage(e)}
                    mode={mode}
                    setMode={(e) => setMode(e)}
                    productErrorMessage={productErrorMessage}
                  />
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            } else if (input?.type === "ratingType") {
              return (
                <div className="mb-4" key={index}>
                  <RatingType
                    value={input?.value}
                    onChange={(e) => onChange(e, index)}
                  />
                </div>
              );
            } else if (input?.type === "features") {
              return (
                <div className="mb-4" key={index}>
                  <FeaturesBox
                    value={input?.value || []}
                    onChange={(e) => onChange(e, index)}
                  />
                </div>
              );
            } else if (input?.type === "social") {
              return (
                <div className="mb-4" key={index}>
                  <SocialLink
                    value={input?.value}
                    onChange={(e) => onChange(e, index)}
                    isText={input?.isText}
                  />
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            } else if (input?.type === "multiLinks") {
              return (
                <div className="mb-4" key={index}>
                  <MultiLinks
                    value={input?.value}
                    onChange={(e) => onChange(e, index)}
                  />
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            } else if (input?.type === "richText") {
              return (
                <div className="mb-4" key={index}>
                  <RichText
                    label={input?.label}
                    labelPrimary
                    value={input?.value}
                    onChange={(e) => onChange(e, index)}
                  />
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            } else if (input?.type == "location") {
              return (
                <div className={"mb-8"}>
                  {/* <Autocomplete> */}
                  <ThemeInput
                    label={input?.label}
                    labelPrimary
                    type={"text"}
                    placeholder={input?.placeholder}
                  />
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                  {/* </Autocomplete> */}
                </div>
              );
            } else if (input?.type == "createQuestion") {
              return (
                <div className={"mb-8"}>
                  <CreateQuestion
                    value={input?.value}
                    onAddQuestion={() => onAddQuestion(input, index)}
                    onRemoveQuestion={(deleteIndex) =>
                      onRemoveQuestion(index, deleteIndex)
                    }
                    onChange={(e) => onChange(e, index)}
                  />
                  <p className="text-sm text-red-600 mt-1">
                    {input?.errorMessage}
                  </p>
                </div>
              );
            }
          })}
        </div>
      </section>
    </>
  );
};

export default Section;
