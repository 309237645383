import React, { useState, useEffect } from "react";
import { Modal, Button, Spin } from "antd";
import { ThemeSelect, ThemeButton, ThemeInput } from "../../ui/ui";
import { useGetFoldersQuery } from "../../services/folders";

const MoveFolder = ({
  open,
  onClose,
  onSubmit,
  isLoading,
  formData,
  setFormData,
  mode,
}) => {
  const [folders, setFolders] = useState([]);

  const { data, fetchingFolders, refetch } = useGetFoldersQuery();

  useEffect(() => {
    let list = [];

    console.log("data", data?.data);
    if (data?.data) {
      data?.data?.map((folder) => {
        list.push({ label: folder?.name, value: folder?._id });
      });
    }
    setFolders(list);
  }, [data]);
  return (
    <Modal
      open={open}
      centered={true}
      width={600}
      title={
        <div>
          <p className="text-2xl font-bold text-center font-MontserratBold">
            {mode === "move" ? "Move QR Code" : "Select Folder"}
          </p>
          <p
            className={`text-base text-black text-center mt-1 ${
              mode !== "move" && "hidden"
            }`}
          >
            Select the folder you would like to move your QR Code to
          </p>
        </div>
      }
      onCancel={onClose}
      footer={
        <div className="flex items-center justify-between">
          <Button key="cancel" onClick={onClose} className="h-9">
            Cancel
          </Button>
          <ThemeButton
            content={
              <>
                {isLoading ? (
                  <Spin />
                ) : (
                  <p>{mode === "move" ? "Move" : "Save"}</p>
                )}
              </>
            }
            onClick={onSubmit}
          />
        </div>
      }
    >
      <div className="my-10">
        <ThemeSelect
          className={"submitModalSelectBox w-full"}
          options={folders}
          value={formData}
          onChange={(e) => setFormData(e)}
          loading={fetchingFolders}
        />
      </div>
    </Modal>
  );
};

export default MoveFolder;
