import React, { useState, useEffect } from "react";
import { Modal, Button, Spin } from "antd";
import { ThemeInput, ThemeButton, ThemeSelect } from "../../ui/ui";
import { useGetFoldersQuery } from "../../services/folders";

const QrSubmitModal = ({
  open,
  onClose,
  onSubmit,
  submitting,
  qrLabel,
  setQrLabel,
  qrFolder,
  setQrFolder,
}) => {
  const [folders, setFolders] = useState([]);

  const { data, isLoading, refetch } = useGetFoldersQuery();

  useEffect(() => {
    let list = [];
    if (data?.data) {
      data?.data?.map((folder) => {
        list.push({ label: folder?.name, value: folder?._id });
      });
    }
    setFolders(list);
  }, [data]);

  return (
    <Modal
      open={open}
      centered={true}
      title={<p className="text-xl font-bold text-center">Campaign Name</p>}
      onCancel={onClose}
      footer={
        <div className="flex items-center justify-between">
          <Button key="cancel" onClick={onClose} className="h-9">
            Cancel
          </Button>
          <ThemeButton
            content={<>{submitting ? <Spin /> : <p>Save</p>}</>}
            onClick={onSubmit}
          />
        </div>
      }
    >
      <div className="mt-6">
        <ThemeInput
          label={"Give a name to your QR code"}
          labelPrimary
          className={"mb-4"}
          inputHeight={"h-11"}
          value={qrLabel}
          onChange={(e) => setQrLabel(e.target.value)}
        />
        <div className="mb-12 flex flex-col">
          <p className="text-primary font-semibold mb-1 flex items-center text-sm">
            Folder
          </p>
          <ThemeSelect
            className={"submitModalSelectBox"}
            options={folders}
            value={qrFolder}
            onChange={(e) => setQrFolder(e)}
            loading={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default QrSubmitModal;
