import React from "react";
import { ThemeSelect, ThemeButton, ThemeInput } from "../../ui/ui";
import { female_vector, male_vector } from "../../assets/images/images";

const Setup = () => {
  return (
    <div className="h-screen w-full p-4 flex flex-col items-center justify-center setup-container">
      <div className="mb-8">
        <h1 className="text-3xl font-MontserratBold text-[#002B38] text-center mb-2">
          Hi User! Tell us a little about yourself.
        </h1>
        <p className="text-[#615C5C] font-medium text-center text-xs">
          This will help us recommend the bes tlayouts and industry trends for
          your.
        </p>
      </div>
      <div className="flex items-center w-full justify-center">
        <div className="md:flex hidden">
          <img src={male_vector} className="vector" />
        </div>
        <div className="md:w-6/12 w-full mx-4">
          <div className="mb-8">
            <p className="text-primary mb-1 flex items-center text-sm font-semibold">
              Comapny Size
            </p>
            <ThemeSelect
              placeholder={"Select your company size"}
              options={[
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 80, value: 80 },
                { label: 100, value: 100 },
              ]}
              className={"h-12 w-full inputBox-shadow"}
            />
          </div>
          <div className="mb-12">
            <p className="text-primary mb-1 flex items-center text-sm font-semibold">
              Industry
            </p>
            <ThemeSelect
              placeholder={"Select your industry"}
              options={[
                { label: "Food industry", value: "Food industry" },
                { label: "Construction", value: "Construction" },
                { label: "Agriculture", value: "Agriculture" },
                { label: "Transportation", value: "Transportation" },
                { label: "Real Estate", value: "Real Estate" },
              ]}
              className={"h-12 w-full inputBox-shadow"}
            />
          </div>
          <ThemeInput
            label={"Your Company Name"}
            labelPrimary
            className={"mb-4 w-full"}
            inputHeight={"h-12"}
            placeholder={"Write your company name"}
          />
          <ThemeButton
            type="primary"
            htmlType="submit"
            // onClick={() => router.push("/home")}
            className={"!h-12 w-full flex mb-2 mt-20"}
            content={<p className="text-sm uppercase">Start creating</p>}
          />
        </div>
        <div className="md:flex hidden">
          <img src={female_vector} alt="vector" />
        </div>
      </div>
    </div>
  );
};

export default Setup;
